import {Button, Grid, Paper, TextField} from "@mui/material";
import {t} from "i18next";
import {Company} from "jack-shared";
import {useState} from "react";
import {useJackTables} from "../utilities/hooks";
import useStyles from "../utilities/styles";

export default function TableGeneratorForm({company, numberOfExistingTables}: {company: Company; numberOfExistingTables: number}) {
  const classes = useStyles();
  const [tableCount, setTableCount] = useState<string>("1");
  const {createTables} = useJackTables();

  async function createTablesForCompany() {
    createTables(Number(tableCount) || 0, numberOfExistingTables, company);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.paperContent}>
          <Grid container>
            <Grid item xs={8}>
              <TextField fullWidth label={t("enterTableCountToGenerate", "Eklenecek Masa Adedi")} onChange={(e) => setTableCount(e.target.value)} type="number" value={tableCount} />
            </Grid>
            <Grid item xs={4}>
              <Button disabled={!tableCount} onClick={createTablesForCompany} size="large" variant="contained">
                {t("generateTables", "Masa Ekle")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
