import {DataTypeProvider, Filter, FilteringState, IntegratedFiltering, IntegratedSelection, SelectionState} from "@devexpress/dx-react-grid";
import {Grid as GridDX, Table as TableDX, TableFilterRow as TableFilterRowDX, TableHeaderRow as TableHeaderRowDX, TableSelection as TableSelectionDX} from "@devexpress/dx-react-grid-material-ui";
import {CameraAlt, Delete, Home} from "@mui/icons-material";
import {Avatar, Breadcrumbs, Button, CircularProgress, Grid, IconButton, Paper, Typography} from "@mui/material";
import {Timestamp} from "firebase/firestore";
import {t} from "i18next";
import {useAtom} from "jotai";
import {Currency, Product, ProductStatus, ProductVarianceType, ProductVariation, slugify} from "jack-shared";
import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ProductVariantsForm from "../forms/product-variantion-form";
import {errorsAtom} from "../utilities/atoms";
import {buildVariationsFromAllAttributes} from "../utilities/helpers";
import {useMetretulProducts, useMetretulStorageFolder} from "../utilities/hooks";
import useStyles from "../utilities/styles";
const uniqid = require("uniqid");

const ProductVariantsWrite = function () {
  const navigate = useNavigate();
  const {id} = useParams();
  const classes = useStyles();
  const {deleteProductVariations, isLoadingProducts, products, productVariations, readProduct, readVariations, updateProduct, writeProductVariations} = useMetretulProducts();
  const [selection, setSelection] = useState<(string | number)[]>([]);
  const {uploadFiles} = useMetretulStorageFolder();
  const [, setErrors] = useAtom(errorsAtom);
  const [filters, setFilters] = useState<Filter[] | undefined>([]);
  const [files] = useState<any>({});

  /**
   *
   * ÖNEMLİ NOT!
   *
   * Bir görsel birden fazla varyasyona tanımlanabilir.
   * Storage tarafında sorguyu azaltmak için her tekil görsel için lodash.uniq ile files içine sadece string url'lerden oluşan liste tanımlıyoruz.
   * Varyantları listelerken sorgu sayısını azaltmak için files dizisinden faydalanıyoruz.
   *
   */

  const deleteSelectedVariations = useCallback(
    async function () {
      try {
        const _selectedProductVariationIds = selection.map((selectionIndex: any) => productVariations[selectionIndex].id);

        console.log("🚀 ~ file: product-variations.tsx ~ line 48 ~ deleteSelectedVariations ~ _selectedProductVariationIds", _selectedProductVariationIds);

        const _productVariationDeletionResult = await deleteProductVariations(_selectedProductVariationIds);
        console.log("🚀 ~ file: product-variations.tsx ~ line 47 ~ _productVariationDeletionResult", _productVariationDeletionResult);

        setFilters([]);
        setSelection([]);
      } catch (error) {
        console.error("🚀 ~ file: product-service-variants-write.tsx ~ line 47 ~ error", error);
        setErrors((e: any) => [...e, error]);
      }
    },
    [deleteProductVariations, productVariations, selection, setErrors]
  );

  const handleAllVariations = useCallback(
    async function () {
      if (!products[0]) {
        throw new Error(t("productIsRequired", "handleAllVariations için Ürün gereklidir."));
      }

      const _now: Timestamp = Timestamp.now();
      const _product: Product = products[0];
      const _allVariations = buildVariationsFromAllAttributes(_product.attributes);
      const _variations = _allVariations.map((variation: any) => {
        const _name = Object.values(variation).reduce((a, b, i) => (i === 0 ? b : `${a} - ${b}`), "") as string;

        const _productVariaion: ProductVariation = {
          allowMultiplePurchase: false,
          allowedPointsUsagePercentage: 0,
          attachment: null,
          attachments: null,
          attributes: [],
          autoRenew: false,
          barcode: "",
          categoryIds: [],
          companyId: "",
          createdAt: _now.toDate(),
          criticalStockCount: 0,
          currentPrice: {
            amount: 0,
            createdAt: _now.toDate(),
            currency: Currency.TRY,
          },
          currentPriceRange: null,
          description: null,
          duration: null,
          expiresAt: null,
          gtip: null,
          hasStockMovements: false,
          id: `${slugify(`${_product.name}-${_name}`)}-${uniqid()}`,
          includedAttributeValues: variation,
          initialStock: 0,
          inventoryTracking: false,
          isSaleable: false,
          isUsageConfirmationNeeded: false,
          listPrice: {
            amount: 0,
            createdAt: _now.toDate(),
            currency: Currency.TRY,
          },
          listPriceRange: null,
          name: _name,
          order: 0,
          paymentId: "",
          startsAt: _now.toDate(), // TO DO: Date | null olarak güncellenmeli.
          status: ProductStatus.available,
          stock: null,
          stockType: null,
          updatedAt: _now.toDate(),
          taxes: [],
          variations: [],
          varianceType: ProductVarianceType.variation,
          variationOfProductId: _product.id,
        };

        return _productVariaion;
      });

      const _writeProductVariationsResult: void = await writeProductVariations(_variations);
      console.log("🚀 ~ file: product-service-variants-write.tsx ~ line 94 ~ _writeProductVariationsResult", _writeProductVariationsResult);
    },
    [products, writeProductVariations]
  );

  const uploadPhotoForSelected = useCallback(
    async function (event: any) {
      try {
        if (!products[0] && !selection.length) {
          throw new Error(t("productVariationIsRequires", "Görsel yüklenecek ürün gereklidir."));
        }

        const _file = event.target.files[0];

        const _uploadResult = await uploadFiles({file: _file, path: `ProductImages/${(products as Product[])[0].id}/variations/${_file.name}`});
        console.log("🚀 ~ file: product-service-variants-write.tsx ~ line 108 ~ uploadPhotoForSelected ~ _uploadResult", _uploadResult);

        if (!!_uploadResult?.metadata.fullPath) {
          const _productWithAttachment: Product = {
            ...(products as Product[])[0],
            variations: (products as Product[])[0].variations.map((_selectedProduct: ProductVariation, index: number) => ({
              ..._selectedProduct,
              attachment: selection.includes(index) ? _uploadResult?.metadata.fullPath : _selectedProduct.attachment,
            })),
          };

          const _updateProductResult: void = await updateProduct(_productWithAttachment);
          console.log("🚀 ~ file: product-service-variants-write.tsx ~ line 123 ~ _updateProductResult", _updateProductResult);

          setFilters([]);
          setSelection([]);
        }
      } catch (error) {
        console.error((error as Error).message);
        setErrors((e: any) => [...e, error]);
      }
    },
    [products, selection, setErrors, updateProduct, uploadFiles]
  );

  useEffect(
    function () {
      let _unsubscribe: any;

      if (!!id) {
        _unsubscribe = readProduct(id);
      }
      return function () {
        console.log("🚀 ~ file: product-service-variants-write.tsx ~ line 26 ~ _unsubscribe", _unsubscribe);
        if (!!_unsubscribe) {
          console.log("🚀 ~ file: product-service-variants-write.tsx ~ line 28 ~ _unsubscribe", _unsubscribe);
          _unsubscribe();
        }
      };
    },
    [id, readProduct]
  );

  useEffect(
    function () {
      let _unsubscribeVariations: any;

      if (!!id) {
        _unsubscribeVariations = readVariations(id);
      }
      return function () {
        console.log("🚀 ~ file: product-service-variants-write.tsx ~ line 26 ~ _unsubscribeVariations", _unsubscribeVariations);
        if (!!_unsubscribeVariations) {
          console.log("🚀 ~ file: product-service-variants-write.tsx ~ line 28 ~ _unsubscribeVariations", _unsubscribeVariations);
          _unsubscribeVariations();
        }
      };
    },
    [id, readVariations]
  );

  return (
    <>
      <Grid item>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <IconButton color="primary" onClick={() => navigate("/dashboard")}>
                <Home />
              </IconButton>
              <Button onClick={() => navigate("/products")}>
                <Typography>{t("products", "Ürünler")}</Typography>
              </Button>
              {!!products[0] ? (
                [
                  <Button key="breadcrumpProductName" onClick={() => navigate(`/product/${(products[0] as Product).id}`, {state: {product: products[0]}})}>
                    <Typography>{(products[0] as Product).name}</Typography>
                  </Button>,
                  <Button key="breadcrumpVariations" disabled>
                    <Typography>{t("variantions", "Varyantlar")}</Typography>
                  </Button>,
                ]
              ) : (
                <Typography>{t("loadind", "Yükleniyor...")}</Typography>
              )}
            </Breadcrumbs>
          </Grid>
          <Grid item>
            {!!productVariations.length && (
              <Grid container>
                <Grid item>
                  <label htmlFor="files-for-selected-variations">
                    <input disabled={!selection.length} hidden id="files-for-selected-variations" type="file" onChange={uploadPhotoForSelected} />
                    <Button component="span" startIcon={<CameraAlt />} disabled={!selection.length} variant="outlined">
                      {t("addImageToSelectedVariations", "Seçilenlere Görsel Yükle")}
                    </Button>
                  </label>
                </Grid>
                <Grid item>
                  <Button color="error" disabled={!selection.length} onClick={deleteSelectedVariations} startIcon={<Delete />} variant="contained">
                    {t("deleteSelectedVariations", "Seçilenleri Sil")}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {isLoadingProducts ? (
          <CircularProgress />
        ) : (
          <Grid container>
            <Grid item xs={12} md={4}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Paper className={classes.paperContent}>
                    <Button fullWidth onClick={handleAllVariations} variant="outlined">
                      {t("createVariantsFromAllAttributes", "Tüm Niteliklerden Varyant Oluştur")}
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  {!!products[0] && <ProductVariantsForm product={products[0]} productVariations={productVariations} />}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              {!!products[0] && (
                <Paper>
                  <GridDX
                    rows={productVariations}
                    columns={[
                      {name: "attachment", title: t("variationImage", "Ürün Görseli")},
                      {name: "name", title: t("variationName", "Varyasyon İsmi")},
                    ]}
                  >
                    <FilteringState columnExtensions={[{columnName: "attachment", filteringEnabled: false}]} filters={filters} onFiltersChange={setFilters} />
                    <SelectionState selection={selection} onSelectionChange={setSelection} />

                    {/**
                     *
                     * Aşağıdaki sıralama garip bir şekilde önemli.
                     * Eğer IntegratedFiltering sonra gelirse, tümünü seçe basıldığında tüm veriyi seçiyor.
                     * Önce gelirse filtrelenmiş veriyi seçiyor.
                     *
                     */}
                    <IntegratedFiltering />
                    <IntegratedSelection />

                    <DataTypeProvider
                      for={["attachment"]}
                      formatterComponent={({row, value}) =>
                        !!value && !!files[value] ? (
                          <Avatar alt={row.name} src={files[value]} variant="rounded" />
                        ) : (
                          <Avatar variant="rounded">
                            <CameraAlt />
                          </Avatar>
                        )
                      }
                    />
                    <TableDX columnExtensions={[{columnName: "attachment", width: 100}]} />
                    <TableHeaderRowDX />
                    <TableFilterRowDX />
                    <TableSelectionDX showSelectAll />
                  </GridDX>
                </Paper>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ProductVariantsWrite;
