import { Grid } from "@mui/material";

import ForgotPasswordForm from "../forms/forgot-password-form";

import useStyles from "../utilities/styles";

const ForgotPassword = function () {
  const classes = useStyles();

  const onSuccess = function ({ values }: { values: any }) {
    console.log("LoginRegister onSuccessLogin values", values);
  };

  return (
    <Grid display="flex" item justifyContent="center">
      <Grid className={classes.formLoginRegisterContainer} container direction="column" spacing={0}>
        <Grid item>
          <ForgotPasswordForm onSuccess={onSuccess} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
