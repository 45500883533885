import {Home} from "@mui/icons-material";
import {Breadcrumbs, Button, Grid, IconButton, Paper, Switch, Typography} from "@mui/material";
import {t} from "i18next";
import {ChangeEvent, Fragment, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {Grid as GridDX, Table as TableDX, TableHeaderRow as TableHeaderRowDX} from "@devexpress/dx-react-grid-material-ui";
import {DataTypeProvider} from "@devexpress/dx-react-grid";
import {useMetretulProducts} from "../utilities/hooks";
import {Company, ProductAttribute, ProductStatus} from "jack-shared";
import useStyles from "../utilities/styles";
import {product} from "jack-shared/assets/constants.json";

const ProductList = function ({companies}: {companies: Company[]}) {
  const columns = useRef<any[]>([
    {name: "name", title: t("productName", "Ürün")},
    {name: "categoryIds", title: t("productCategories", "Kategori")},
    {name: "listPrice", title: t("price", "Fiyat")},
    {name: "varianceType", title: t("productVarianceType", "Ürün Tipi")},
    {name: "editAction", title: " "},
    {name: "varianceAction", title: " "},
    {name: "status", title: " "},
  ]);
  const {products, subscribeToProducts, updateProduct} = useMetretulProducts();
  const classes = useStyles();

  let navigate = useNavigate();

  useEffect(
    function () {
      if (companies[0]?.id) {
        const _unsubscribe = subscribeToProducts(companies[0]?.id);
        console.log("🚀 ~ file: product-list.tsx ~ line 29 ~ ProductList ~ _unsubscribe", _unsubscribe);

        return function () {
          if (_unsubscribe) {
            _unsubscribe();
            console.log("🚀 ~ file: product-list.tsx ~ line 34 ~ _unsubscribe", _unsubscribe);
            console.count("ProductList__unsubscribe");
          }
        };
      }
    },
    [companies, subscribeToProducts]
  );

  useEffect(
    function () {
      console.log("🚀 ~ file: product-list.tsx ~ line 36 ~ products", products);
    },
    [products]
  );

  return (
    <>
      <Grid item xs={12}>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <IconButton color="primary" onClick={() => navigate("/dashboard")}>
                <Home />
              </IconButton>
              <Typography>{t("products", "Ürünler")}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Button onClick={() => navigate("/product")} variant="contained">
              {t("addProduct", "Yeni Ürün Ekleyin")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <GridDX rows={products} columns={columns.current}>
            <DataTypeProvider
              for={["editAction"]}
              formatterComponent={({row}) => (
                <Button onClick={() => navigate(`/product/${row?.id || ""}/variants`, {state: {product: row}})} size="small" variant="outlined">
                  {t("variances", "Varyantlar")}
                </Button>
              )}
            />
            <DataTypeProvider
              for={["categoryIds"]}
              formatterComponent={({value}) =>
                value.map((v: string, i: number) => (
                  <Typography key={`productCategoryId_${i}`} variant="body2">
                    {(product.categories as any)[v].name}
                  </Typography>
                ))
              }
            />
            <DataTypeProvider
              for={["varianceAction"]}
              formatterComponent={({row}) => (
                <Button onClick={() => navigate(`/product/${row?.id || ""}`, {state: {product: row}})} size="small" variant="outlined">
                  {t("editColor", "Düzenle")}
                </Button>
              )}
            />
            <DataTypeProvider
              for={["varianceType"]}
              formatterComponent={({row}) => (
                <>
                  {!!row.attributes.length && <Typography className={classes.typographyBody1ProductsTable} variant="body2">{`${row.attributes.length} ${t("variance", "varyant")}`}</Typography>}
                  {!!row.attributes.length &&
                    row.attributes.map((attribute: ProductAttribute, index: number) => (
                      <Fragment key={`attribute_${index}`}>
                        <br />
                        <Typography variantMapping={{caption: "span"}} variant="caption">
                          {attribute.name}
                        </Typography>
                      </Fragment>
                    ))}
                </>
              )}
            />
            <DataTypeProvider for={["status"]} formatterComponent={({row}) => <Switch checked={row.status === ProductStatus.available} onChange={async (event: ChangeEvent<HTMLInputElement>) => await updateProduct({...row, status: event.target.checked ? ProductStatus.available : ProductStatus.unavailable})} />} />
            <DataTypeProvider for={["listPrice"]} formatterComponent={({row}) => <>{new Intl.NumberFormat("tr-TR", {style: "currency", currency: row.listPrice.currency}).format(row.listPrice.amount)}</>} />
            <TableDX
              columnExtensions={[
                {columnName: "editAction", width: 100},
                {columnName: "varianceAction", width: 100},
                {columnName: "status", width: 100},
              ]}
            />
            <TableHeaderRowDX />
          </GridDX>
        </Paper>
      </Grid>
    </>
  );
};

export default ProductList;
