import {Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import {t} from "i18next";
import {Product, ProductVarianceType} from "jack-shared";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useSnapshot} from "valtio";
import {state} from "..";

const Subscription = function () {
  let navigate = useNavigate();
  const {subscription} = useSnapshot(state);

  return (
    <Card>
      {!!subscription?.order ? (
        <CardContent>
          <Typography variant="caption">{t("current_subscription", "Mevcut Abonelik")}</Typography>
          {subscription.order.cart.items
            .filter((item: any) => item.type === ProductVarianceType.simpleSubscription)
            .map((item: Product, index: number) => (
              <Typography key={`product_${index}`} variant="body1">
                {item.name}
              </Typography>
            ))}

          <Typography variant="body2">
            {t("expires_at", "Bitiş Tarihi")}: {moment(subscription.expiresAt).format("DD MMM YYYY HH:mm:ss")}
          </Typography>
        </CardContent>
      ) : (
        <>
          <CardContent>
            <Typography variant="caption">{t("current_subscription", "Mevcut Abonelik")}</Typography>
            <Typography>{t("no_subscription", "Aboneliğiniz bulunmuyor. Lütfen aboneliğinizi başlatın.")}</Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => navigate("/plans")} variant="contained">
              {t("subscribe", "Aboneliğinizi Seçin")}
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default Subscription;
