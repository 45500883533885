import {getAuth, signInWithEmailAndPassword, signOut, User, UserCredential} from "firebase/auth";
import {addDoc, collection, deleteDoc, doc, DocumentData, DocumentReference, DocumentSnapshot, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, query, Query, QueryDocumentSnapshot, QuerySnapshot, setDoc, startAfter, Timestamp, Unsubscribe, updateDoc, where, writeBatch} from "firebase/firestore";
import {deleteObject, FullMetadata, getDownloadURL, getMetadata, getStorage, listAll, ListResult, ref, StorageReference, uploadBytes, UploadResult} from "firebase/storage";
import {t} from "i18next";
import {Cart, Company, CompanySetting, Invoice, InvoiceStatus, InvoiceStatusCode, InvoiceStatusName, Message, Notification, NotificationStatusName, Order, Points, Product, ProductStatus, ProductVariation, Project, ProjectDeliverables, ProjectFavoritedCompany, ProjectStatus, ProjectStatusCode, ProjectStatusName, Role, slugify, Subscription, Table} from "jack-shared";
import {useAtom} from "jotai";
import {flattenDeep, uniqBy} from "lodash";
import {useCallback, useEffect, useRef, useState} from "react";
import {useSnapshot} from "valtio";
import {state} from "..";
import {errorsAtom} from "./atoms";
import {companySettingsConverter, fromFirestoreCompanyConverter, fromFirestoreInvoiceConverter, fromFirestoreProductConverter, messageConverter, notificationConverter, pointsConverter, productConverter, productVariationConverter, projectConverter, projectTemplateConverter, tableConverter} from "./converters";
import {queryNotifications, querySubscriptions} from "./queries";
import {createMember, createOrderRemote, makePaymentRemote} from "./remote";
import {createUserPropsType, useMetretulCompaniesPropsType, useMetretulProductsUploadFilesParamsType} from "./types";
const uniqid = require("uniqid");

export const useJackTables = function () {
  const [tables, setTables] = useState<Table[]>([]);
  const [loadingCreatingTables, setLoadingCreatingTables] = useState<boolean>(false);
  const [loadingEditingTable, setLoadingEditingTable] = useState<boolean>(false);
  const [loadingTables, setLoadingTables] = useState<boolean>(false);

  const createTables = useCallback(async (tableCount: number, numberOfExistingTables: number, company: Company) => {
    try {
      setLoadingCreatingTables(true);

      let _tables: Table[] = [];
      const _batch = writeBatch(getFirestore());

      for (let i = 0; i < tableCount; i++) {
        const _table: Table = {
          id: uniqid(),
          companyId: company.id,
          description: "",
          name: `${t("table", "Masa")} ${numberOfExistingTables + i + 1}`,
        };
        _tables.push(_table);

        console.log("🚀 ~ file: hooks.ts:36 ~ createTables ~ _tables", _tables);
      }

      _tables.forEach((_table) => _batch.set(doc(getFirestore(), "Tables", _table.id), _table));
      setTables(_tables);

      const _batchResult = await _batch.commit();
      console.log("🚀 ~ file: hooks.ts:46 ~ createTables ~ _batchResult", _batchResult);
    } catch (error) {
    } finally {
      setLoadingCreatingTables(false);
    }
  }, []);

  const deleteTable = useCallback(async (tableId: string) => {
    try {
      setLoadingEditingTable(true);

      const _deleteResult = await deleteDoc(doc(getFirestore(), `Tables/${tableId}`));
      console.log("🚀 ~ file: hooks.ts:60 ~ deleteTable ~ _deleteResult", _deleteResult);
    } catch (error) {
      state.errors = [...state.errors, (error as Error)?.message || "Bilinmeyen hata."];
    } finally {
      setLoadingEditingTable(false);
    }
  }, []);

  const editTableName = useCallback(async (tableId: string, tableName: string) => {
    try {
      setLoadingEditingTable(true);

      const _updateResult = await updateDoc(doc(getFirestore(), `Tables/${tableId}`), {name: tableName});
      console.log("🚀 ~ file: hooks.ts:73 ~ editTableName ~ _updateResult", _updateResult);
    } catch (error) {
      state.errors = [...state.errors, (error as Error)?.message || "Bilinmeyen hata."];
    } finally {
      setLoadingEditingTable(false);
    }
  }, []);

  const getTables = useCallback((companyId) => {
    try {
      setLoadingTables(true);

      return onSnapshot(query(collection(getFirestore(), "Tables"), where("companyId", "==", companyId)).withConverter(tableConverter), function (snapshot: QuerySnapshot<Table>) {
        let _tables: Table[] = [];

        snapshot.forEach((queryDocumentSnapshot) => {
          if (queryDocumentSnapshot.exists()) {
            _tables = [..._tables, queryDocumentSnapshot.data()];
          }
        });

        console.count("getTables");
        console.countReset("getTables");
        setTables(_tables);
      });
    } catch (error) {
      state.errors = [...state.errors, (error as Error)?.message || "Bilinmeyen hata."];
    } finally {
      setLoadingTables(false);
    }
  }, []);

  return {createTables, deleteTable, editTableName, getTables, tables, loadingCreatingTables, loadingEditingTable, loadingTables};
};

export const useJackCompanySettings = function () {
  const [companySettings, setCompanySettings] = useState<CompanySetting[]>([]);
  const [isLoadingCompanySettings, setIsLoadingCompanySettings] = useState<boolean>(false);

  const getCompanySettings = useCallback(async (companyId: string) => {
    try {
      setIsLoadingCompanySettings(true);

      let _companySettings: CompanySetting[] = [];

      // return onSnapshot(query(collection(getFirestore(), "CompanySettings"), where("companyId", "==", companyId)).withConverter(companySettingsConverter), (_companySettingQuerySnapshot: QuerySnapshot<CompanySetting>) => {
      //   _companySettingQuerySnapshot.forEach((_companySettingDocumentSnapshot) => {
      //     if (_companySettingDocumentSnapshot.exists()) {
      //       _companySettings = [..._companySettings, _companySettingDocumentSnapshot.data()];
      //     }
      //   });

      //   setCompanySettings(_companySettings);
      // });

      const _companySettingsQuerySnapshot = await getDocs(query(collection(getFirestore(), "CompanySettings"), where("companyId", "==", companyId)).withConverter(companySettingsConverter));

      _companySettingsQuerySnapshot.forEach((_companySettingQueryDocumentSnapshot: QueryDocumentSnapshot<CompanySetting>) => {
        if (_companySettingQueryDocumentSnapshot.exists()) {
          _companySettings = [..._companySettings, _companySettingQueryDocumentSnapshot.data()];
        }
      });

      setCompanySettings(_companySettings);
    } catch (error: any) {
      state.errors = [...state.errors, error?.message || JSON.stringify(error) || t("unknownErrorgetCompanySettings", "Bilinmeyen hata: getCompanySettings")];
    } finally {
      setIsLoadingCompanySettings(false);
    }
  }, []);

  const updateCompanySetting = useCallback(async (companySettingId, newCompanySettingValue) => {
    try {
      const _companySettingUpdateDocResult = updateDoc(doc(getFirestore(), "CompanySettings", companySettingId), newCompanySettingValue);
      console.log("🚀 ~ file: hooks.ts:136 ~ updateCompanySetting ~ _companySettingUpdateDocResult:", _companySettingUpdateDocResult);
    } catch (error: any) {
      state.errors = [...state.errors, error?.message || JSON.stringify(error) || t("unknownErrorgetUpdateCompanySetting", "Bilinmeyen hata: updateCompanySetting")];
    }
  }, []);

  return {companySettings, getCompanySettings, isLoadingCompanySettings, updateCompanySetting};
};

export const useJackPoints = function () {
  const [points, setPoints] = useState<Points[]>([]);

  const getPoints = useCallback((memberId: string) => {
    let _points: Points[] = [];

    return onSnapshot(query(collection(getFirestore(), "Points"), where("memberId", "==", memberId)).withConverter(pointsConverter), (querySnapshot: QuerySnapshot<Points>) => {
      querySnapshot.forEach((queryDocumentSnapshot) => {
        if (queryDocumentSnapshot.exists()) {
          _points = [..._points, queryDocumentSnapshot.data()];
        }
      });

      setPoints(_points);
    });
  }, []);

  return {points, getPoints};
};

export const useMetretulAuthentication = function () {
  const [loadingAuthentication, setLoadingAutentication] = useState<boolean>(false);

  const createUser = async function ({email, name, password, phoneNumber}: createUserPropsType) {
    try {
      console.log("🚀 ~ file: hooks.ts ~ line 26 ~ createUser ~ setLoadingAutentication(true)");
      setLoadingAutentication(true);

      const _resultCreateMember: any = await createMember({displayName: name, email, password, phoneNumber});
      console.log("🚀 ~ file: AUTH_DEMO_APP.tsx ~ line 22 ~ signInUser ~ _resultCreateMember", _resultCreateMember);

      state.errors = [];

      return getAuth().currentUser;
    } catch (error) {
      console.error(`useMetretulAuthentication signIn error: ${(error as Error).message || error || "Bilinmeyen hata."}`);
      state.errors = [...state.errors, (error as Error)?.message || "Bilinmeyen hata."];
    } finally {
      setLoadingAutentication(false);
    }
  };

  const signInUser = async function (email: string, password: string) {
    try {
      setLoadingAutentication(true);

      const _userCredential: UserCredential = await signInWithEmailAndPassword(getAuth(), email, password);
      console.log("🚀 ~ file: AUTH_DEMO_APP.tsx ~ line 22 ~ signInUser ~ _userCredential", _userCredential);

      return _userCredential;
    } catch (error) {
      state.errors = [...state.errors, (error as Error)?.message || "Bilinmeyen hata."];
    } finally {
      setLoadingAutentication(false);
    }
  };

  const signOutUser = async function () {
    try {
      setLoadingAutentication(true);

      const _void: void = await signOut(getAuth());
      console.log("🚀 ~ file: AUTH_DEMO_APP.tsx ~ line 22 ~ signOutUser ~ _void", _void);

      // const _user = (await signInAnonymously(getAuth())).user;
      // console.log("🚀 ~ file: hooks.ts:152 ~ signOutUser ~ _user:", _user);
    } catch (error) {
      console.error(`useMetretulAuthentication signOut error: ${(error as Error).message || error || "Bilinmeyen hata."}`);
    } finally {
      setLoadingAutentication(false);
    }
  };

  return {createUser, loadingAuthentication, signInUser, signOutUser};
};

export const useMetretulBankAccounts = function () {
  const [loadingBankAccounts, setLoadingBankAccounts] = useState<boolean>(false);
  const [bankAccounts, setBankAccounts] = useState<any[]>([]);

  const getBankAccounts = useCallback(async () => {
    try {
      setLoadingBankAccounts(true);

      let _bankAccounts: any[] = [];
      const _bankAccountsQuerySnapshot = await getDocs(collection(getFirestore(), "BankAccounts"));

      if (_bankAccountsQuerySnapshot.empty) return setBankAccounts([]);

      _bankAccountsQuerySnapshot.forEach((bankAccountDocumentData) => {
        if (bankAccountDocumentData.exists()) {
          _bankAccounts = [..._bankAccounts, bankAccountDocumentData.data()];
        }
      });

      console.log("🚀 ~ file: hooks.ts:97 ~ getBankAccounts ~ _bankAccounts", _bankAccounts);
      setBankAccounts(_bankAccounts);

      return _bankAccounts;
    } catch (error) {
      console.error(error);
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "getBankAccounts hata."];
    } finally {
      setLoadingBankAccounts(false);
    }
  }, []);

  return {bankAccounts, getBankAccounts, loadingBankAccounts};
};

export const useMetretulCompanies = function ({customQuery}: useMetretulCompaniesPropsType = {}) {
  const [cities, setCities] = useState<string[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companiesWithIds, setCompaniesWithIds] = useState<any | null>(null);
  const {currentUser: member} = useSnapshot(state);
  const [, setErrors] = useAtom(errorsAtom);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(true);
  const [isLoadingCompany, setIsLoadingCompany] = useState(true);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [allCompanies, setAllCompanies] = useState<Company[]>([]);
  const [isLoadingAllCompanies, setIsLoadingAllCompanies] = useState<boolean>(false);

  const getAllCompanies = useCallback(async () => {
    try {
      setIsLoadingAllCompanies(true);

      let _allCompanies: Company[] = [];

      const _allCompaniesQuerySnapshot = await getDocs(collection(getFirestore(), "Companies").withConverter(fromFirestoreCompanyConverter));

      _allCompaniesQuerySnapshot.forEach((c) => {
        if (c.exists()) {
          _allCompanies = [..._allCompanies, c.data()];
        }
      });

      setAllCompanies(_allCompanies);
    } catch (error: any) {
      state.errors = [...state.errors, error?.message || JSON.stringify(error) || t(`unknownErrorGetAllCompanies`, `Bilinmeyen Hata: getAllCompanies`)];
    } finally {
      setIsLoadingAllCompanies(false);
    }
  }, []);

  const getCompanies = useCallback(
    function () {
      try {
        setIsLoadingCompanies(true);

        if (!member) {
          throw new Error(t("memberRequires", "Üye bilgileri gerekli."));
        }

        const _database = getFirestore();
        const _query: Query = !!customQuery ? customQuery : query(collection(_database, "Companies"), where("administratorIds", "array-contains", member.uid));
        const _queryWithConverter: Query<Company> = _query.withConverter(fromFirestoreCompanyConverter);

        return onSnapshot(_queryWithConverter, function (querySnapshot: QuerySnapshot<Company>) {
          let _companies: Company[] = [];
          let _companiesWithIds = {};

          querySnapshot.forEach(function (companyDocumentSnapshot) {
            if (companyDocumentSnapshot.exists()) {
              let _company: Company = companyDocumentSnapshot.data();

              _companies = [..._companies, _company];
              _companiesWithIds = {..._companiesWithIds, [companyDocumentSnapshot.id]: _company};
            }
          });

          setCities(_companies.map((_company) => _company.city));
          setCompanies(_companies);
          setCompaniesWithIds(_companiesWithIds);
        });
      } catch (error) {
        setErrors(error as any);
      } finally {
        setIsLoadingCompanies(false);
      }
    },
    [customQuery, member, setErrors]
  );

  const getCompany = useCallback((companyId) => {
    setIsLoadingCompany(true);

    return onSnapshot(doc(getFirestore(), `Companies/${companyId}`).withConverter(fromFirestoreCompanyConverter), (snapshot: DocumentSnapshot<Company>) => {
      if (!snapshot.exists()) {
        setIsLoadingCompany(false);
        setCompany(undefined);
      }

      setCompany(snapshot.data());

      setIsLoadingCompany(false);
    });
  }, []);

  useEffect(
    function () {
      const _unsubscribe = getCompanies();

      return function () {
        if (!!_unsubscribe) {
          _unsubscribe();
          console.log("hooks.ts useEffect return getCompanies");
        }
      };
    },
    [getCompanies]
  );

  return {allCompanies, cities, companies, company, companiesWithIds, getAllCompanies, getCompanies, getCompany, isLoadingAllCompanies, isLoadingCompanies, isLoadingCompany};
};

export const useMetretulNotifications = function () {
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState<Notification[]>([]);
  const {currentUser: member} = useSnapshot(state);

  const getNotifications = useCallback(
    (unreadOnly = false) => {
      try {
        if (!!member) {
          setLoadingNotifications(true);

          return onSnapshot(queryNotifications(member.uid, unreadOnly).withConverter(notificationConverter), (notificationQuerySnapshot) => {
            let _notifications: Notification[] = [];

            notificationQuerySnapshot.forEach((notificationQueryDocumentSnapshot) => {
              if (notificationQueryDocumentSnapshot.exists()) {
                _notifications = [..._notifications, notificationQueryDocumentSnapshot.data()];
              }
            });

            console.log("🚀 ~ file: hooks.ts:148 ~ notificationQuerySnapshot.forEach ~ _notifications", _notifications);
            setNotifications(_notifications);
            setUnreadNotifications(_notifications.filter((n) => n.status.name === NotificationStatusName.unread));
          });
        }
      } catch (error) {
        console.error(error);
        state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "getNotifications hata."];
      } finally {
        setLoadingNotifications(false);
      }
    },
    [member]
  );

  const markAsRead = useCallback(async (notificationIds: string[] = [], callback?: () => void) => {
    if (!!notificationIds.length) {
      const _batch = writeBatch(getFirestore());
      notificationIds.forEach((notificationId) => _batch.update(doc(getFirestore(), "Notifications", notificationId), {status: {name: NotificationStatusName.read, updatedAt: Timestamp.now().toDate()}}));

      const _batchResult = await _batch.commit();
      console.log("🚀 ~ file: hooks.ts:166 ~ markAsRead ~ _batchResult", _batchResult);
    }

    if (!!callback) callback();
  }, []);

  return {getNotifications, loadingNotifications, markAsRead, notifications, unreadNotifications};
};

export const useMetretulInvoices = function (projectId?: string, companyId: string = "") {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const {currentUser: member} = useSnapshot(state);
  const [writeInvoiceResult, setWriteInvoiceResult] = useState<boolean | null>(null);
  const [loadingWriteInvoice, setLoadingWriteInvoice] = useState<boolean>(false);

  const getInvoices = useCallback(
    function () {
      try {
        const _queryWithCompanyId = query(collection(getFirestore(), "Invoices"), orderBy("createdAt", "desc"), where("projectId", "==", projectId), where("companyId", "==", companyId));
        const _queryWithoutCompanyId = query(collection(getFirestore(), "Invoices"), orderBy("createdAt", "desc"), where("projectId", "==", projectId));

        return onSnapshot((!!companyId ? _queryWithCompanyId : _queryWithoutCompanyId).withConverter(fromFirestoreInvoiceConverter), function (querySnapshot: QuerySnapshot<Invoice>) {
          let _invoices: Invoice[] = [];

          querySnapshot.forEach(function (invoiceQueryDocumentSnapshot: QueryDocumentSnapshot<Invoice>) {
            if (invoiceQueryDocumentSnapshot.exists()) {
              const _invoice: Invoice = {...invoiceQueryDocumentSnapshot.data(), id: invoiceQueryDocumentSnapshot.id};

              _invoices = [..._invoices, _invoice];
            }
          });

          _invoices.forEach((_invoice) => {});

          setInvoices(_invoices);
          console.log("🚀 ~ file: hooks.ts ~ line 229 ~ _invoices", _invoices);
        });
      } catch (error) {}
    },
    [companyId, projectId]
  );

  const updateInvoice = async function (invoice: Invoice, statusName: InvoiceStatusName, statusCode: InvoiceStatusCode) {
    console.log("🚀 ~ file: hooks.ts ~ line 202 ~ updateInvoice ~ statusName", statusName);
    console.log("🚀 ~ file: hooks.ts ~ line 202 ~ updateInvoice ~ invoice", invoice);
    const _status: InvoiceStatus = {
      ...invoice.status,
      code: statusCode,
      name: statusName,
      updatedAt: Timestamp.now().toDate(),
      updatedByMemberId: member?.uid || "",
    };

    const _invoiceUpdateResult = await updateDoc(doc(getFirestore(), "Invoices", invoice.id), {status: _status});
    console.log("🚀 ~ file: hooks.ts ~ line 111 ~ const_invoiceUpdateResult=awaitupdateDoc ~ _invoiceUpdateResult", _invoiceUpdateResult);
  };

  const writeInvoice = async function (invoice: Invoice) {
    try {
      setLoadingWriteInvoice(true);

      console.log("🚀 ~ file: hooks.ts ~ line 231 ~ writeInvoice ~ invoice", invoice);

      const _invoiceWriteResult = await setDoc(doc(getFirestore(), "Invoices", invoice.id), invoice);
      console.log("🚀 ~ file: hooks.ts ~ line 121 ~ _invoiceWriteResult", _invoiceWriteResult);

      setWriteInvoiceResult(true);
    } catch (error) {
      console.error(error);
      setWriteInvoiceResult(false);
    } finally {
      setLoadingWriteInvoice(false);
    }
  };

  useEffect(
    function () {
      const _unsubscribe = getInvoices();

      return function () {
        if (!!_unsubscribe) {
          console.log("🚀 ~ file: hooks.ts ~ line 255 ~ useMetretulInvoices ~ getInvoices _unsubscribe");
          _unsubscribe();
        }
      };
    },
    [getInvoices]
  );

  return {invoices, loadingWriteInvoice, updateInvoice, writeInvoice, writeInvoiceResult};
};

export const useMetretulMessages = function (project: Project, company: Company) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
  const [errors, setErrors] = useAtom(errorsAtom);

  const getMessages = useCallback(
    function () {
      try {
        if (!!project) {
          setIsLoadingMessages(true);

          const _query = query(collection(getFirestore(), "Messages"), orderBy("createdAt", "desc"), where("relatedProjectId", "==", project.id), where("channelId", "==", `${project.id}_${company.id}`), limit(30));
          const _queryWithConverter = _query.withConverter(messageConverter);

          return onSnapshot(_queryWithConverter, function (querySnapshot: QuerySnapshot) {
            let _messages: Message[] = [];

            querySnapshot.forEach(function (messagesDocumentSnapshot: DocumentSnapshot<DocumentData>) {
              if (messagesDocumentSnapshot.exists()) {
                let _message: Message = messagesDocumentSnapshot.data() as Message;

                _messages = [..._messages, _message];
              }
            });

            setMessages(_messages.reverse());
          });
        }
      } catch (error) {
        console.error(`useMetretulMessages getMessages error: ${(error as Error).message || error || t("unknownError", "Bilinmeyen Hata")}`);
        setErrors((e: unknown[]) => [...e, error]);
      } finally {
        setIsLoadingMessages(false);
      }
    },
    [company.id, project, setErrors]
  );

  const sendMessage = async function (message: Message) {
    try {
      setIsLoadingMessages(true);

      if (!message) {
        throw new Error(t("messageIsRequired", "Mesaj bilgisi gerekli."));
      }

      const _projectResult = await addDoc(collection(getFirestore(), "Messages"), message);
      console.log("🚀 ~ file: hooks.ts ~ line 194 ~ sendMessage ~ _projectResult", _projectResult);
    } catch (error) {
      console.error(`useMetretulMessages sendMessage error: ${(error as Error).message || error || t("unknownError", "Bilinmeyen Hata")}`);
    } finally {
      setIsLoadingMessages(false);
    }
  };

  useEffect(
    function () {
      const _unsubscribe = getMessages();

      return function () {
        console.log("🚀 ~ file: hooks.ts ~ line 196 ~ useMetretulMessages ~ _unsubscribe", _unsubscribe);

        if (!!_unsubscribe) {
          _unsubscribe();
          console.log("🚀 ~ file: hooks.ts ~ line 200 ~ returnfunction ~ _unsubscribe", _unsubscribe);
        }
      };
    },
    [getMessages]
  );

  return {errors, isLoadingMessages, messages, sendMessage};
};

export const useMetretulPayment = function () {
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentResult, setPaymentResult] = useState<any | null>(null);
  const {currentUser} = useSnapshot(state);

  const makePayment = useCallback(
    async function (cart, creditCard) {
      try {
        setLoading(true);

        const _ipResponse = await fetch("https://geolocation-db.com/json/");
        const _ipJson = await _ipResponse.json();
        const _ip = _ipJson?.IPv4 || "123.123.123.123";
        console.log("🚀 ~ file: hooks.ts ~ line 303 ~ makePayment ~ _ip", _ip);

        console.log("🚀 ~ file: hooks.ts:307 ~ creditCard", creditCard);
        const _paymentResult = await makePaymentRemote(cart, creditCard, _ip, {...currentUser, email: currentUser?.email || `norepl+${currentUser?.uid}@dijitalgozluk.com`} as User);
        setPaymentResult(_paymentResult);

        return _paymentResult;
      } catch (error) {
        console.error(error);
        state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "makePayment hata."];
      } finally {
        setLoading(false);
      }
    },
    [currentUser]
  );

  return {makePayment, loading, paymentResult};
};

export const useMetretulProducts = function () {
  const [errors, setErrors] = useAtom(errorsAtom);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
  const [isLoadingProductVariations, setIsLoadingProductVariations] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [companyProducts, setCompanyProducts] = useState<Product[]>([]);
  const [productVariations, setProductVariations] = useState<ProductVariation[]>([]);

  const deleteProductVariations = useCallback(async function (productVariationIds: string[] = []) {
    try {
      setIsLoadingProductVariations(true);

      if (!productVariationIds.length) throw new Error(t("productVariationId", "Ürün varyasyon kimlik bilgisi gereklidir."));

      const _batch = writeBatch(getFirestore());

      productVariationIds.forEach((productVariationId) => _batch.delete(doc(getFirestore(), "ProductVariations", productVariationId)));

      const _batchResult = await _batch.commit();
      console.log("🚀 ~ file: hooks.ts ~ line 374 ~ deleteProductVariations ~ _batchResult", _batchResult);
    } catch (error) {
      console.log("🚀 ~ file: hooks.ts ~ line 178 ~ deleteProductVariations ~ error", error);
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "deleteProductVariations hata."];
    } finally {
      setIsLoadingProductVariations(false);
    }
  }, []);

  const disableProduct = useCallback(async function (productId: string, status: ProductStatus) {
    try {
      setIsLoadingProducts(true);

      const _productDocumentReference = await updateDoc(doc(getFirestore(), "Products", productId), {status});
      console.log("🚀 ~ file: hooks.ts ~ line 83 ~ deleteProduct ~ _productDocumentReference", _productDocumentReference);
    } catch (error) {
      console.error(`hooks.ts useMetretulProducts disableProduct error: ${(error as Error)?.message || error || t("unknownError", "Bilinmeyen Hata.")}`);
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "disableProduct hata."];
    } finally {
      setIsLoadingProducts(false);
    }
  }, []);

  const getProducts = useCallback(async function (_query?: Query): Promise<void> {
    try {
      setIsLoadingProducts(true);

      let _products: Product[] = [];

      const _query = query(collection(getFirestore(), "Products"), limit(5));
      const _productsQuerySnapshot = await getDocs(_query.withConverter(fromFirestoreProductConverter));

      _productsQuerySnapshot.forEach(function (_productQueryDocumentSnapshot) {
        if (_productQueryDocumentSnapshot.exists()) {
          const _product: Product = {..._productQueryDocumentSnapshot.data(), id: _productQueryDocumentSnapshot.id} as Product;
          console.log("🚀 ~ file: hooks.ts ~ line 137 ~ _product", _product);

          _products = [..._products, _product];
        }

        setProducts(_products);
      });
    } catch (error) {
      console.error("🚀 ~ file: hooks.ts ~ line 82 ~ getProducts ~ error", error);
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "getProducts hata."];
    } finally {
      setIsLoadingProducts(false);
    }
  }, []);

  const getProductsByCompanyId = useCallback(async function (companyId: string) {
    try {
      setIsLoadingProducts(true);

      let _products: Product[] = [];

      const _query = query(collection(getFirestore(), "Products"), limit(5));
      const _productsByQueryQuerySnapshot = await getDocs(_query.withConverter(fromFirestoreProductConverter));
      console.log("🚀 ~ file: hooks.ts:577 ~ getProductsByCompanyId ~ _productsByQueryQuerySnapshot", _productsByQueryQuerySnapshot);

      _productsByQueryQuerySnapshot.forEach(function (_productQueryDocumentSnapshot) {
        console.log("🚀 ~ file: hooks.ts:583 ~ _productQueryDocumentSnapshot", _productQueryDocumentSnapshot);
        if (_productQueryDocumentSnapshot.exists()) {
          const _product: Product = {..._productQueryDocumentSnapshot.data(), id: _productQueryDocumentSnapshot.id} as Product;
          console.log("🚀 ~ file: hooks.ts ~ line 157 ~ _product", _product);

          _products = [..._products, _product];
        }

        setCompanyProducts(_products);
      });
    } catch (error) {
    } finally {
      setIsLoadingProducts(false);
    }
  }, []);

  const getProductsByQuery = useCallback(async function (query: Query) {
    try {
      setIsLoadingProducts(true);

      if (!query) {
        throw new Error(t("queryIsRequiredForGetproductsByQuery", "Ürün sorgusu gerekli."));
      }

      let _products: Product[] = [];

      const _productsByQueryQuerySnapshot = await getDocs(query.withConverter(fromFirestoreProductConverter));

      _productsByQueryQuerySnapshot.forEach(function (_productQueryDocumentSnapshot) {
        if (_productQueryDocumentSnapshot.exists()) {
          const _product: Product = {..._productQueryDocumentSnapshot.data(), id: _productQueryDocumentSnapshot.id} as Product;
          console.log("🚀 ~ file: hooks.ts ~ line 157 ~ _product", _product);

          _products = [..._products, _product];
        }

        setProducts(_products);
      });
    } catch (error) {
    } finally {
      setIsLoadingProducts(false);
    }
  }, []);

  const getProductVariation = useCallback(
    async function (productVariationId: string) {
      try {
        setIsLoadingProductVariations(true);

        const _productVariationSnapshot: DocumentSnapshot<ProductVariation> = await getDoc(doc(getFirestore(), `ProductVariations/${productVariationId}`).withConverter(productVariationConverter));
        console.log("🚀 ~ file: hooks.ts ~ line 725 ~ _projectSnapshot", _productVariationSnapshot.data());

        if (_productVariationSnapshot.exists()) setProductVariations([_productVariationSnapshot.data()]);
      } catch (error) {
        console.error((error as Error).message);
        setErrors((e: any[]) => [...e, error]);
      } finally {
        setIsLoadingProductVariations(false);
      }
    },
    [setErrors]
  );

  const getProductVariations = useCallback(async function (productId: string, attributes: {[attributeName: string]: string}) {
    try {
      setIsLoadingProductVariations(true);
      if (!productId) throw new Error(t("productIdIsRequired", "getProductVariations için productId parametresi gereklidir."));
      if (!attributes) throw new Error(t("attributesIsRequired", "getProductVariations için attributes parametresi gereklidir."));

      // ...(!!attributes.length ? [where("includedAttributeValues", "not-in", attributes)] : [])

      const _productVariationsReference = collection(getFirestore(), "ProductVariations");
      const _attributesFilter = !!Object.keys(attributes).length ? Object.keys(attributes).map((attributeName) => where(`includedAttributeValues.${attributeName}`, "==", attributes[attributeName])) : [];
      const _query1 = query(_productVariationsReference, where("variationOfProductId", "==", productId), ..._attributesFilter);
      const _productVariationsQuerySnapshot: QuerySnapshot<ProductVariation> = await getDocs(_query1.withConverter(productVariationConverter));

      let _productVariations: ProductVariation[] = [];
      if (_productVariationsQuerySnapshot.size <= 0) return setProductVariations(_productVariations);

      _productVariationsQuerySnapshot.forEach(function (_productVariationQueryDocumentSnapshot: QueryDocumentSnapshot<ProductVariation>) {
        if (_productVariationQueryDocumentSnapshot.exists()) {
          _productVariations = [..._productVariations, _productVariationQueryDocumentSnapshot.data()];
        }
      });

      setProductVariations(_productVariations);

      return _productVariations;
    } catch (error) {
      console.error(`getProductVariations error: ${(error as Error).message || JSON.stringify(error) || t("unknownError", "Bilinmeyen hata.")}`);
    } finally {
      setIsLoadingProductVariations(false);
    }
  }, []);

  const readProduct = useCallback(
    function (productId: string) {
      try {
        setIsLoadingProducts(true);

        if (!productId) {
          throw new Error(t("productIdIsRequired", "Ürün kimlik bilgisi gereklidir."));
        }

        const _unsubscribe: Unsubscribe = onSnapshot(doc(getFirestore(), "Products", productId).withConverter(fromFirestoreProductConverter), function (doc) {
          console.log("🚀 ~ file: hooks.ts ~ line 184 ~ doc", doc);
          if (doc.exists()) {
            const _product: Product = doc.data() as Product;

            console.log("🚀 ~ file: hooks.ts ~ line 142 ~ _product", _product);
            setProducts([_product]);
          }
        });
        return _unsubscribe;
      } catch (error) {
        console.log("🚀 ~ file: hooks.ts ~ line 178 ~ readProduct ~ error", error);
        setErrors((e: any[]) => [...e, error]);
      } finally {
        setIsLoadingProducts(false);
      }
    },
    [setErrors]
  );

  const readVariations = useCallback(
    function (productId: string) {
      try {
        setIsLoadingProductVariations(true);

        if (!productId) throw new Error(t("productIdIsRequired", "Ürün kimlik bilgisi gereklidir."));

        const _query = query(collection(getFirestore(), "ProductVariations"), where("variationOfProductId", "==", productId)).withConverter(productVariationConverter);
        const _unsubscribe: Unsubscribe = onSnapshot(_query, (productVariationSnapshot: QuerySnapshot<ProductVariation>) => {
          console.log("🚀 ~ file: hooks.ts ~ line 511 ~ productVariationSnapshot", productVariationSnapshot);

          if (productVariationSnapshot.empty) return setProductVariations([]);

          let _productVariations: ProductVariation[] = [];

          productVariationSnapshot.forEach((productVariationSnapshot) => {
            if (productVariationSnapshot.exists()) {
              _productVariations = [..._productVariations, productVariationSnapshot.data()];
            }
          });

          setProductVariations(_productVariations);
        });

        return _unsubscribe;
      } catch (error) {
        console.log("🚀 ~ file: hooks.ts ~ line 178 ~ readVariations ~ error", error);
        setErrors((e: any[]) => [...e, error]);
      } finally {
        setIsLoadingProductVariations(false);
      }
    },
    [setErrors]
  );

  const updateProduct = useCallback(
    async function (product: Product) {
      try {
        setIsLoadingProducts(true);

        console.log("🚀 ~ file: hooks.ts ~ line 161 ~ updateProduct ~ product", product);
        console.log("🚀 ~ file: hooks.ts ~ line 162 ~ updateProduct ~ product.id", product.id);
        const _updateProductResult: void = await updateDoc(doc(getFirestore(), "Products", product.id), {...product});
        console.log("🚀 ~ file: hooks.ts ~ line 161 ~ updateProduct ~ _updateProductResult", _updateProductResult);

        return _updateProductResult;
      } catch (error) {
        console.log("🚀 ~ file: hooks.ts ~ line 159 ~ updateProduct ~ error", error);
        setErrors((e: any[]) => [...e, error]);
      } finally {
        setIsLoadingProducts(false);
      }
    },
    [setErrors]
  );

  const resetProductsVariations = useCallback(function () {
    setProductVariations([]);
  }, []);

  const subscribeToProducts = useCallback(function (companyId, resultLimit = 20) {
    return onSnapshot(
      query(collection(getFirestore(), "Products"), limit(resultLimit), where("companyId", "==", companyId)).withConverter(productConverter),
      function (querySnapshot: QuerySnapshot<Product>) {
        let _products: Product[] = [];
        console.log("🚀 ~ file: hooks.ts ~ line 490 ~ subscribeToProducts ~ querySnapshot", querySnapshot);
        console.log("🚀 ~ file: hooks.ts ~ line 490 ~ subscribeToProducts ~ querySnapshot.size", querySnapshot.size);

        querySnapshot.forEach(function (productDocumentSnapshot: DocumentSnapshot<Product>) {
          if (productDocumentSnapshot.exists()) {
            let _product = productDocumentSnapshot.data();
            console.log("🚀 ~ file: hooks.ts ~ line 493 ~ _product", _product);

            _products = [..._products, _product];
          }
        });

        setProducts(_products);
      },
      function (error) {
        console.error(`subscribeToProducts error: ${error.message}`);
        setProducts([]);
      }
    );
  }, []);

  const writeProduct = useCallback(
    async function (product: Product) {
      try {
        setIsLoadingProducts(true);
        console.log("🚀 ~ file: hooks.ts ~ line 162 ~ writeProduct ~ product", product);

        const _productDocumentReference: void = await setDoc(doc(getFirestore(), "Products", product.id), product);
        console.log("🚀 ~ file: hooks.ts ~ line 82 ~ writeProduct ~ _productDocumentReference", _productDocumentReference);

        return _productDocumentReference;
      } catch (error) {
        console.error("🚀 ~ file: hooks.ts ~ line 80 ~ getProducts ~ error", (error as Error).message);
        setErrors((e: any[]) => [...e, error]);
      } finally {
        setIsLoadingProducts(false);
      }
    },
    [setErrors]
  );

  const writeProductVariation = useCallback(async function (variation: ProductVariation) {
    try {
      setIsLoadingProductVariations(true);

      const _variationDocumentReference: void = await setDoc(doc(getFirestore(), "ProductVariations", variation.id), variation);
      console.log("🚀 ~ file: hooks.ts ~ line 539 ~ writeProductVariation ~ _variationDocumentReference", _variationDocumentReference);

      return _variationDocumentReference;
    } catch (error) {
      console.error(`writeProductVariations error: ${(error as Error).message || JSON.stringify(error) || t("unknownError", "Bilinmeyen hata oluştu.")}`);
    } finally {
      setIsLoadingProductVariations(false);
    }
  }, []);

  const writeProductVariations = useCallback(async function (variations: ProductVariation[]) {
    try {
      setIsLoadingProductVariations(true);

      const _batch = writeBatch(getFirestore());

      /**
       * ÖNEMLİ NOT!
       * Tüm Niteliklerden Varyant Oluştur butona basınca, eğer önceden eklenmiş varyant varsa burada onları siliyor.
       */
      const _uniqProducts = uniqBy(variations, "variationOfProductId").map((variation) => variation.variationOfProductId);
      console.log("🚀 ~ file: hooks.ts ~ line 648 ~ writeProductVariations ~ _uniqProducts", _uniqProducts);
      const q = query(collection(getFirestore(), "ProductVariations"), where("variationOfProductId", "in", _uniqProducts));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((document) => {
        _batch.delete(doc(getFirestore(), "ProductVariations", document.id));
      });

      variations.forEach((variation) => _batch.set(doc(getFirestore(), "ProductVariations", variation.id), variation));

      const _batchResult = await _batch.commit();
      console.log("🚀 ~ file: hooks.ts ~ line 539 ~ writeProductVariations ~ _batchResult", _batchResult);
    } catch (error) {
      console.error(`writeProductVariations error: ${(error as Error).message || JSON.stringify(error) || t("unknownError", "Bilinmeyen hata oluştu.")}`);
    } finally {
      setIsLoadingProductVariations(false);
    }
  }, []);

  return {companyProducts, deleteProductVariations, disableProduct, errors, getProducts, getProductsByCompanyId, getProductsByQuery, getProductVariation, getProductVariations, isLoadingProducts, isLoadingProductVariations, products, productVariations, resetProductsVariations, subscribeToProducts, readProduct, readVariations, updateProduct, writeProduct, writeProductVariation, writeProductVariations};
};

export const useMetretulProjects = function (customQuery: Query | null = null) {
  const [project, setProject] = useState<Project | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(true);
  const [isUpdatingProject, setIsUpdatingProject] = useState<boolean>(true);
  const {currentUser: member} = useSnapshot(state);
  const [isLoadingProjectScope, setIsLoadingProjectScope] = useState<boolean>(false);

  async function writeProjectScope(values: any) {
    try {
      setIsLoadingProjectScope(true);

      const _projectScopeDocumentReference: DocumentReference = await addDoc(collection(getFirestore(), "ProjectScopes"), values);
      console.log("🚀 ~ file: hooks.ts ~ line 523 ~ writeProjectScope ~ _projectScopeDocumentReference", _projectScopeDocumentReference);

      return _projectScopeDocumentReference;
    } catch (error) {
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "writeProjectScope hata."];
    } finally {
      setIsLoadingProjectScope(false);
    }
  }

  const createProject = async function (project: Project | null | undefined) {
    try {
      setIsLoadingProjects(true);

      if (!project) {
        throw new Error(t("projectIsRequired", "Proje bilgisi gerekli."));
      }

      console.log("🚀 ~ file: hooks.ts ~ line 79 ~ createProject ~ project", project);
      const _projectResult = await setDoc(doc(getFirestore(), "Projects", project.id), project);
      console.log("🚀 ~ file: hooks.ts ~ line 79 ~ createProject ~ _projectResult", _projectResult);

      setProjects([project]);
    } catch (error) {
      console.error((error as Error).message);
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "createProject hata."];
    } finally {
      setIsLoadingProjects(false);
    }
  };

  const getProject = useCallback(async function (projectId: string) {
    try {
      setIsLoadingProjects(true);

      const _projectSnapshot: DocumentSnapshot<Project> = await getDoc(doc(getFirestore(), `Projects/${projectId}`).withConverter(projectConverter));
      console.log("🚀 ~ file: hooks.ts ~ line 725 ~ _projectSnapshot", _projectSnapshot.data());

      if (_projectSnapshot.exists()) setProject(_projectSnapshot.data());
    } catch (error) {
      console.error((error as Error).message);
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "getProject hata."];
    } finally {
      setIsLoadingProjects(false);
    }
  }, []);

  const getProjects = useCallback(
    function () {
      try {
        if (!!customQuery) {
          console.log("🚀 ~ file: hooks.ts ~ line 99 ~ useMetretulProjects ~ customQuery", customQuery);

          setIsLoadingProjects(true);

          return onSnapshot(customQuery.withConverter(projectConverter), function (querySnapshot: QuerySnapshot) {
            let _projects: Project[] = [];

            querySnapshot.forEach(function (projectsDocumentSnapshot: DocumentSnapshot<DocumentData>) {
              if (projectsDocumentSnapshot.exists()) {
                let _project: Project = projectsDocumentSnapshot.data() as Project;
                console.log("🚀 ~ file: hooks.ts ~ line 112 ~ _project", _project);

                _projects = [..._projects, _project];
              }
            });

            console.log("🚀 ~ file: hooks.ts ~ line 112 ~ _projects", _projects);
            setProjects(_projects);
          });
        }
      } catch (error) {
        console.error((error as Error).message);
        state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "getProjects hata."];
      } finally {
        setIsLoadingProjects(false);
      }
    },
    [customQuery]
  );

  const updateProjectLikedCompanyIds = async function (project: Project, company: Company) {
    setIsUpdatingProject(true);

    try {
      const _projectSnapshot: DocumentSnapshot<Project> = await getDoc(doc(getFirestore(), `Projects/${project.id}`).withConverter(projectConverter));

      if (!_projectSnapshot.exists()) throw new Error(t("unknownProject", "Unknown project."));

      const _project: Project = _projectSnapshot.data();
      const _projectFavoriedCompany: ProjectFavoritedCompany = {
        createdAt: Timestamp.now().toDate(),
        createdByMemberId: member?.uid || "",
        companyId: company.id,
        companyName: company.legalName,
      };

      const _favoritedCompanies: any = {favoritedCompanies: _project.favoritedCompanies.find((c) => c.companyId === company.id) ? _project.favoritedCompanies.filter((c) => c.companyId !== company.id) : [..._project.favoritedCompanies, _projectFavoriedCompany]};
      const _projectWriteResult = await updateDoc(_projectSnapshot.ref, _favoritedCompanies);
      console.log("🚀 ~ file: hooks.ts ~ line 610 ~ updateProjectLikedCompanyIds ~ _projectWriteResult", _projectWriteResult);
    } catch (error) {
      console.error((error as Error)?.message || t("updateProjectLikedCompanyIds", "Teklif beğenmede hata oluştu."));
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "updateProjectLikedCompanyIds hata."];
    } finally {
      setIsUpdatingProject(false);
    }
  };

  const writeProject = useCallback(async function (project) {
    try {
      setIsLoadingProjects(true);

      console.log("🚀 ~ file: hooks.ts ~ line 811 ~ writeProject ~ project", project);

      const _projectResult = await setDoc(doc(getFirestore(), "Projects", project.id), project);
      console.log("🚀 ~ file: hooks.ts ~ line 680 ~ _projectResult", _projectResult);

      return _projectResult;
    } catch (error) {
      console.error(`writeProject error: ${(error as Error)?.message || JSON.stringify(error) || t("unknownError", "Bilinmeyen hata oluştu.")}`);
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "writeProject hata."];
    } finally {
      setIsLoadingProjects(false);
    }
  }, []);

  useEffect(
    function () {
      const _unsubscribe = getProjects();

      return function () {
        console.log("🚀 ~ file: hooks.ts ~ line 317 ~ _unsubscribe();", _unsubscribe);

        if (!!_unsubscribe) {
          _unsubscribe();
          console.log("🚀 ~ file: hooks.ts ~ line 118 ~ _unsubscribe UNSUBSCRIBED!", _unsubscribe);
        }
      };
    },
    [getProjects]
  );

  return {createProject, getProject, isLoadingProjects, isLoadingProjectScope, isUpdatingProject, project, projects, updateProjectLikedCompanyIds, writeProject, writeProjectScope};
};

export const useMetretulProjectTemplates = function () {
  const [projectDeliverablesItem, setProjectDeliverablesItem] = useState<ProjectDeliverables | null>(null);
  const [projectDeliverablesList, setProjectDeliverablesList] = useState<ProjectDeliverables[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const projectTemplatesFolder = useRef<string>("ProjectTemplates");

  const deleteProjectDeliverables = useCallback(async function ({id}: {id: string}) {
    try {
      setLoading(true);

      if (!id) throw new Error(t("idNeededForDeleteProjectDeliverablesItem", "deleteProjectDeliverables için id parametresi gereklidir."));

      const _deleteResult = await deleteDoc(doc(getFirestore(), projectTemplatesFolder.current, id));
      console.log("🚀 ~ file: hooks.ts ~ line 642 ~ deleteProjectDeliverables ~ _deleteResult", _deleteResult);
    } catch (error) {
      console.error(`deleteProjectDeliverables error: ${(error as Error)?.message || JSON.stringify(error)}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const getProjectDeliverablesItem = useCallback(async function ({id}: {id: string}) {
    try {
      setLoading(false);

      if (!id) throw new Error(t("idNeededForGetProjectDeliverablesItem", "getProjectDeliverablesItem için id parametresi gereklidir."));

      const _projectDeliverableDocumentSnapshot: DocumentSnapshot<ProjectDeliverables> = await getDoc(doc(getFirestore(), `${projectTemplatesFolder.current}/${id}`).withConverter(projectTemplateConverter));
      console.log("🚀 ~ file: hooks.ts ~ line 644 ~ getProjectDeliverablesItem ~ _projectDeliverableDocumentSnapshot", _projectDeliverableDocumentSnapshot);

      if (!_projectDeliverableDocumentSnapshot.exists()) return setProjectDeliverablesItem(null);

      setProjectDeliverablesItem(_projectDeliverableDocumentSnapshot.data());
    } catch (error) {
      console.error(`getProjectDeliverablesItem error: ${(error as Error)?.message || JSON.stringify(error)}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const getProjectDeliverablesList = useCallback(async function ({page = 1, resultsPerPage = 10}: {page?: number; resultsPerPage?: number} = {}) {
    try {
      setLoading(false);

      const _projectDeliverablesQuerySnapshot = await getDocs(query(collection(getFirestore(), projectTemplatesFolder.current), limit(resultsPerPage), orderBy("createdAt"), startAfter(page * resultsPerPage)).withConverter(projectTemplateConverter));
      console.log("🚀 ~ file: hooks.ts ~ line 654 ~ getProjectTemplate ~ _projectDeliverablesQuerySnapshot", _projectDeliverablesQuerySnapshot);
      if (_projectDeliverablesQuerySnapshot.empty) {
        setProjectDeliverablesList([]);
        return;
      }

      let _projectDeliverables: ProjectDeliverables[] = [];
      _projectDeliverablesQuerySnapshot.forEach(function (_pt) {
        _projectDeliverables = [..._projectDeliverables, {..._pt.data(), id: _pt.id}];
      });
      setProjectDeliverablesList(_projectDeliverables);

      return;
    } catch (error) {
      console.error(`getProjectTemplate error: ${(error as Error)?.message || JSON.stringify(error)}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const subscribeToProjectDeliverablesList = useCallback(function ({page = 1, resultsPerPage = 10}: {page?: number; resultsPerPage?: number} = {}) {
    return onSnapshot(query(collection(getFirestore(), projectTemplatesFolder.current), limit(resultsPerPage), orderBy("createdAt"), startAfter(page * resultsPerPage)).withConverter(projectTemplateConverter), function (querySnapshot: QuerySnapshot<ProjectDeliverables>) {
      let _projectDeliverablesList: ProjectDeliverables[] = [];

      querySnapshot.forEach(function (projectDeliverablesDocumentSnapshot: DocumentSnapshot<ProjectDeliverables>) {
        if (projectDeliverablesDocumentSnapshot.exists()) {
          let _projectDeliverables: ProjectDeliverables = {...projectDeliverablesDocumentSnapshot.data(), id: projectDeliverablesDocumentSnapshot.id};
          console.log("🚀 ~ file: hooks.ts ~ line 112 ~ _projectDeliverables", _projectDeliverables);

          _projectDeliverablesList = [..._projectDeliverablesList, _projectDeliverables];
        }
      });

      console.log("🚀 ~ file: hooks.ts ~ line 112 ~ _projectDeliverablesList", _projectDeliverablesList);
      console.count("_projectDeliverablesList");
      setProjectDeliverablesList(_projectDeliverablesList);
    });
  }, []);

  const writeProjectDeliverables = useCallback(async function (projectDeliverables) {
    try {
      setLoading(true);

      console.log("🚀 ~ file: hooks.ts ~ line 951 ~ writeProjectDeliverables ~ projectDeliverables", projectDeliverables);

      const _projectTemplateResult = await setDoc(doc(getFirestore(), projectTemplatesFolder.current, slugify(projectDeliverables.title)), projectDeliverables);
      console.log("🚀 ~ file: hooks.ts ~ line 680 ~ _projectTemplateResult", _projectTemplateResult);

      return _projectTemplateResult;
    } catch (error) {
      console.error(`writeProjectTemplate error: ${(error as Error)?.message || JSON.stringify(error) || t("unknownError", "Bilinmeyen hata oluştu.")}`);
    } finally {
      setLoading(false);
    }
  }, []);

  return {deleteProjectDeliverables, getProjectDeliverablesItem, getProjectDeliverablesList, loading, projectDeliverablesItem, projectDeliverablesList, subscribeToProjectDeliverablesList, writeProjectDeliverables};
};

export const useMetretulOrders = function () {
  const [loadingOrder, setLoadingOrder] = useState<boolean>(false);
  const [order, setOrder] = useState<Order | null>(null);

  async function createOrder(cart: Cart) {
    try {
      setLoadingOrder(true);

      const _orderResult = await createOrderRemote(cart);
      console.log("🚀 ~ file: hooks.ts ~ line 935 ~ createOrder ~ _orderResult", _orderResult);

      if (!_orderResult?.data) throw new Error(t("unexpectedOrderResult", "Order servisinden beklenmeyen cevap alındı."));

      setOrder(_orderResult.data);
    } catch (error) {
      console.error(error);
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "createOrder hata."];
    } finally {
      setLoadingOrder(false);
    }
  }

  return {createOrder, loadingOrder, order};
};

export const useMetretulStorageFolder = function (url = "") {
  /**
   * TO DO:
   * useMetretulStorageFolder içindeki useEffect iptal edilecek ve ekranların içinde getFiles çağrılacak.
   */
  const [files, setFiles] = useState<FullMetadata[]>([]);
  const [downloadedFile, setDownloadedFile] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);

  const clearFiles = function () {
    setFiles([]);
  };

  const downloadFile = useCallback(async function (fullPath: string) {
    try {
      setLoading(true);
      console.log("🚀 ~ file: hooks.ts ~ line 644 ~ downloadFile ~ fullPath", fullPath);

      const _url: string = await getDownloadURL(ref(getStorage(), fullPath));
      console.log("🚀 ~ file: hooks.ts ~ line 645 ~ downloadFile ~ _url", _url);

      setDownloadedFile(_url);
      return _url;
    } catch (error) {
      console.log("🚀 ~ file: hooks.ts ~ line 308 ~ downloadFile ~ error", error);
      setError(error);
      // return "";
    } finally {
      setLoading(false);
    }
  }, []);

  const getFiles = useCallback(
    async function () {
      try {
        const _storage = getStorage();
        const _listReference: StorageReference = ref(_storage, url);
        const _listResult: ListResult = await listAll(_listReference);
        const _files: FullMetadata[] = await Promise.all(
          _listResult.items.map(async (item) => {
            const _fullMetadata: FullMetadata = await getMetadata(ref(_storage, item.fullPath));

            return _fullMetadata;
          })
        );

        setFiles(_files);
        console.log("🚀 ~ file: hooks.ts ~ line 129 ~ _files", _files);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [url]
  );

  const deleteFile = useCallback(
    async function (url = "") {
      try {
        console.log("🚀 ~ file: hooks.ts ~ line 99 ~ deleteFile ~ deleteFile", deleteFile);

        const storage = getStorage();
        const _fileStorageReference: StorageReference = ref(storage, url);

        if (!url) {
          throw new Error(t("noFileURLToDelete", "Silinecek dosyanın adresi verilmemiş"));
        }

        const _deleteResult = await deleteObject(_fileStorageReference);
        console.log("🚀 ~ file: hooks.ts ~ line 100 ~ deleteFile ~ _deleteResult", _deleteResult);

        await getFiles();
      } catch (error) {
        setError(error);
      }
    },
    [getFiles]
  );

  const uploadFiles = useCallback(
    async function ({file, path}: useMetretulProductsUploadFilesParamsType) {
      try {
        setLoading(true);

        const _storageReference: StorageReference = ref(getStorage(), path);
        const _uploadResult: UploadResult = await uploadBytes(_storageReference, file);
        console.log("🚀 ~ file: hooks.ts ~ line 331 ~ uploadFiles ~ _uploadResult", _uploadResult);

        await getFiles();

        return _uploadResult;
      } catch (error) {
        console.log("🚀 ~ file: hooks.ts ~ line 330 ~ uploadFiles ~ error", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [getFiles]
  );

  return {clearFiles, deleteFile, downloadedFile, downloadFile, error, files, getFiles, loading, uploadFiles};
};

export const useMetretulSubscription = function () {
  const [availableCompanyCategories, setAvailableCompanyCategories] = useState<any[]>([]);
  const [loadingPlans, setLoadingPlans] = useState<boolean>(false);
  const [plans, setPlans] = useState<Product[]>([]);
  const {subscription} = useSnapshot(state);

  const getAvailableCompanyCategories = useCallback(
    function () {
      if (!subscription) {
        setAvailableCompanyCategories([]);
        return;
      }

      const _categoryIds = flattenDeep(subscription?.order?.cart?.items.map((item: any) => item?.categoryIds ?? []));
      console.log("🚀 ~ file: hooks.ts ~ line 18 ~ const_categoryIds=flatten ~ _categoryIds", _categoryIds);

      setAvailableCompanyCategories(_categoryIds);
    },
    [subscription]
  );

  const getPlans = useCallback(async function () {
    try {
      setLoadingPlans(true);

      const _plansQuerySnapshot = await getDocs(query(collection(getFirestore(), "Plans").withConverter(productConverter), orderBy("currentPrice.amount", "asc")));

      if (!_plansQuerySnapshot.empty) {
        let _plans: Product[] = [];

        _plansQuerySnapshot.forEach((_plan) => {
          if (_plan.exists()) _plans = [..._plans, _plan.data()];
        });

        setPlans(_plans);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlans(false);
    }
  }, []);

  const listenSubscription = useCallback(function () {
    console.count("currentUser");

    const {currentUser} = getAuth();

    if (!currentUser?.uid) return;

    const _now = Timestamp.now();

    console.log("🚀 ~ file: hooks.ts ~ line 1110 ~ currentUser.uid", currentUser.uid);
    console.log("🚀 ~ file: hooks.ts ~ line 1112 ~ _now", _now.toDate());

    const _querySubscriptions = querySubscriptions(currentUser);

    console.log("🚀 ~ file: hooks.ts ~ line 1116 ~ listenSubscription ~ !_querySubscriptions", !_querySubscriptions);

    if (!_querySubscriptions) return;

    return onSnapshot(_querySubscriptions, async function (subscriptionQuerySnapshot) {
      console.log("🚀 ~ file: hooks.ts ~ line 1108 ~ subscriptionQuerySnapshot.empty", subscriptionQuerySnapshot.empty);

      if (subscriptionQuerySnapshot.empty) {
        /**
         * ÖNEMLİ NOT!
         *
         * Eğer üyenin aktif bir subscription objesi yoksa state.role değerini getIdTokenResult ile alıyoruz.
         * Diğer durumda subscription içindeki Role objesi ile eşleniyor.
         */

        const _role: Role | null = ((await currentUser.getIdTokenResult(true))?.claims?.role as Role) || null;
        console.log("🚀 ~ file: hooks.ts ~ line 1105 ~ setTimeout ~ _role", _role);

        state.role = _role;
        state.subscription = null;

        return;
      }

      let _subscriptions: Subscription[] = [];

      subscriptionQuerySnapshot.docs.forEach((subscriptionQueryDocumentSnapshot) => {
        _subscriptions = [..._subscriptions, subscriptionQueryDocumentSnapshot.data()];
      });

      console.log("🚀 ~ file: hooks.ts ~ line 1101 ~ _subscriptions", _subscriptions);
      state.subscription = _subscriptions[0];
      state.role = _subscriptions[0].role;
    });
  }, []);

  useEffect(
    function () {
      getAvailableCompanyCategories();
      console.log("🚀 ~ file: hooks.ts ~ line 171 ~ useMetretulSubscription ~ getAvailableCompanyCategories");
    },
    [getAvailableCompanyCategories]
  );

  return {availableCompanyCategories, getPlans, listenSubscription, loadingPlans, plans};
};

export const useMetretulWorkflow = function () {
  const {currentUser: member} = useSnapshot(state);
  const [loadingWorkflow, setLoadingWorkflow] = useState<boolean>(false);

  async function completeMeasurement(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.pendingForFinalApproval,
      name: ProjectStatusName.pendingForFinalApproval,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      code: InvoiceStatusCode.pendingForFinalApproval,
      name: InvoiceStatusName.pendingForFinalApproval,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
  }

  async function confirmDelivery(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.deliveryCompleted,
      name: ProjectStatusName.deliveryCompleted,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      code: InvoiceStatusCode.deliveryCompleted,
      name: InvoiceStatusName.deliveryCompleted,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
  }

  async function deliveryCompleted(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.deliveryCompleted,
      name: ProjectStatusName.deliveryCompleted,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      code: InvoiceStatusCode.deliveryCompleted,
      name: InvoiceStatusName.deliveryCompleted,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
  }

  async function deliveryConfirmationRequest(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.pendingForDeliveryConfirmation,
      name: ProjectStatusName.pendingForDeliveryConfirmation,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      code: InvoiceStatusCode.deliverConfirmationRequested,
      name: InvoiceStatusName.deliverConfirmationRequested,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
  }

  async function finalApproval(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.pendingForDelivery,
      name: ProjectStatusName.pendingForDelivery,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      code: InvoiceStatusCode.approved,
      name: InvoiceStatusName.approved,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
  }

  async function pendingForBankTransfer(project: Project) {
    setLoadingWorkflow(true);

    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.pendingForBankTransfer,
      name: ProjectStatusName.pendingForBankTransfer,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ pendingForBankTransferCompleted ~ _projectWriteResult", _projectWriteResult);

    setLoadingWorkflow(false);
  }

  async function pendingForBankTransferCompleted(project: Project) {
    setLoadingWorkflow(true);

    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.pendingForBankTransferCompleted,
      name: ProjectStatusName.pendingForBankTransferCompleted,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ pendingForBankTransferCompleted ~ _projectWriteResult", _projectWriteResult);

    setLoadingWorkflow(false);
  }

  async function pendingForBankTransferNotified(project: Project) {
    setLoadingWorkflow(true);

    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.pendingForBankTransferNotified,
      name: ProjectStatusName.pendingForBankTransferNotified,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ pendingForBankTransferNotified ~ _projectWriteResult", _projectWriteResult);

    setLoadingWorkflow(false);
  }

  async function pendingForDeliveryFix(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.pendingForDeliveryFix,
      name: ProjectStatusName.pendingForDeliveryFix,
    };
    console.log("🚀 ~ file: hooks.ts:1382 ~ pendingForDeliveryFix ~ project", project);
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    console.log("🚀 ~ file: hooks.ts:1388 ~ pendingForDeliveryFix ~ invoice", invoice);
    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      code: InvoiceStatusCode.pendingForDeliveryFix,
      name: InvoiceStatusName.pendingForDeliveryFix,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
  }

  async function preApproveInvoice(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.preApproved,
      name: ProjectStatusName.preApproved,
    };

    const _favoritedCompany: ProjectFavoritedCompany = {
      createdAt: Timestamp.now().toDate(),
      createdByMemberId: member?.uid || "",
      companyId: invoice.companyId,
      companyName: invoice.company.legalName,
    };
    const _favoritedCompanies: ProjectFavoritedCompany[] = project?.favoritedCompanies.findIndex((fc) => fc.companyId === invoice.companyId) >= 0 ? project.favoritedCompanies : [...project.favoritedCompanies, _favoritedCompany];
    const _contractorCompanyId: string = invoice.companyId;

    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {contractorCompanyId: _contractorCompanyId, favoritedCompanies: _favoritedCompanies, status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      code: InvoiceStatusCode.preApproved,
      name: InvoiceStatusName.preApproved,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
  }

  async function rejectInvoiceAndRestart(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.pendingForQuoteApproval,
      name: ProjectStatusName.pendingForQuoteApproval,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {contractorCompanyId: "", status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      code: InvoiceStatusCode.rejected,
      name: InvoiceStatusName.rejected,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
    try {
    } catch (error) {
      console.error(`rejectInvoiceAndRestart error: ${(error as Error)?.message || JSON.stringify(error)}`);
      state.errors = [...state.errors, (error as Error)?.message || JSON.stringify(error) || "rejectInvoiceAndRestart hata."];
    }
  }

  async function requestChallenge(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      updatedAt: Timestamp.now().toDate(),
      updatedByMemberId: member?.uid || "",
      code: ProjectStatusCode.challengeRequested,
      name: ProjectStatusName.challengeRequested,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      updatedAt: Timestamp.now().toDate(),
      updatedByMemberId: member?.uid || "",
      code: InvoiceStatusCode.challengeRequested,
      name: InvoiceStatusName.challengeRequested,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
  }

  async function sendMeasurementInvitation(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.pendingForMeasurement,
      name: ProjectStatusName.pendingForMeasurement,
    };
    const _contractorCompanyId: string = invoice.companyId;
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {contractorCompanyId: _contractorCompanyId, status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      code: InvoiceStatusCode.pendingForMeasurement,
      name: InvoiceStatusName.pendingForMeasurement,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
  }

  async function suspendProject(project: Project, invoice: Invoice) {
    const _projectStatus: ProjectStatus = {
      ...project.status,
      code: ProjectStatusCode.suspended,
      name: ProjectStatusName.suspended,
    };
    const _projectWriteResult = await updateDoc(doc(getFirestore(), `Projects/${project.id}`), {status: _projectStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 776 ~ preApproveInvoice ~ _projectWriteResult", _projectWriteResult);

    const _invoiceStatus: InvoiceStatus = {
      ...invoice.status,
      code: InvoiceStatusCode.suspended,
      name: InvoiceStatusName.suspended,
    };
    const _invoiceWriteResult = await updateDoc(doc(getFirestore(), `Invoices/${invoice.id}`), {status: _invoiceStatus});
    console.log("🚀 ~ file: hooks.ts ~ line 783 ~ preApproveInvoice ~ _invoiceWriteResult", _invoiceWriteResult);
  }

  return {completeMeasurement, confirmDelivery, deliveryCompleted, deliveryConfirmationRequest, finalApproval, loadingWorkflow, pendingForBankTransfer, pendingForBankTransferCompleted, pendingForBankTransferNotified, pendingForDeliveryFix, preApproveInvoice, rejectInvoiceAndRestart, requestChallenge, sendMeasurementInvitation, suspendProject};
};
