import {Button, Grid, MenuItem, Paper, TextField} from "@mui/material";
import {Timestamp} from "firebase/firestore";
import {FormikHelpers, useFormik} from "formik";
import {t} from "i18next";
import {useAtom} from "jotai";
import {Currency, ProductAttribute, ProductStatus, ProductVarianceType, ProductVariation, slugify} from "jack-shared";
import {useCallback, useEffect, useState} from "react";
import {object, string} from "yup";
import {errorsAtom} from "../utilities/atoms";
import {buildErrorState} from "../utilities/helpers";
import {useMetretulProducts} from "../utilities/hooks";
import useStyles from "../utilities/styles";
import {ProductVariantsFormInitialValuesType, ProductVariantsFormPropsType} from "../utilities/types";
const uniqid = require("uniqid");

const ProductVariantionForm = function ({product, productVariations}: ProductVariantsFormPropsType) {
  const classes = useStyles();
  const [, setErrors] = useAtom(errorsAtom);
  const {isLoadingProductVariations, writeProductVariation} = useMetretulProducts();
  const [productVariationId, setProductVariationId] = useState<string>("");
  const [productVariationName, setProductVariationName] = useState<string>("");

  const onSubmit = useCallback(
    async function (values: ProductVariantsFormInitialValuesType, actions: FormikHelpers<ProductVariantsFormInitialValuesType>) {
      try {
        const _includedAttributeValues = values;
        /**
         * TO DO:
         * Varyasyon önceden eklenmişse sorguyu engelleyecek ve kullanıcıya uyarı verilecek bir yapı kurulmalı.
         */
        console.log(
          "🚀 ~ file: product-variantion-form.tsx ~ line 32 ~ productVariations.findIndex((productVariation) => productVariation.name === productVariationName)",
          productVariations.findIndex((productVariation) => productVariation.name === productVariationName)
        );
        if (productVariations.findIndex((productVariation) => productVariation.name === productVariationName) > -1) throw new Error(t("productVariationAlreadyCreated", "Varyasyon daha önceden eklenmiş."));

        const _now: Timestamp = Timestamp.now();

        const _variation: ProductVariation = {
          allowMultiplePurchase: false,
          allowedPointsUsagePercentage: 0,
          attachment: null,
          attachments: null,
          attributes: [],
          autoRenew: false,
          barcode: "",
          categoryIds: [],
          companyId: "",
          createdAt: _now.toDate(),
          criticalStockCount: 0,
          currentPrice: {
            amount: 0,
            createdAt: _now.toDate(),
            currency: Currency.TRY,
          },
          currentPriceRange: null,
          duration: null,
          description: null,
          expiresAt: null,
          gtip: null,
          hasStockMovements: false,
          id: productVariationId,
          includedAttributeValues: _includedAttributeValues,
          initialStock: 0,
          inventoryTracking: false,
          isSaleable: false,
          isUsageConfirmationNeeded: false,
          listPrice: {
            amount: 0,
            createdAt: _now.toDate(),
            currency: Currency.TRY,
          },
          listPriceRange: null,
          name: productVariationName,
          order: 0,
          paymentId: "",
          startsAt: _now.toDate(), // TO DO: Düzeltilmeli ve null destekleyen hale gelmeli.
          status: ProductStatus.available,
          stock: null,
          stockType: null,
          updatedAt: _now.toDate(),
          taxes: [],
          variations: [],
          varianceType: ProductVarianceType.variation,
          variationOfProductId: product.id,
        };
        console.log("🚀 ~ file: product-service-variants-form.tsx ~ line 80 ~ _variation", _variation);

        writeProductVariation(_variation);
      } catch (error) {
        console.error("🚀 ~ file: product-service-variants-write.tsx ~ line 21 ~ onSubmit ~ error", error);
        setErrors((e: any[]) => [...e, error]);
      }
    },
    [product.id, productVariationId, productVariationName, productVariations, setErrors, writeProductVariation]
  );

  const validationSchema = object(Object.values(product.attributes).reduce((a, b) => ({...a, [b.name]: string().required(t("attributeIsRequired", `${b.name} gereklidir.`))}), {}));

  const {errors, handleChange, handleSubmit, touched, values} = useFormik<ProductVariantsFormInitialValuesType>({
    enableReinitialize: true,
    initialValues: Object.values(product.attributes).reduce((a, b) => ({...a, [b.name]: ""}), {}),
    onSubmit,
    validationSchema,
  });

  useEffect(
    function () {
      const _name = Object.values(values).reduce((a, b, i) => (i === 0 ? b : `${a} - ${b}`), "") as string;

      setProductVariationId(`${slugify(`${product.name}-${_name}`)}-${uniqid()}`);
      setProductVariationName(_name);
    },
    [product.name, values]
  );

  return (
    <Paper className={classes.paperContent} component="form" onSubmit={handleSubmit}>
      <Grid container direction="column">
        {product.attributes.map((attribute: ProductAttribute, index: number) => (
          <Grid item key={`attribute_${index}`} xs={12}>
            <TextField error={buildErrorState(touched, errors, attribute.name).state} fullWidth helperText={buildErrorState(touched, errors, attribute.name).helperText} label={t(`attribute_${index}`, attribute.name)} name={attribute.name} onChange={handleChange} select value={(values as any)[attribute.name]}>
              {attribute.values.map((v: string, i: number) => (
                <MenuItem key={`attribute_value_${i}`} value={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button disabled={isLoadingProductVariations} fullWidth type="submit" variant="contained">
            {t("createVariant", "Varyant Ekle")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductVariantionForm;
