import {Button, Card, CardActions, CardContent, Grid, Typography} from "@mui/material";
import {t} from "i18next";
import {Product} from "jack-shared";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useCart} from "react-use-cart";
import Money from "../components/money";
import {useMetretulSubscription} from "../utilities/hooks";

const Plans = function () {
  const cart = useCart();
  const {plans, getPlans} = useMetretulSubscription();

  let navigate = useNavigate();

  const onClick = function (plan: Product) {
    cart.setItems([{...plan, price: plan.currentPrice.amount}]);
    navigate("/cart");
  };

  useEffect(
    function () {
      getPlans();
    },
    [getPlans]
  );

  return (
    <>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          {plans.map((plan) => (
            <Grid item key={plan.id} md={4} xs={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="text.secondary" gutterBottom>
                    {t(plan.id)}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {t(plan.name)}
                  </Typography>
                  <Typography color="text.secondary">
                    <Money currency={plan.currentPrice.currency} price={plan.currentPrice.amount} />
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button fullWidth onClick={() => onClick(plan)} variant="contained">
                    {t("select_package_and_complete", "Paketi Seç ve Devam Et")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default Plans;
