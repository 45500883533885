import { Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import InputMask from "react-input-mask";
import { useSnapshot } from "valtio";
import { state } from "..";

const MemberForm = function ({ edit = false, onSuccess }: { edit?: boolean; onSuccess: Function }) {
  const { currentUser: member } = useSnapshot(state);

  const onSubmit = function (values: any, actions: any) {
    onSuccess({ actions, values });
  };

  const { errors, handleChange, handleSubmit, touched, values } = useFormik({
    initialValues: {
      email: "",
      name: member?.displayName,
      company_phone: "",
    },
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid item xs={12} md={6}>
          <TextField disabled={!edit} error={!!touched.name && !!errors.name} fullWidth helperText={!!touched.name && errors?.name} id="name" label={t("name", "İsim Soyisim")} name="name" onChange={handleChange} value={values.name} />
        </Grid>
        <Grid item>
          <TextField disabled={!edit} error={!!touched.email && !!errors.email} fullWidth helperText={!!touched.email && errors?.email} id="email" label={t("email", "E-posta")} name="email" onChange={handleChange} value={values.email} />
        </Grid>
        <Grid item>
          <InputMask disabled={!edit} id="company_phone" mask="0 (999) 999 99 99" name="company_phone" onChange={handleChange} value={values.company_phone}>
            <TextField error={!!touched.company_phone && !!errors.company_phone} fullWidth helperText={!!touched.company_phone && errors?.company_phone} label={t("company_phone", "Firma Telefonu")} />
          </InputMask>
        </Grid>
        {edit && (
          <Grid item>
            <Button color="primary" variant="contained" fullWidth type="submit">
              {t("submit_update_profile", "Profil Bilgilerini Güncelle")}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default MemberForm;
