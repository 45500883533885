import {Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import {t} from "i18next";
import {useAtom} from "jotai";
import {Cart as CartModel, Company, IyzicoPaymentCard} from "jack-shared";
import InputMask from "react-input-mask";
import {useSnapshot} from "valtio";
import * as yup from "yup";
import {state} from "..";
import TestDataSelector from "../components/test-data-selector";
import {isUsingTestDataAtom, testDataAtom, testDataIdsAtom} from "../utilities/atoms";
import {useMetretulPayment} from "../utilities/hooks";

const CreditCardForm = function ({cart, company, onSuccess, parentErrors, showAgreements = true, submitText}: {cart?: any; company?: Company; onSuccess: Function; parentErrors?: any; showAgreements?: boolean; submitText?: any}) {
  const [testData] = useAtom(testDataAtom);
  const [testDataIds] = useAtom(testDataIdsAtom);
  const [isUsingTestData] = useAtom(isUsingTestDataAtom);
  const {loading: loadingPayment, makePayment} = useMetretulPayment();
  const {currentUser} = useSnapshot(state);

  const onSubmit = async function (values: any) {
    /**
     * TO DO:
     * billing ve shipping alanları dinamik hale gelecek.
     */

    const _creditCard: IyzicoPaymentCard = {
      cardNumber: values.cardNumber,
      cardHolderName: values.cardHolderName,
      cvc: values.cvv,
      expireMonth: values.date.split("/")[0],
      expireYear: values.date.split("/")[1],
    };

    const _cart: CartModel = {
      isEmpty: false,
      cartTotal: cart.cartTotal,
      items: cart.items,
      metadata: {
        ...cart.metadata,
        ...(company ? {company} : {}),
        billing: {
          address: "test_1_address",
          city: "test_1_city",
          contactName: "test_1_contactName",
          country: "test_1_country",
        },
        memberId: currentUser?.uid || "",
        shipping: {
          address: "test_1_address",
          city: "test_1_city",
          contactName: "test_1_contactName",
          country: "test_1_country",
        },
      } as any,
      totalItems: cart.totalItems,
      totalUniqueItems: cart.totalUniqueItems,
    };
    console.log("🚀 ~ file: credit-card-form.tsx:63 ~ onSubmit ~ _cart:", _cart);

    const _paymentResult = await makePayment(_cart, _creditCard);
    console.log("🚀 ~ file: cart.tsx ~ line 45 ~ onSuccess ~ _paymentResult", _paymentResult);

    onSuccess(_paymentResult);
  };

  const validationSchema = yup.object({
    cardNumber: yup.string().required(t("validation_required_cardNumber", "Kart numarasını girin.")),
    cvv: yup.string().required(t("validation_required_cvv", "Kartınızın arkasındaki güvenlik kodunu girin.")),
    date: yup.string().required(t("validation_required_date", "Kartınızın son kullanma tarihini girin.")),
    cardHolderName: yup.string().required(t("validation_required_cardHolderName", "Kartın üstünde yazan ismi girin.")),
    ...(showAgreements ? {agreeCommisions: yup.boolean().oneOf([true], t("validation_required_agreeCommisions", "Komisyon oranlarını kabul etmeniz gerekiyor."))} : {}),
  });

  const {errors, handleChange, handleSubmit, setFieldValue, touched, values} = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...(showAgreements ? {agreeRecursive: false, agreeCommisions: isUsingTestData} : {}),
      cardNumber: isUsingTestData ? testData.cards[testDataIds.cards].cardNumber : "",
      cvv: isUsingTestData ? testData.cards[testDataIds.cards].cvv : "",
      date: isUsingTestData ? `${testData.cards[testDataIds.cards].expireMonth}/${testData.cards[testDataIds.cards].expireYear}` : "",
      cardHolderName: isUsingTestData ? testData.cards[testDataIds.cards].cardHolderName : "",
    },
    onSubmit,
    validationSchema,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("creditCardInfo", "Kart Bilgileri")}</Typography>
      </Grid>
      {process.env.NODE_ENV === "development" && (
        <Grid item xs={12}>
          <TestDataSelector id="cards" />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField id="cardHolderName" disabled={cart && !cart.cartTotal} error={!!touched.cardHolderName && !!errors.cardHolderName} fullWidth helperText={!!touched.cardHolderName && errors?.cardHolderName} label={t("cardHolderName", "Kart Üzerindeki İsim")} name="cardHolderName" onChange={handleChange} value={values.cardHolderName} />
      </Grid>
      <Grid item xs={12}>
        <InputMask disabled={cart && !cart.cartTotal} id="cardNumber" mask="9999 9999 9999 9999" name="cardNumber" onChange={(event) => setFieldValue("cardNumber", event.target.value?.replaceAll(" ", ""))} value={values.cardNumber}>
          <TextField error={!!touched.cardNumber && !!errors.cardNumber} fullWidth helperText={!!touched.cardNumber && errors?.cardNumber} label={t("cardNumber", "Kredi Kartı Numarası")} />
        </InputMask>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <InputMask disabled={cart && !cart.cartTotal} id="cvv" mask="99/2099" name="date" onChange={handleChange} value={values.date}>
              <TextField error={!!touched.date && !!errors.date} fullWidth helperText={!!touched.date && errors?.date} label={t("date", "Son Kul. Tar.")} />
            </InputMask>
          </Grid>
          <Grid item xs={6}>
            <InputMask disabled={cart && !cart.cartTotal} id="cvv" mask="999" name="cvv" onChange={handleChange} value={values.cvv}>
              <TextField error={!!touched.cvv && !!errors.cvv} fullWidth helperText={!!touched.cvv && errors?.cvv} label={t("cvv", "CVV / CVC")} />
            </InputMask>
          </Grid>
        </Grid>
      </Grid>
      {showAgreements && (
        <Grid item xs={12}>
          <Grid container direction="column" spacing={0}>
            <Grid item>
              <FormControl required error={!!touched.agreeCommisions && !!errors.agreeCommisions}>
                <FormControlLabel control={<Checkbox checked={!!values.agreeCommisions} onChange={handleChange} name="agreeCommisions" />} label={<Typography variant="body2">{t("agreeCommisions", "Jack komisyon oranlarını kabul ediyorum.")}</Typography>} />
                <FormHelperText>{!!touched.agreeCommisions && errors?.agreeCommisions}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControlLabel control={<Checkbox checked={!!values.agreeRecursive} onChange={handleChange} name="agreeRecursive" />} label={<Typography variant="body2">{t("agreeRecursive", "Her ay otomatik üyeliğimi uzat.")}</Typography>} />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button color="primary" disabled={loadingPayment || (cart && !cart.cartTotal) || Object.values(parentErrors).some((v) => !!v)} fullWidth type="submit" variant="contained">
          {loadingPayment ? <CircularProgress /> : submitText || t("pay", "Ödeme Yap")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreditCardForm;
