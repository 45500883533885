import { Home } from "@mui/icons-material";
import { Breadcrumbs, Grid, IconButton, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import useStyles from "../utilities/styles";

const TransactionList = function () {
  const classes = useStyles();
  const [colums] = useState<Array<any>>([]);
  const [rows] = useState<Array<any>>([]);

  let navigate = useNavigate();

  return (
    <>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate("/dashboard")}>
            <Home />
          </IconButton>
          <Typography>{t("transactions", "Ödeme Hareketleri")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Paper>
          <div className={classes.paperDataGridContainer}>
            <DataGrid
              columns={colums}
              localeText={{
                MuiTablePagination: {
                  labelRowsPerPage: t("result_per_page", " "),
                },
              }}
              rows={rows}
              disableSelectionOnClick
              sx={{
                "& .MuiDataGrid-cell": {
                  maxHeight: "none !important",
                  paddingBottom: 1,
                  paddingTop: 1,
                  whiteSpace: "normal",
                },
                "& .MuiDataGrid-row": {
                  maxHeight: "none !important",
                },
              }}
            />
          </div>
        </Paper>
      </Grid>
    </>
  );
};

export default TransactionList;
