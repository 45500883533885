import {Close, Home, ShoppingCart} from "@mui/icons-material";
import {Breadcrumbs, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Grid, IconButton, Typography} from "@mui/material";
import {t} from "i18next";
import {Company} from "jack-shared";
import {SnackbarKey, useSnackbar} from "notistack";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useCart} from "react-use-cart";
import {useJackCompanySettings, useMetretulCompanies, useMetretulProducts} from "../utilities/hooks";

export default function Menu({currentCompany}: {currentCompany?: (c: Company) => void}) {
  const {companyId} = useParams();
  const {products, isLoadingProducts, subscribeToProducts} = useMetretulProducts();
  const {company, getCompany, isLoadingCompany} = useMetretulCompanies();
  const navigate = useNavigate();
  const cart = useCart();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const {companySettings, getCompanySettings} = useJackCompanySettings();

  function notistackActions(snackbarKey: SnackbarKey | undefined) {
    return (
      <>
        <Button
          color="inherit"
          endIcon={<ShoppingCart />}
          onClick={() => {
            navigate("/cart", {state: {company}});
            closeSnackbar();
          }}
        >
          {t("cart", "Sepet")}
        </Button>
        <IconButton color="inherit" onClick={() => closeSnackbar(snackbarKey)}>
          <Close />
        </IconButton>
      </>
    );
  }

  useEffect(() => {
    if (companyId) {
      const _unsubscribe = getCompany(companyId);
      console.log("🚀 ~ file: menu.tsx:17 ~ useEffect ~ _unsubscribe", _unsubscribe);

      return () => {
        if (_unsubscribe) {
          _unsubscribe();
          console.log("🚀 ~ file: product-list.tsx ~ line 34 ~ _unsubscribe", _unsubscribe);
          console.count("ProductList__unsubscribe");
        }
      };
    }
  }, [companyId, getCompany]);

  useEffect(
    function () {
      if (companyId) {
        const _unsubscribe = subscribeToProducts(companyId);
        console.log("🚀 ~ file: product-list.tsx ~ line 29 ~ ProductList ~ _unsubscribe", _unsubscribe);

        return () => {
          if (_unsubscribe) {
            _unsubscribe();
            console.log("🚀 ~ file: product-list.tsx ~ line 34 ~ _unsubscribe", _unsubscribe);
            console.count("ProductList__unsubscribe");
          }
        };
      }
    },
    [companyId, subscribeToProducts]
  );

  useEffect(() => {
    if (companyId) {
      getCompanySettings(companyId);
    }
  }, [companyId, getCompanySettings]);

  useEffect(() => {
    console.log("🚀 ~ file: menu.tsx:74 ~ useEffect ~ company:", company);
    console.log("🚀 ~ file: menu.tsx:74 ~ useEffect ~ currentCompany:", currentCompany);

    if (!!company && !!currentCompany) {
      currentCompany(company);
    }
  }, [company, currentCompany]);

  return (
    <>
      <Grid item xs={12}>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <IconButton color="primary" onClick={() => navigate("/")}>
                <Home />
              </IconButton>
              {isLoadingCompany ? <CircularProgress size={18} /> : company && <Typography>{company?.shortName}</Typography>}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {isLoadingProducts ? (
          <CircularProgress />
        ) : (
          <Grid container>
            {products.map((product) => (
              <Grid key={product.id} item xs={6} md={3}>
                <Card sx={{display: "flex", flexDirection: "column"}}>
                  {product.attachment && <CardMedia component="img" src={product.attachment} />}
                  <CardContent sx={{height: "100%"}}>
                    <Typography gutterBottom variant="subtitle1">
                      {product.name}
                    </Typography>
                    <Typography gutterBottom color="GrayText" variant="body2">
                      {product.description}
                    </Typography>
                    {companySettings.find((c) => c.id === `${companyId}_showPrices`)?.status && (
                      <Typography color="red" variant="h5">
                        {new Intl.NumberFormat("tr-TR", {style: "currency", currency: product.listPrice.currency}).format(product.listPrice.amount)}
                      </Typography>
                    )}
                  </CardContent>
                  {companySettings.find((c) => c.id === `${companyId}_enableQRMenu`)?.status && (
                    <CardActions sx={{}}>
                      <Button
                        fullWidth
                        onClick={() => {
                          const _isInCart = cart.inCart(product.id);
                          _isInCart ? cart.updateItemQuantity(product.id, cart.getItem(product.id)?.quantity + 1 || 1) : cart.addItem({...product, price: product.currentPrice.amount}, 1);

                          const _text = _isInCart ? `${product.name} ${t("updatedCartQuantity", "adedi güncellendi.")}` : `${product.name} ${t("addedToCart", "sepete eklendi.")}`;
                          enqueueSnackbar(_text, {action: notistackActions, variant: "success"});
                        }}
                        startIcon={<ShoppingCart />}
                        variant="outlined"
                      >
                        {t("addToCart", "Sepete Ekle")}
                      </Button>
                    </CardActions>
                  )}
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </>
  );
}
