import {Home} from "@mui/icons-material";
import {Breadcrumbs, Button, Grid, IconButton, Typography} from "@mui/material";
import {t} from "i18next";
import {Company} from "jack-shared";
import {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ProductForm from "../forms/product-form";

const ProductWrite = function ({companies}: {companies: Company[]}) {
  const navigate = useNavigate();
  const {id} = useParams();
  const {state} = useLocation();
  const [isEditing] = useState<boolean>(!!id && !!(state as any)?.product);

  return (
    <>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate("/dashboard")}>
            <Home />
          </IconButton>
          <Button onClick={() => navigate("/products")}>
            <Typography>{t("products", "Ürünler")}</Typography>
          </Button>
          <Button disabled>
            <Typography>{isEditing ? (state as any)?.product.name : t("newProductPage", "Yeni Ürün")}</Typography>
          </Button>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        {companies[0]?.id && <ProductForm companyId={companies[0].id} product={isEditing ? (state as any)?.product : null} />}
      </Grid>
    </>
  );
};

export default ProductWrite;
