import {CircularProgress, List, ListItem, ListItemButton, ListItemText, Paper, Typography} from "@mui/material";
import {t} from "i18next";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useMetretulCompanies} from "../utilities/hooks";

export default function QRMenuCompanies() {
  let navigate = useNavigate();
  const {allCompanies, getAllCompanies, isLoadingAllCompanies} = useMetretulCompanies();

  useEffect(() => {
    getAllCompanies();
  }, [getAllCompanies]);

  return (
    <Paper>
      {isLoadingAllCompanies ? (
        <CircularProgress />
      ) : (
        <List>
          <ListItem>
            <ListItemText>
              <Typography variant="subtitle2">{t("jackCompanies", "Jack Noktaları")}</Typography>
            </ListItemText>
          </ListItem>
          {allCompanies?.length &&
            allCompanies.map((company) => (
              <ListItemButton
                onClick={() => {
                  navigate(`/menu/${company.id}`);
                }}
                key={company.id}
              >
                <ListItemText>{company.shortName}</ListItemText>
              </ListItemButton>
            ))}
        </List>
      )}
    </Paper>
  );
}
