import {Check, Circle, Home} from "@mui/icons-material";
import {Breadcrumbs, Button, CircularProgress, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography} from "@mui/material";
import {t} from "i18next";
import {NotificationStatusName} from "jack-shared";
import moment from "moment";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useMetretulNotifications} from "../utilities/hooks";
import useStyles from "../utilities/styles";
import theme from "../utilities/theme";

const NotificationsList = function () {
  const {loadingNotifications, markAsRead, notifications, getNotifications} = useMetretulNotifications();

  const classes = useStyles();

  let navigate = useNavigate();

  useEffect(() => {
    console.count("getNotifications");
    const _unsubscribe = getNotifications();

    return () => {
      if (!!_unsubscribe) {
        console.log("🚀 ~ file: notification-list.tsx:34 ~ NotificationsList ~ _unsubscribe", _unsubscribe);
        _unsubscribe();
      }
    };
  }, [getNotifications]);

  return loadingNotifications ? (
    <CircularProgress />
  ) : (
    <>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <IconButton color="primary" onClick={() => navigate("/dashboard")}>
                <Home />
              </IconButton>
              <Typography>{t("notifications", "Bildirimler")}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Button disabled={!notifications.filter((notification) => notification.status.name === NotificationStatusName.unread).length} onClick={() => markAsRead(notifications.filter((notification) => notification.status.name === NotificationStatusName.unread).map((n) => n.id))} size="medium" startIcon={<Check />} variant="outlined">
              {t("mark_all_as_read", "Tümünü Okundu Yap")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paperContent}>
          <List>
            {notifications.map((notification) => (
              <ListItem key={notification.id}>
                <ListItemButton onClick={() => markAsRead([notification.id], notification.url ? () => navigate(new URL(notification.url).pathname) : () => {})}>
                  <ListItemAvatar>{notification.status.name === NotificationStatusName.unread && <Circle color="primary" />}</ListItemAvatar>
                  <ListItemText primaryTypographyProps={notification.status.name === NotificationStatusName.read ? {color: theme.palette.grey[600]} : {}} primary={notification.subject} secondary={moment(notification.createdAt).format("lll")} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
    </>
  );
};

export default NotificationsList;
