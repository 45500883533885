import { useFormik } from "formik";
import { Button, Grid, Paper, TextField } from "@mui/material";
import { t } from "i18next";
import * as yup from "yup";

import useStyles from "../utilities/styles";

const ForgotPasswordForm = function ({ onSuccess }: { onSuccess: Function }) {
  const classes = useStyles();

  const onSubmit = function (values: any, actions: any) {
    onSuccess({ actions, values });
  };

  const validationSchema = yup.object({
    email: yup.string().email(t("validation_format_email", "E-posta adresinizi kontrol edin.")).required(t("validation_required_email", "E-posta adresinizi girin.")),
  });

  const { errors, handleChange, handleSubmit, touched, values } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Paper className={classes.paperContent}>
        <Grid container direction="column">
          <Grid item>
            <TextField id="email" error={!!touched.email && !!errors.email} fullWidth helperText={!!touched.email && errors?.email} label={t("email", "E-posta")} name="email" onChange={handleChange} value={values.email} />
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" fullWidth type="submit">
              {t("send_password_message", "Şifre Yenileme Talebi Gönder")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default ForgotPasswordForm;
