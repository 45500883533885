import {ChipProps} from "@mui/material";
import {User} from "firebase/auth";
import {collection, doc, getFirestore, Timestamp} from "firebase/firestore";
import {FullMetadata, getDownloadURL, getStorage, ref} from "firebase/storage";
import {FormikErrors, FormikTouched} from "formik";
import {BaseStatusName, Company, Currency, Invoice, InvoiceStatus, InvoiceStatusCode, InvoiceStatusName, InvoiceType, InvoiceTypeName, Message, MessageStatus, MessageStatusName, MessageSubject, MessageType, Money, PaymentStatus, PaymentStatusName, ProductAttribute, ProductCategoryName, Project, ProjectDeliverable, ProjectDeliverables, ProjectDeliverableType, ProjectScope, ProjectStatusName} from "jack-shared";
import {defaults} from "jack-shared/assets/constants.json";
import moment from "moment";
import orderId from "order-id";
import FileResizer from "react-image-file-resizer";
import {ErrorStateType, ProjectFormInitialValuesType} from "./types";

export const buildChipPropsForInvoices = function (invoiceStatusName: InvoiceStatusName): ChipProps {
  switch (invoiceStatusName) {
    case InvoiceStatusName.accepted:
      return {
        color: "success",
        label: invoiceStatusName,
        variant: "filled",
      };

    case InvoiceStatusName.cancelled:
      return {
        color: "default",
        label: invoiceStatusName,
        variant: "outlined",
      };

    case InvoiceStatusName.pending:
      return {
        color: "warning",
        label: invoiceStatusName,
        variant: "outlined",
      };

    case InvoiceStatusName.preApproved:
      return {
        color: "success",
        label: invoiceStatusName,
        variant: "outlined",
      };

    default:
      return {
        color: "default",
        label: invoiceStatusName,
        variant: "outlined",
      };
  }
};

export const buildErrorState = function <T, E>(touched: FormikTouched<T>, errors: FormikErrors<E>, name: string): ErrorStateType {
  return {helperText: !!(touched as any)[name] && (errors as any)[name], state: !!(touched as any)[name] && !!(errors as any)[name]};
};

export const buildInvoice: (company: Company, member: User, project: Project, revisionOfQuoteId: string | null, values: any, invoice: Invoice | null) => Invoice = function (company, member, project, revisionOfQuoteId = null, values, invoice) {
  const _invitationExpirationPeriod = defaults.periods.invitationExpiration;
  const _now: Timestamp = Timestamp.now();
  const _expiresAt = Timestamp.fromDate(moment(_now.toDate()).add(_invitationExpirationPeriod, "days").toDate());
  const _currency = Currency.TRY;
  let _statusCode = revisionOfQuoteId ? InvoiceStatusCode.pendingRevision : InvoiceStatusCode.pending;
  let _statusName = revisionOfQuoteId ? InvoiceStatusName.pendingRevision : InvoiceStatusName.pending;
  // const _statusCode: InvoiceStatusCode = !!invoice && invoice?.status.code >= InvoiceStatusCode.preApproved ? InvoiceStatusCode.pendingPreApprovalRevisedQuote : !revisionOfQuoteId ? InvoiceStatusCode.pending : InvoiceStatusCode.pendingRevision;
  // const _statusName: InvoiceStatusName = !!invoice && invoice?.status.code >= InvoiceStatusCode.preApproved ? InvoiceStatusName.pendingPreApprovalRevisedQuote : !revisionOfQuoteId ? InvoiceStatusName.pending : InvoiceStatusName.pendingRevision;

  switch (invoice?.status.code) {
    case InvoiceStatusCode.preApproved:
      _statusCode = InvoiceStatusCode.pendingPreApprovalRevisedQuote;
      _statusName = InvoiceStatusName.pendingPreApprovalRevisedQuote;
      break;

    case InvoiceStatusCode.pendingForMeasurement:
    case InvoiceStatusCode.pendingForFinalApproval:
      _statusCode = InvoiceStatusCode.pendingForFinalApprovalOfRevisedQuote;
      _statusName = InvoiceStatusName.pendingForFinalApprovalOfRevisedQuote;
      break;
  }

  /**
   * TO DO:
   * KDV ve diğer vergiler taxes alanında yer alması gerekiyor.
   * Discount alanı da tek alan değil yine Array olmalı.
   * Buraya eklenen kalemlere göre satır toplamları otomatik hesaplanmalı.
   * Geçici olarak vat ve vatRate alanlarına değer yazıldı.
   */

  const _paymentStatus: PaymentStatus = {
    createdAt: _now.toDate(),
    description: "",
    name: PaymentStatusName.unpaid,
    updatedAt: _now.toDate(),
    updatedByMemberId: member?.uid || "",
  };

  const _status: InvoiceStatus = {
    code: _statusCode,
    createdAt: _now.toDate(),
    description: "",
    name: _statusName,
    updatedAt: _now.toDate(),
    updatedByMemberId: member?.uid || "",
  };

  const _type: InvoiceType = {
    createdAt: _now.toDate(),
    description: "",
    name: project.status.name === ProjectStatusName.preApproved ? InvoiceTypeName.quotationRevision : InvoiceTypeName.quotation,
    updatedAt: _now.toDate(),
    updatedByMemberId: member?.uid || "",
  };

  const _noMoney = {
    amount: 0,
    createdAt: _now.toDate(),
    currency: _currency,
  };

  let _grandTotalAmount: number = 0;

  const _projectDeliverables: ProjectDeliverables[] = Object.keys(values).map((valueId): ProjectDeliverables => {
    let _totalAmount: number = 0;

    const _pd: ProjectDeliverables = {
      deliverables: Object.values(values[valueId]).map((deliverable: any): ProjectDeliverable => {
        const _price: Money = {
          amount: deliverable.price,
          createdAt: _now.toDate(),
          currency: Currency.TRY,
        };

        const _totalPrice: Money = {
          amount: deliverable.totalPrice,
          createdAt: _now.toDate(),
          currency: Currency.TRY,
        };

        _totalAmount += deliverable.totalPrice;
        console.log("🚀 ~ file: helpers.ts ~ line 136 ~ deliverables:Object.values ~ _totalAmount", _totalAmount);

        const _projectDeliverable: ProjectDeliverable = {
          areVariationsMultiSelectable: false,
          attachments: [],
          attachmentTips: "",
          hasVariations: false,
          description: "",
          id: "",
          isAttachments: false,
          isAttachmentTipsValid: false,
          isOptional: false,
          isMultiline: false,
          isProduct: false,
          price: _price,
          productCategoryId: ProductCategoryName.none,
          productId: "",
          productVariationId: "",
          pricingType: deliverable.pricingType,
          quantity: deliverable.quantity,
          quantityName: deliverable.quantityName,
          title: deliverable.title,
          totalPrice: _totalPrice,
          type: ProjectDeliverableType.deliverable,
          value: deliverable.value,
          valueOfOptional: false,
          variations: [],
          vatRate: deliverable.vatRate,
        };

        return _projectDeliverable;
      }),
      description: null,
      title: valueId,
      createdAt: _now.toDate(),
      createdBy: member?.uid || "",
      id: valueId,
      updatedAt: _now.toDate(),
      updatedBy: member?.uid || "",
      status: BaseStatusName.available,
      total: {
        amount: _totalAmount,
        createdAt: _now.toDate(),
        currency: _currency,
      },
    };

    _grandTotalAmount += _totalAmount;
    _totalAmount = 0;

    return _pd;
  });

  const _invoice: Invoice = {
    billingAddress: null,
    createdAt: _now.toDate(),
    createdByMemberId: member?.uid || "",
    company,
    companyId: company.id,
    currency: _currency,
    description: "",
    dueDate: _expiresAt.toDate(),
    expiresAt: _expiresAt.toDate(),
    grandTotal: {
      amount: _grandTotalAmount,
      createdAt: _now.toDate(),
      currency: Currency.TRY,
    },
    id: orderId(Timestamp.now().nanoseconds.toString()).generate(),
    invoiceDiscount: _noMoney,
    invoiceSeries: "",
    items: [],
    projectDeliverables: _projectDeliverables,
    projectCity: project.address?.city || "",
    projectId: project.id,
    projectName: project.name,
    projectOwnerCompany: project.ownerCompany,
    revisionOfQuoteId,
    paymentStatus: _paymentStatus,
    shipmentAddress: null,
    shipmentIncluded: false,
    status: _status,
    type: _type,
  };

  return _invoice;
};

export const buildMessage = function (message: string, member: User, project: Project, attachments: FullMetadata[] = [], subject: MessageSubject | null = null, company: Company, senderCompany: Company, recieverCompany: Company) {
  const _now: Timestamp = Timestamp.now();
  const _messageStatus: MessageStatus = {
    deliveredAt: _now.toDate(),
    description: "",
    name: MessageStatusName.unread,
    updatedAt: _now.toDate(),
    updatedByMemberId: member?.uid || "",
    readAt: _now.toDate(), // TO DO: Okundu özelliği eklendiğinde gerçek veri gelmeli ve bu alan Date | null olarak güncellenmeli.
  };
  const _message: Message = {
    attachments: attachments.map((attachment: FullMetadata) => attachment.fullPath),
    channelId: `${project.id}_${company.id}`,
    createdAt: _now.toDate(),
    id: "",
    message,
    recieverCompany: recieverCompany,
    recieverMemberId: project.quotationMemberDocumentReference?.id || "",
    recieverMemberRef: project.quotationMemberDocumentReference,
    relatedProjectCity: project.address?.city || "",
    relatedProjectId: project.id,
    relatedProjectName: project.name,
    relatedProjectRef: doc(collection(getFirestore(), "Projects"), project.id),
    repliedMessageId: null,
    senderCompanyName: senderCompany.legalName,
    senderCompanyId: senderCompany.id,
    senderMemberName: member?.displayName || "",
    senderMemberId: member?.uid || "",
    senderMemberRef: null,
    status: _messageStatus,
    subject,
    type: MessageType.channelMessage,
  };

  return _message;
};

export const buildProjectScope: (values: ProjectFormInitialValuesType) => ProjectScope = function (values: ProjectFormInitialValuesType) {
  const _projectScope: ProjectScope = {
    application: {
      id: "application",
      neededForQuote: true,
      value: values.application,
    },
    applicationDetails: {
      id: "applicationDetails",
      neededForQuote: false,
      value: values.applicationDetails,
    },
    backSpaceCoverage: {
      id: "backSpaceCoverage",
      neededForQuote: true,
      value: values.backSpaceCoverage,
    },
    backSpaceCoverageOptions: {
      id: "backSpaceCoverageOptions",
      neededForQuote: false,
      value: values.backSpaceCoverageOptions,
    },
    notes: {
      id: "notes",
      neededForQuote: false,
      value: values.notes,
    },
    productId: {
      id: "productId",
      neededForQuote: false,
      value: values.productId,
    },
  };

  return _projectScope;
};

export const buildVariationsFromAllAttributes = function (attributes: ProductAttribute[]) {
  let attrs = [];
  const _attributes: {[key: string]: string[]} = attributes.reduce((a, b) => ({...a, [b.name]: b.values}), {});

  for (const [attr, values] of Object.entries(_attributes)) {
    attrs.push(values.map((v) => ({[attr]: v})));
  }

  attrs = attrs.reduce((a, b) => a.flatMap((d) => b.map((e) => ({...d, ...e}))));

  return attrs;
};

export const downloadFile = async function (file: FullMetadata) {
  const _file: string = await getDownloadURL(ref(getStorage(), file.fullPath));
  window.open(_file, "_blank");
};

export const isObject = (obj: object) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

export function resizeFile({compressFormat = "JPEG", file, maxHeight = 100, maxWidth = 100}: {compressFormat?: string; file: File; maxHeight?: number; maxWidth?: number; outputType?: string}): Promise<File> {
  return new Promise((resolve) => {
    FileResizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      100,
      0,
      (uri) => {
        resolve(uri as File);
      },
      "file"
    );
  });
}
