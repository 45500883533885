import { Home } from "@mui/icons-material";
import { Breadcrumbs, Divider, Grid, IconButton, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import useStyles from "../utilities/styles";

const Settings = function () {
  const [emailPeriod, setEmailPeriod] = useState("only_important_activities");
  const [emailMarketing, setEmailMarketing] = useState("never");
  const classes = useStyles();

  let navigate = useNavigate();

  return (
    <>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate("/dashboard")}>
            <Home />
          </IconButton>
          <Typography>{t("settings", "Ayarlar")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Paper className={classes.paperContent}>
              <Grid container>
                <Grid item md={12}>
                  <Typography variant="subtitle2">{t("email_sending_settings", "E-Posta Bildirim Ayarları")}</Typography>
                </Grid>
                <Grid item md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={7}>
                  {t("email_marketing_period", "Hangi Durumlarda E-Posta Gönderilsin")}
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField id="emailPeriod" fullWidth name="emailPeriod" onChange={(event: any) => setEmailPeriod(event.target.value)} select value={emailPeriod}>
                    <MenuItem value="only_important_activities">{t("only_important_activities", "Sadece Önemli Aktiviteler")}</MenuItem>
                    <MenuItem value="all_activities">{t("all_activities", "Tüm Aktiviteler")}</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={7}>
                  {t("email_marketing_content", "Kampanya ve Haftalık Bülten E-Postaları")}
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField id="email_marketing_period" fullWidth name="email_marketing_period" onChange={(event: any) => setEmailMarketing(event.target.value)} select value={emailMarketing}>
                    <MenuItem value="never">{t("never", "Hiçbir Zaman")}</MenuItem>
                    <MenuItem value="weekly_digest">{t("weekly_digest", "Haftalık Özet")}</MenuItem>
                    <MenuItem value="daily_digest">{t("daily_digest", "Günlük Özet")}</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;
