import {atom} from "jotai";
import {focusAtom} from "jotai/optics";
import {atomWithStorage} from "jotai/utils";

import testData from "jack-shared/assets/testData.json";

import {atomInitialDataTestDataIdsType, atomInitialDataType} from "./types";

const testDataIds: atomInitialDataTestDataIdsType = {
  addresses: Object.keys(testData.addresses)[0],
  cards: Object.keys(testData.cards)[0],
  companies: Object.keys(testData.companies)[0],
  products: Object.keys(testData.products)[0],
  projects: Object.keys(testData.projects)[0],
  users: Object.keys(testData.users)[0],
};

/**
 * TO DO:
 * errors alanı tekrar kurgulanmalı.
 */

const initialData: atomInitialDataType = {
  errors: [],
  testData,
  testDataIds,
  isUsingTestData: false, // process.env.NODE_ENV === "development",
};

export const dataAtom = atom<any>(initialData);
dataAtom.debugLabel = "Metretul_DataAtom";

export const isUsingTestDataAtom = focusAtom(dataAtom, (optic) => optic.prop("isUsingTestData"));

export const locationAtom = atom<any>(null);

export const errorsAtom = atom<unknown[]>([null]);
errorsAtom.debugLabel = "Metretul_ErrorsAtom";

export const testDataAtom = focusAtom(dataAtom, (optic) => optic.prop("testData"));

export const testDataIdsAtom = focusAtom(dataAtom, (optic) => optic.prop("testDataIds"));

export const uidAtom = atomWithStorage<string | null>("uid", null);
