import { Menu, MenuItem } from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useMetretulAuthentication, useMetretulSubscription } from "../utilities/hooks";

const MenuMember = function ({ anchorEl, handleClose, open }: any) {
  const { signOutUser } = useMetretulAuthentication();
  const { listenSubscription } = useMetretulSubscription();

  useEffect(
    function () {
      const _unsubscribe = listenSubscription();
      console.log("🚀 ~ file: menu-member.tsx ~ line 13 ~ MenuMember ~ _unsubscribe", _unsubscribe);

      return function () {
        if (!!_unsubscribe) {
          _unsubscribe();
          console.log("🚀 ~ file: menu-member.tsx ~ line 17 ~ _unsubscribe", _unsubscribe);
        }
      };
    },
    [listenSubscription]
  );

  return (
    <Menu
      id="demo-customized-menu"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={anchorEl}
      onClose={handleClose}
      open={open}
    >
      <MenuItem
        onClick={() => {
          handleClose();
          signOutUser();
        }}
        disableRipple
      >
        {t("logout", "Çıkış")}
      </MenuItem>
    </Menu>
  );
};

export default MenuMember;
