import {CameraAlt, Close} from "@mui/icons-material";
import {Button, Card, CardActions, CardMedia, CircularProgress, FormHelperText, Grid, IconButton, MenuItem, Paper, TextField} from "@mui/material";
import {Timestamp} from "firebase/firestore";
import {FormikHelpers, useFormik} from "formik";
import {t} from "i18next";
import {Currency, Money, Product, ProductAttribute, ProductStatus, ProductVarianceType, slugify} from "jack-shared";
import {product as ConstantProduct} from "jack-shared/assets/constants.json";
import {useAtom} from "jotai";
import {orderBy} from "lodash";
import {ChangeEvent, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import TestDataSelector from "../components/test-data-selector";
import {errorsAtom, isUsingTestDataAtom, testDataAtom, testDataIdsAtom} from "../utilities/atoms";
import {buildErrorState, resizeFile} from "../utilities/helpers";
import {useMetretulProducts, useMetretulStorageFolder} from "../utilities/hooks";
import useStyles from "../utilities/styles";
import {ProductFormInitialValuesType, ProductFormPropsType} from "../utilities/types";
const uniqid = require("uniqid");

const ProductForm = function ({companyId, product}: ProductFormPropsType) {
  const [, setErrors] = useAtom(errorsAtom);
  const classes = useStyles();
  const [testData] = useAtom(testDataAtom);
  const [testDataIds] = useAtom(testDataIdsAtom);
  const [isUsingTestData] = useAtom(isUsingTestDataAtom);
  const {writeProduct} = useMetretulProducts();
  const navigate = useNavigate();
  const {downloadFile, loading: uploadingFiles, uploadFiles} = useMetretulStorageFolder();
  const [uploadingFilesList, setUploadingFilesList] = useState<{[field: string]: File[]}>({});
  const uploadFolder = useRef("Products");
  const productId = useRef<string>(uniqid());

  async function handleChangeUploadFiles(event: ChangeEvent<HTMLInputElement>) {
    if (!event?.currentTarget?.files) throw new Error(t("handleChangeUploadFilesHasNoFiles", "handleChangeUploadFiles fonksiyonu event.currentTarget.files dönmüyor."));
    if (!event?.currentTarget?.name) throw new Error(t("handleChangeUploadFilesHasNoName", "handleChangeUploadFiles fonksiyonu event.currentTarget.name dönmüyor."));

    const _name = event.currentTarget.name;
    const _files = await Promise.all(Object.values(event.currentTarget.files).map(async (file) => await resizeFile({file, maxHeight: 640, maxWidth: 640})));

    setUploadingFilesList((fl) => ({...fl, [`${productId.current}`]: _files}));

    const _uploadResultAll = await Promise.all(
      _files.map(async function (_file: any) {
        const _uploadResult = await uploadFiles({file: _file, path: `${uploadFolder.current}/${productId.current}/${_file.name}`});

        return _uploadResult;
      })
    );
    console.log("🚀 ~ file: delivery-request-form.tsx ~ line 27 ~ onChange ~ _uploadResultAll", _uploadResultAll);

    if (!!_uploadResultAll.length && _uploadResultAll[0]?.metadata?.fullPath) {
      const _attachment = await downloadFile(_uploadResultAll[0].metadata.fullPath);
      console.log("🚀 ~ file: product-form.tsx:53 ~ handleChangeUploadFiles ~ _attachment", _attachment);

      await setFieldValue(_name, _attachment);
    }
  }

  const validationSchema = yup.object({
    categoryId: yup.string().required(t("categoryIsRequires", "Ürün kategorisi gereklidir.")),
    name: yup.string().required(t("nameIsRequires", "Ürün ismi gereklidir.")),
    variance: yup.string().required(t("varianceIsRequired", "Ürün tipi gereklidir.")),
    attributes: yup.array().when("variance", {
      is: (variance: string) => {
        return variance === ProductVarianceType.variable;
      },
      then: yup
        .array()
        .of(
          yup.object({
            name: yup.string().required(t("attributeNameIsRequired", "Nitelik ismi gereklidir.")),
            values: yup.string().required(t("attributeValueIsRequired", "En az bir adet nitelik değeri gereklidir.")),
          })
        )
        .min(1, t("minimumOneAttributeIsRequires", "Varyantlı ürünler için en az bir nitelik gereklidir.")),
    }),
  });

  const onSubmit = async function (values: ProductFormInitialValuesType, actions: FormikHelpers<ProductFormInitialValuesType>) {
    try {
      const _now: Timestamp = Timestamp.now();
      const _price: Money = {
        amount: Number(values.listPrice),
        createdAt: _now.toDate(),
        currency: Currency.TRY,
      };

      const _attributes: ProductAttribute[] = values.attributes.map((attribute: any) => ({
        ...attribute,
        values: attribute.values.split("\n"),
      }));

      const _product: Product = {
        allowMultiplePurchase: true,
        allowedPointsUsagePercentage: 0,
        attachment: values.attachment,
        attachments: null,
        attributes: _attributes,
        autoRenew: false,
        barcode: "",
        categoryIds: [values.categoryId],
        companyId,
        createdAt: _now.toDate(),
        criticalStockCount: 0,
        currentPrice: _price,
        currentPriceRange: null,
        description: values.description,
        duration: null,
        expiresAt: null,
        gtip: null,
        hasStockMovements: false,
        id: product?.id || slugify(`${values.name}-${uniqid()}`),
        initialStock: 0,
        inventoryTracking: false,
        isSaleable: false,
        isUsageConfirmationNeeded: false,
        listPrice: _price,
        listPriceRange: null,
        name: values.name,
        order: 0,
        paymentId: "",
        startsAt: _now.toDate(), // TO DO: Düzeltilmeli ve null destekleyen hale gelmeli.
        status: ProductStatus.available,
        stock: null,
        stockType: null,
        updatedAt: _now.toDate(),
        taxes: [],
        varianceType: values.variance,
        variations: [],
      };

      const _productDocumentReference = await writeProduct(_product);
      console.log("🚀 ~ file: product-service-form.tsx ~ line 97 ~ onSubmit ~ _productDocumentReference", _productDocumentReference);

      navigate("/products");
    } catch (error) {
      console.error("🚀 ~ file: product-form.tsx ~ line 9 ~ onSubmit ~ error", (error as Error).message);
      setErrors((e: any[]) => [...e, error]);
    }
  };

  const {errors, handleChange, handleSubmit, setFieldValue, touched, values} = useFormik<ProductFormInitialValuesType>({
    enableReinitialize: true,
    initialValues: {
      attachment: !!product?.attachment ? product.attachment : "",
      attributes: isUsingTestData ? testData.products[testDataIds.products].attributes : !!product ? product.attributes : [],
      categoryId: isUsingTestData ? testData.products[testDataIds.products].categoryIds[0] : !!product ? product.categoryIds[0] : "",
      description: isUsingTestData ? testData.products[testDataIds.products].description : !!product ? product.description : "",
      name: isUsingTestData ? testData.products[testDataIds.products].name : !!product ? product.name : "",
      listPrice: isUsingTestData ? testData.products[testDataIds.products].listPrice.amount : !!product ? product.listPrice : "",
      variance: isUsingTestData ? testData.products[testDataIds.products].varianceType : !!product ? product.varianceType : "",
    },
    onSubmit,
    validationSchema,
  });

  if (process.env.NODE_ENV === "development") {
    (window as any).values = values;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={6}>
        <Grid component="form" container direction="column" onSubmit={handleSubmit}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paperContent}>
              <Grid container>
                {process.env.NODE_ENV === "development" && (
                  <Grid item xs={12}>
                    <TestDataSelector id="products" />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField error={buildErrorState(touched, errors, "categoryId").state} fullWidth helperText={buildErrorState(touched, errors, "categoryId").helperText} label={t("productCategory", "Ürün Kategorisi")} name="categoryId" onChange={handleChange} select value={values.categoryId}>
                    {Object.values(orderBy(ConstantProduct.categories, "name")).map((category: any, index: number) => (
                      <MenuItem key={`type_${index}`} value={category.id}>
                        {t(category.id, category.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField error={buildErrorState(touched, errors, "name").state} fullWidth helperText={buildErrorState(touched, errors, "name").helperText} label={t("productName", "Ürün İsmi")} name="name" onChange={handleChange} value={values.name} />
                </Grid>
                <Grid item xs={12}>
                  <TextField error={buildErrorState(touched, errors, "listPrice").state} fullWidth helperText={buildErrorState(touched, errors, "listPrice").helperText} label={t("listPrice", "Fiyat")} name="listPrice" onChange={handleChange} value={values.listPrice} />
                </Grid>
                <Grid item xs={12}>
                  <TextField error={buildErrorState(touched, errors, "description").state} fullWidth helperText={buildErrorState(touched, errors, "description").helperText} label={t("productDescription", "Ürün Açıklaması")} minRows={3} multiline name="description" onChange={handleChange} value={values.description} />
                </Grid>
                <Grid item xs={12}>
                  {!!uploadingFilesList?.[`${productId.current}`]?.length ? (
                    <Card>
                      <CardMedia component="img" src={window.URL.createObjectURL(uploadingFilesList[`${productId.current}`][0])} />
                      <CardActions>
                        <Button
                          onClick={() => {
                            setUploadingFilesList({});
                          }}
                          variant="outlined"
                        >
                          {t("delete", "Sil")}
                        </Button>
                      </CardActions>
                    </Card>
                  ) : (
                    <label style={{display: "flex", flex: 1}} htmlFor="attachment">
                      <input disabled={uploadingFiles} hidden id="attachment" name="attachment" onChange={handleChangeUploadFiles} type="file" />
                      <Button color="primary" component="span" disabled={uploadingFiles} fullWidth size="large" variant="outlined">
                        {uploadingFiles ? <CircularProgress size={22} /> : <CameraAlt color="primary" />}
                      </Button>
                    </label>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField error={buildErrorState(touched, errors, "variance").state} fullWidth helperText={buildErrorState(touched, errors, "variance").helperText} label={t("productType", "Ürün Tipi")} name="variance" onChange={handleChange} select value={values.variance}>
                    {Object.values(ConstantProduct.varianceTypes)
                      .filter((type) => type.id === ProductVarianceType.simple || type.id === ProductVarianceType.variable)
                      .map((type: any, index: number) => (
                        <MenuItem key={`type_${index}`} value={type.id}>
                          {t(type.id, type.name)}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                {Array.isArray(values?.attributes) &&
                  !!values?.attributes.length &&
                  values.variance === ProductVarianceType.variable &&
                  values.attributes.map((attribute: ProductAttribute, attributeIndex: number) => {
                    const _touchedName = Array.isArray((touched as any)?.attributes) ? (touched as any)?.attributes[attributeIndex]?.name : (touched as any)?.attributes;
                    const _errorName = Array.isArray((errors as any)?.attributes) ? (errors as any)?.attributes[attributeIndex]?.name : (errors as any)?.attributes;
                    const _touchedValues = Array.isArray((touched as any)?.attributes) ? (touched as any)?.attributes[attributeIndex]?.values : (touched as any)?.attributes;
                    const _errorValues = Array.isArray((errors as any)?.attributes) ? (errors as any)?.attributes[attributeIndex]?.values : (errors as any)?.attributes;

                    return (
                      <Grid item key={`attributeIndex_${attributeIndex}`} xs={12}>
                        <Grid container>
                          <Grid item xs={12} md={values.attributes.length > 1 ? 5.5 : 6}>
                            <TextField error={!!_touchedName && !!!!_errorName} fullWidth helperText={!!_touchedName && _errorName} label={t("attributeName", "Nitelik İsmi")} name={`attributes[${attributeIndex}].name`} onChange={handleChange} value={values.attributes[attributeIndex].name} />
                          </Grid>
                          <Grid item xs={values.attributes.length > 1 ? 10 : 12} md={values.attributes.length > 1 ? 5.5 : 6}>
                            <TextField error={!!_touchedValues && !!_errorValues} fullWidth helperText={!!_touchedValues && !!_errorValues ? _errorValues : t("valuePerLine", "Her satıra bir nitelik yazın.")} label={t("attributeValues", "Nitelik Değerleri")} minRows={3} multiline name={`attributes[${attributeIndex}].values`} onChange={handleChange} value={values.attributes[attributeIndex].values} />
                          </Grid>
                          {values.attributes.length > 1 && (
                            <Grid item xs={2} md={1}>
                              <IconButton
                                onClick={() =>
                                  setFieldValue(
                                    "attributes",
                                    values.attributes.filter((attributeValue: any, attributeValueIndex: number) => attributeValueIndex !== attributeIndex)
                                  )
                                }
                              >
                                <Close />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                {values.variance === ProductVarianceType.variable && (
                  <Grid item xs={12}>
                    <Button color={!!touched?.attributes && typeof errors?.attributes === "string" ? "error" : "primary"} fullWidth onClick={() => setFieldValue("attributes", [...values.attributes, {name: "", usedForVariations: true, visible: false, values: ""}])} variant="outlined">
                      {t("addAttribute", "Yeni Nitelik Ekle")}
                    </Button>
                    {!!touched?.attributes && typeof errors?.attributes === "string" && <FormHelperText error>{errors.attributes}</FormHelperText>}
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paperContent}>
              <Button fullWidth type="submit" variant="contained">
                {t("saveProduct", "Ürünü Kaydet")}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductForm;
