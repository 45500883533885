import {createTheme} from "@mui/material";
import {grey, red} from "@mui/material/colors";

import {colors} from "jack-shared/assets/constants.json";

const theme = createTheme({
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          textTransform: "none",
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: "small",
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          backgroundColor: colors.acik_gri,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 2,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          minHeight: 59,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        variant: "filled",
      },
    },
  },
  palette: {
    background: {
      default: grey[200],
    },
    primary: {
      main: red[600],
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
  },
});

export default theme;
