import {Alert, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, Paper, TextField} from "@mui/material";
import {FormikHelpers, useFormik} from "formik";
import {t} from "i18next";
import {useAtom} from "jotai";
import InputMask from "react-input-mask";
import {Navigate, useLocation} from "react-router-dom";
import {useSnapshot} from "valtio";
import {state} from "..";
import TestDataSelector from "../components/test-data-selector";
import {errorsAtom, isUsingTestDataAtom, testDataAtom, testDataIdsAtom} from "../utilities/atoms";
import {useMetretulAuthentication} from "../utilities/hooks";
import useStyles from "../utilities/styles";
import {RegisterFormType} from "../utilities/types";

const RegisterForm = function () {
  const classes = useStyles();
  const [testData] = useAtom(testDataAtom);
  const [testDataIds] = useAtom(testDataIdsAtom);
  const [isUsingTestData] = useAtom(isUsingTestDataAtom);
  const [, setError] = useAtom(errorsAtom);
  const {createUser, loadingAuthentication} = useMetretulAuthentication();
  const {currentUser, role} = useSnapshot(state);

  let location = useLocation();

  const _path = `${(location as any)?.state?.from?.pathname || "/dashboard"}`;

  const onSubmit = async function (values: RegisterFormType, actions: FormikHelpers<RegisterFormType>) {
    try {
      console.log("🚀 ~ file: register-form.tsx ~ line 31 ~ onSubmit ~ values", values);

      const {email, name, password, phoneNumber} = values;
      console.log("🚀 ~ file: register-form.tsx ~ line 31 ~ onSubmit ~ email, name, password, phoneNumber", email, name, password, phoneNumber);

      /**
       * TO DO:
       *
       * Üye kayıt formu ikiye ayrılmalı.
       * İlk adımda e-posta ve şifre alınıp üyelik kaydı açılmalı.
       * İkinci adımda profil fotoğrafı, isim, telefon gibi ek bilgiler alınarak Members içindeki Document güncellenmeli.
       *
       */

      const _user = await createUser({email, name, password, phoneNumber});
      console.log("🚀 ~ file: register-form.tsx ~ line 28 ~ onSubmit ~ _user", _user);
    } catch (error: any) {
      console.error(error?.message ?? "test");
      setError((errors) => [...errors, error]);
    } finally {
    }
  };

  const {errors, handleChange, handleSubmit, touched, values} = useFormik<RegisterFormType>({
    enableReinitialize: true,
    initialValues: {
      agreement: isUsingTestData,
      cookie: false,
      email: isUsingTestData ? testData.users[testDataIds.users].email : "",
      name: isUsingTestData ? testData.users[testDataIds.users].displayName : "",
      password: isUsingTestData ? process.env?.REACT_APP_TEST_USERS_PASSWORD || "" : "",
      password2: isUsingTestData ? process.env?.REACT_APP_TEST_USERS_PASSWORD || "" : "",
      phoneNumber: isUsingTestData ? testData.users[testDataIds.users].phoneNumber : "",
    },
    onSubmit,
  });

  if (!!currentUser && !!role) return <Navigate to={_path} />;

  if (process.env.NODE_ENV === "development") {
    (window as any).errors = errors;
    (window as any).values = values;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Paper className={classes.paperContent}>
        <Grid container direction="column">
          {process.env.NODE_ENV === "development" && (
            <Grid item>
              <TestDataSelector id="users" />
            </Grid>
          )}
          <Grid item>
            <Grid container>
              <Grid item xs={12} md={6}>
                <TextField id="name" error={!!touched.name && !!errors.name} fullWidth helperText={!!touched.name && errors?.name} label={t("name", "İsim Soyisim")} name="name" onChange={handleChange} value={values.name} />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputMask id="phoneNumber" mask="+90 (599) 999 99 99" maskPlaceholder="+90 (5__) ___ __ __" name="phoneNumber" onChange={handleChange} value={values.phoneNumber}>
                  <TextField error={!!touched.phoneNumber && !!errors.phoneNumber} fullWidth helperText={!!touched.phoneNumber && errors?.phoneNumber} label={t("phoneNumber", "Telefon")} />
                </InputMask>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField id="email" error={!!touched.email && !!errors.email} fullWidth helperText={!!touched.email && errors?.email} label={t("email", "E-posta")} name="email" onChange={handleChange} value={values.email} />
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12} md={6}>
                <TextField id="password" error={!!touched.password && !!errors.password} fullWidth helperText={(!!touched.password && errors?.password) || t("min6characters", "En az 6 karakter.")} label={t("password", "Şifre")} name="password" onChange={handleChange} type="password" value={values.password} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField id="password2" error={!!touched.password2 && !!errors.password2} fullWidth helperText={!!touched.password2 && errors?.password2} label={t("password_retype", "Şifre (tekrar)")} name="password2" onChange={handleChange} type="password" value={values.password2} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={0}>
              <Grid item>
                <FormControl required error={!!touched.agreement && !!errors.agreement}>
                  <FormControlLabel control={<Checkbox checked={!!values.agreement} onChange={handleChange} name="agreement" />} label={t("check_agreement", "Sözleşmeleri kabul ediyorum.") as string} />
                  <FormHelperText>{!!touched.agreement && errors?.agreement}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControlLabel control={<Checkbox checked={!!values.cookie} onChange={handleChange} name="cookie" />} label={t("check_cookie", "Tanıtım izni veriyorum.") as string} />
              </Grid>
            </Grid>
          </Grid>
          {!!Object.keys(errors).length && <Alert>{JSON.stringify(errors)}</Alert>}
          <Grid item>
            <Button disabled={loadingAuthentication} color="primary" variant="contained" fullWidth type="submit">
              {loadingAuthentication ? <CircularProgress size={24} /> : t("register", "Üye ve Firma Bilgilerini Kaydet")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default RegisterForm;
