import {LockOpen, Notifications as NotificationsIcon, PersonOutline, ShoppingCart} from "@mui/icons-material/";
import {AppBar, Badge, Button, Container, CssBaseline, Grid, IconButton, Toolbar, Typography} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {t} from "i18next";
import {Company} from "jack-shared";
import {SnackbarProvider} from "notistack";
import {SyntheticEvent, useEffect, useRef, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {CartProvider} from "react-use-cart";
import {useSnapshot} from "valtio";
import {state} from ".";
import {Commit} from "./components/commit";
import Errors from "./components/errors";
import Footer from "./components/footer";
import MenuDrawer from "./components/menu-drawer";
import MenuMember from "./components/menu-member";
import RequireAuth from "./components/require-auth";
import "./locales/config";
import ProductWrite from "./screens/add-product";
import Cart from "./screens/cart";
import CompanySettings from "./screens/company-settings";
import CompanyWrite from "./screens/company-write";
import Dashboard from "./screens/dashboard";
import ForgotPassword from "./screens/forgot-password";
import Home from "./screens/home";
import LoginRegister from "./screens/member-create";
import Member from "./screens/member-read";
import Menu from "./screens/menu";
import NotFound from "./screens/not-found";
import NotificationsList from "./screens/notifications";
import UpdatePassword from "./screens/password-update";
import PaymentMethods from "./screens/payment-method-list";
import Plans from "./screens/plans";
import ProductList from "./screens/product-list";
import ProductVariantsWrite from "./screens/product-variations";
import Result from "./screens/result";
import Settings from "./screens/settings";
import Tables from "./screens/tables";
import TransactionList from "./screens/transaction-list";
import {useMetretulCompanies, useMetretulNotifications, useMetretulSubscription} from "./utilities/hooks";
import theme from "./utilities/theme";

function App() {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const {cities, companies, companiesWithIds, isLoadingCompanies} = useMetretulCompanies();
  const {availableCompanyCategories} = useMetretulSubscription();
  const [message] = useState<string>("");
  const {currentUser, role} = useSnapshot(state);
  const {getNotifications, unreadNotifications} = useMetretulNotifications();
  const currentCompany = useRef<Company | null>(null);

  let navigate = useNavigate();

  const handleClick = function (event: SyntheticEvent) {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = function () {
    setAnchorEl(null);
  };

  useEffect(() => {
    console.count("App useEffect getNotifications");
    const _unsubscribe = getNotifications(true);

    return () => {
      if (_unsubscribe) {
        console.count("App useEffect _unsubscribe");
        _unsubscribe();
      }
    };
  }, [getNotifications]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={2000} dense maxSnack={2}>
        <CartProvider metadata={{billing: null, member: null, shipping: null}}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <AppBar position="static">
              <Toolbar>
                <MenuDrawer availableCompanyCategories={availableCompanyCategories} companies={companies} message={message} />
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                  {t("jack", "Jack")}
                  <Commit />
                </Typography>
                <>
                  <Button color="inherit" disableElevation endIcon={<ShoppingCart />} onClick={() => navigate("/cart", {state: {company: currentCompany.current}})}>
                    {t("cart", "Sepet")}
                  </Button>
                </>
                {!role && window.location.pathname !== "/login" && window.location.pathname !== "/register" && (
                  <Button color="inherit" disableElevation endIcon={<LockOpen />} onClick={() => navigate("login")}>
                    {t("login", "Giriş")}
                  </Button>
                )}
                {!!role && (
                  <>
                    <IconButton color="inherit" onClick={() => navigate("/notifications")}>
                      <Badge badgeContent={unreadNotifications.length} color="error">
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                    <Button aria-controls={open ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} color="inherit" id="demo-customized-button" onClick={handleClick} startIcon={<PersonOutline />}>
                      {currentUser?.displayName || currentUser?.email?.split("@")[0]}
                    </Button>
                    <MenuMember anchorEl={anchorEl} handleClose={handleClose} open={open} />
                  </>
                )}
              </Toolbar>
            </AppBar>

            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <Errors />
                </Grid>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/menu/:companyId" element={<Menu currentCompany={(c) => (currentCompany.current = c)} />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/result" element={<Result />} />
                  <Route path="/login" element={<LoginRegister />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/register" element={<LoginRegister />} />
                  <Route path="/plans" element={<RequireAuth screen={<Plans />} />} />
                  <Route path="/member" element={<RequireAuth screen={<Member />} />} />
                  <Route path="/member/:uid" element={<RequireAuth screen={<Member />} />} />
                  <Route path="/company" element={<RequireAuth screen={<CompanyWrite />} />} />
                  <Route path="/settings/company/:companyId" element={<RequireAuth screen={<CompanySettings companies={companies} />} />} />
                  <Route path="/company/:uid" element={<RequireAuth screen={<CompanyWrite />} />} />
                  <Route path="/payment-methods" element={<RequireAuth screen={<PaymentMethods />} />} />
                  <Route path="/update-password" element={<RequireAuth screen={<UpdatePassword />} />} />
                  <Route path="/settings" element={<RequireAuth screen={<Settings />} />} />
                  <Route path="/notifications" element={<RequireAuth screen={<NotificationsList />} />} />
                  <Route path="/transactions/:type" element={<RequireAuth screen={<TransactionList />} />} />
                  <Route path="/dashboard" element={<RequireAuth screen={<Dashboard availableCompanyCategories={availableCompanyCategories} cities={cities} companies={companies} companiesWithIds={companiesWithIds} isLoadingCompanies={isLoadingCompanies} message={message} />} />} />
                  <Route path="/products" element={<RequireAuth screen={<ProductList companies={companies} />} />} />
                  <Route path="/product" element={<RequireAuth screen={<ProductWrite companies={companies} />} />} />
                  <Route path="/product/:id" element={<RequireAuth screen={<ProductWrite companies={companies} />} />} />
                  <Route path="/product/:id/variants" element={<RequireAuth screen={<ProductVariantsWrite />} />} />
                  <Route path="/tables" element={<RequireAuth screen={<Tables companies={companies} />} />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />
              </Grid>
            </Container>
          </LocalizationProvider>
        </CartProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
