import {List, ListItem, ListItemText, ListSubheader, Paper, Typography} from "@mui/material";
import {t} from "i18next";
import moment from "moment";
import {useEffect} from "react";
import {useJackPoints} from "../utilities/hooks";

export default function MemberPoints({memberId}: {memberId: string}) {
  const {getPoints, points} = useJackPoints();

  useEffect(() => {
    if (memberId) {
      const _unsubscribe = getPoints(memberId);
      console.log("🚀 ~ file: member-points.tsx:15 ~ return ~ _unsubscribe:", _unsubscribe);

      return () => {
        if (_unsubscribe) {
          _unsubscribe();
        }
      };
    }
  }, [getPoints, memberId]);

  return (
    <Paper>
      <List>
        <ListSubheader>
          <Typography>{t("earnedPoints", "Kazandığınız Puanlar")}</Typography>
        </ListSubheader>
        {points.map((point, index) => (
          <ListItem key={`${point.memberId}_${index}`}>
            <ListItemText
              primary={
                <>
                  <Typography variant="subtitle1">
                    {t("earnedPoint", "Kazanılan Puan")} {point.value}
                  </Typography>
                  <Typography variant="body2">
                    {t("company", "Kazanılan Mejan")}
                    <Typography variant="caption">— {point.companyId}</Typography>
                  </Typography>
                </>
              }
              secondary={
                <Typography variant="caption">
                  {t("expiresAt", "Son Kullanım Tarihi")} {moment(point.expiresAt).format("lll")}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}
