import {Save} from "@mui/icons-material";
import {Button, Grid, MenuItem, TextField} from "@mui/material";
import {User} from "firebase/auth";
import {DocumentReference} from "firebase/firestore";
import {FormikHelpers, useFormik} from "formik";
import {t} from "i18next";
import {Company} from "jack-shared";
import {companyCategories, countries} from "jack-shared/assets/constants.json";
import {useAtom} from "jotai";
import {intersection} from "lodash";
import {useEffect, useMemo, useRef, useState} from "react";
import InputMask from "react-input-mask";
import {useNavigate} from "react-router-dom";
import {useSnapshot} from "valtio";
import * as yup from "yup";
import {state} from "..";
import TestDataSelector from "../components/test-data-selector";
import {errorsAtom, isUsingTestDataAtom, testDataAtom, testDataIdsAtom} from "../utilities/atoms";
import {useMetretulSubscription} from "../utilities/hooks";
import {createCompany} from "../utilities/remote";

const CompanyForm = function ({company, editable = true}: {company?: Company | null; editable?: boolean}) {
  const [testData] = useAtom(testDataAtom);
  const [testDataIds] = useAtom(testDataIdsAtom);
  const [isUsingTestData] = useAtom(isUsingTestDataAtom);
  const [, setError] = useAtom(errorsAtom);
  const {availableCompanyCategories} = useMetretulSubscription();
  const {currentUser: member} = useSnapshot(state);
  const [additionalFields] = useState<boolean>(false);
  const citiesRef = useRef(countries.tr);
  const [districts, setDistricts] = useState<any[]>();

  const companyCategoriesMemo = useMemo(() => Object.values(companyCategories).filter((c) => intersection(c.availableForPlanCategories, availableCompanyCategories).length), [availableCompanyCategories]);

  let navigate = useNavigate();

  const onSubmit = async function <T>(values: T, actions: FormikHelpers<T>) {
    try {
      const _companyDocumentReference: DocumentReference = await createCompany(values, null, member as User, additionalFields);
      console.log("🚀 ~ file: company-form.tsx ~ line 37 ~ CompanyForm ~ _companyDocumentReference", _companyDocumentReference);

      navigate("/dashboard");
    } catch (error) {
      setError((errors) => [...errors, error]);
    }
  };

  const selectCity = function (event: any) {
    setFieldValue("company_address_district", "");
    setFieldValue("company_address_city", event.target.value);
  };

  const selectCompanyArea = function (event: any) {
    if (event?.target?.value) {
      setFieldValue("company_area", event.target.value);
    }
  };

  const validationSchema = yup.object({
    company_area: yup.string().required(t("validation_required_company_area", "Firma alanı gereklidir.")),
    company_address: yup.string().required(t("validation_required_company_address", "Firma adresi gereklidir.")),
    company_address_city: yup.string().required(t("validation_required_company_address_city", "Firma şehri gereklidir.")),
    company_address_district: yup.string().required(t("validation_required_company_address_district", "Firma ilçesi gereklidir.")),
    company_phone: yup.string().required(t("validation_required_company_phone", "Firma telefonu gereklidir.")),
    company_name: yup.string().required(t("validation_required_company_name", "Firma ünvanı gereklidir.")),
    kep: yup.string().email(t("validation_kep", "E-posta (KEP) adresini kontrol edin.")).required(t("validation_kep_required", "E-posta (KEP) adresi gereklidir.")),
    tax_number: yup.string().required(t("validation_required_tax_number", "Vergi numarası gereklidir.")),
    tax_office: yup.string().required(t("validation_required_tax_office", "Vergi dairesi gereklidir.")),
    ...(additionalFields ? {employeeNumbers: yup.number().required(t("validation_required_employeeNumbers", "Çalışan sayısı gereklidir."))} : {}),
    ...(additionalFields ? {experiencedYearsOnField: yup.number().required(t("validation_required_experiencedYearsOnField", "Kuruluş yılı bilgisi gereklidir."))} : {}),
  });

  const {errors, handleChange, handleSubmit, setFieldValue, touched, values} = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_area: companyCategoriesMemo[0]?.id ?? "",
      company_address: isUsingTestData ? testData.companies[testDataIds.companies].address : "",
      company_address_city: isUsingTestData ? testData.companies[testDataIds.companies].city : "",
      company_address_district: isUsingTestData ? testData.companies[testDataIds.companies].district : "",
      company_phone: isUsingTestData ? testData.companies[testDataIds.companies].contactPhone : "",
      company_name: isUsingTestData ? testData.companies[testDataIds.companies].legalName : "",
      kep: isUsingTestData ? testData.companies[testDataIds.companies].contactEmail : "",
      tax_number: isUsingTestData ? testData.companies[testDataIds.companies].taxNumber : "",
      tax_office: isUsingTestData ? testData.companies[testDataIds.companies].taxOffice : "",
      ...(additionalFields ? {employeeNumbers: ""} : {}),
      ...(additionalFields ? {experiencedYearsOnField: ""} : {}),
    },
    onSubmit,
    validationSchema,
  });

  useEffect(
    function () {
      console.log("🚀 ~ file: company-form.tsx ~ line 115 ~ CompanyForm ~ values.company_address_city", values.company_address_city);

      if (!!values.company_address_city) {
        const _districts = Object.values(citiesRef.current).find((city) => city.name === values.company_address_city)?.districts;
        const _districtsValues = Object.values(_districts as object);
        console.log("🚀 ~ file: company-form.tsx ~ line 117 ~ CompanyForm ~ _districtsValues", _districtsValues);

        setDistricts(_districtsValues);
      }
    },
    [values.company_address_city]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        {process.env.NODE_ENV === "development" && (
          <Grid item xs={12} md={6}>
            <TestDataSelector id="companies" />
          </Grid>
        )}
        <Grid item>
          <TextField disabled={!editable} error={!!touched.company_area && !!errors.company_area} fullWidth helperText={!!touched.company_area && errors?.company_area} id="company_area" label={t("company_area", "Sektör")} name="company_area" onChange={selectCompanyArea} select value={values.company_area}>
            {companyCategoriesMemo.map((companyCategory) => (
              <MenuItem key={companyCategory.id} value={companyCategory.id}>
                {companyCategory.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField disabled={!editable} error={!!touched.company_name && !!errors.company_name} fullWidth helperText={!!touched.company_name && errors?.company_name} id="company_name" label={t("company_name", "Firma Ünvanı")} name="company_name" onChange={handleChange} value={values.company_name} />
        </Grid>
        <Grid item>
          <TextField id="company_address" disabled={!editable} error={!!touched.company_address && !!errors.company_address} fullWidth helperText={!!touched.company_address && errors?.company_address} label={t("company_address", "Firma Adresi")} minRows={3} multiline name="company_address" onChange={handleChange} value={values.company_address} />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={12} md={!!values.company_address_city ? 6 : 12}>
              <TextField error={!!touched?.company_address_city && !!errors?.company_address_city} fullWidth helperText={!!touched?.company_address_city && errors?.company_address_city} label={t("city", "İl")} name="company_address_city" onChange={selectCity} select value={values.company_address_city}>
                {Object.values(citiesRef.current)
                  .filter((city) => city.index === 34 || city.index === 6)
                  .map((city) => (
                    <MenuItem key={`${city.name}_${city.index}`} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              {!!values.company_address_city && !!districts?.length && (
                <TextField error={!!touched?.company_address_district && !!errors?.company_address_district} fullWidth helperText={!!touched?.company_address_district && errors?.company_address_district} label={t("district", "İlçe / Mahalle")} name="company_address_district" onChange={handleChange} select value={values.company_address_district}>
                  {districts?.map((district) => (
                    <MenuItem key={`${district.name}_${district.index}`} value={district.name}>
                      {district.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField disabled={!editable} error={!!touched.tax_office && !!errors.tax_office} fullWidth helperText={!!touched.tax_office && errors?.tax_office} id="tax_office" label={t("tax_office", "Vergi Dairesi")} name="tax_office" onChange={handleChange} value={values.tax_office} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField disabled={!editable} error={!!touched.tax_number && !!errors.tax_number} fullWidth helperText={!!touched.tax_number && errors?.tax_number} id="tax_number" label={t("tax_number", "Vergi Numarası)")} name="tax_number" onChange={handleChange} type="number" value={values.tax_number} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <InputMask disabled={!editable} id="company_phone" mask="0 (999) 999 99 99" name="company_phone" onChange={handleChange} value={values.company_phone}>
            <TextField error={!!touched.company_phone && !!errors.company_phone} fullWidth helperText={!!touched.company_phone && errors?.company_phone} label={t("company_phone", "Firma Telefonu")} />
          </InputMask>
        </Grid>
        <Grid item>
          <TextField disabled={!editable} error={!!touched.kep && !!errors.kep} fullWidth helperText={!!touched.kep && errors?.kep} id="kep" label={t("kep", "E-posta (KEP) Adresi")} name="kep" onChange={handleChange} type="email" value={values.kep} />
        </Grid>
        {additionalFields && (
          <Grid item>
            <Grid container>
              <Grid item xs={12} md={6}>
                <TextField disabled={!editable} error={!!touched.employeeNumbers && !!errors.employeeNumbers} fullWidth helperText={!!touched.employeeNumbers && errors?.employeeNumbers} id="employeeNumbers" label={t("employeeNumbers", "Çalışan Sayısı")} name="employeeNumbers" onChange={handleChange} type="number" value={values.employeeNumbers} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField disabled={!editable} error={!!touched.experiencedYearsOnField && !!errors.experiencedYearsOnField} fullWidth helperText={!!touched.experiencedYearsOnField && errors?.experiencedYearsOnField} id="experiencedYearsOnField" label={t("experiencedYearsOnField", "Kuruluş Yılı")} name="experiencedYearsOnField" onChange={handleChange} type="number" value={values.experiencedYearsOnField} />
              </Grid>
            </Grid>
          </Grid>
        )}
        {editable && (
          <Grid item>
            <Button color="primary" startIcon={<Save />} fullWidth type="submit" variant="contained">
              {company ? t("submit_update_company", "Firma Bilgilerini Güncelle") : t("createNewCompany", "Firma Bilgilerini Kaydet")}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default CompanyForm;
