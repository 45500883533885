import {ArrowBack, Home as HomeIcon, Menu as MenuIcon} from "@mui/icons-material";
import {Divider, Drawer, Grid, IconButton, List, ListItemButton, ListItemText, Toolbar, Typography} from "@mui/material";
import {t} from "i18next";
import {intersection} from "lodash";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSnapshot} from "valtio";
import {state} from "..";
import useStyles from "../utilities/styles";
import {MenuDrawerComponentPropsType} from "../utilities/types";

const MenuDrawer = function ({availableCompanyCategories, companies, message}: MenuDrawerComponentPropsType) {
  const [drawer, setDrawer] = useState<boolean>(false);
  const classes = useStyles();
  const {role} = useSnapshot(state);

  let navigate = useNavigate();

  const checkCapability = function (capabilities: string[]) {
    return Boolean(intersection(role?.capabilities, capabilities).length);
  };

  const buildLink = function (page: string) {
    navigate(page);
    setDrawer(false);
  };

  return !!role ? (
    <>
      <IconButton
        aria-label="menu"
        color="inherit"
        edge="start"
        onClick={() => {
          setDrawer(true);
        }}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={drawer}
        onClose={() => {
          setDrawer(false);
        }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between">
            <Grid item>
              <IconButton aria-label="menu" color="primary" edge="start" onClick={() => setDrawer(false)}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="menu" color="primary" edge="end" onClick={() => buildLink("/dashboard")}>
                <HomeIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>

        <List className={classes.listLeftMenu}>
          {checkCapability(["listTables"]) && (
            <>
              <Divider />
              <ListItemButton onClick={() => buildLink("/tables")}>
                <ListItemText primary={<Typography>{t("tables", "Masalar")}</Typography>} />
              </ListItemButton>
            </>
          )}
          {checkCapability(["createProduct"]) && (
            <>
              <Divider />
              <ListItemButton onClick={() => buildLink("/products")}>
                <ListItemText primary={<Typography>{t("products", "Ürünler")}</Typography>} />
              </ListItemButton>
            </>
          )}
          {checkCapability(["updateCompanySettings"]) && companies[0] && (
            <>
              <Divider />
              <ListItemButton onClick={() => buildLink(`/settings/company/${companies[0].id}`)}>
                <ListItemText primary={<Typography>{t("settings", "Ayarlar")}</Typography>} />
              </ListItemButton>
            </>
          )}
          <Divider />
        </List>
      </Drawer>
    </>
  ) : null;
};

export default MenuDrawer;
