import {Grid, Tab, Tabs} from "@mui/material";
import {useEffect, useState} from "react";
import {t} from "i18next";
import {useLocation, useNavigate} from "react-router-dom";

import LoginForm from "../forms/login-form";
import RegisterForm from "../forms/register-form";

const LoginRegister = function () {
  const [tab, setTab] = useState(window.location.pathname === "/login" ? 0 : 1);
  const location = useLocation();

  let navigate = useNavigate();

  useEffect(
    function () {
      setTab(location.pathname === "/login" ? 0 : 1);
    },
    [location.pathname]
  );

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="center">
        <Grid item md={5} xs={12}>
          <Grid container direction="column" spacing={0}>
            <Grid item>
              <Tabs value={tab} variant="fullWidth">
                <Tab label={t("login", "Üye Girişi")} onClick={() => navigate("/login")} />
                <Tab label={t("registration", "Yeni Üyelik")} onClick={() => navigate("/register")} />
              </Tabs>
            </Grid>
            <Grid item>{!tab ? <LoginForm /> : <RegisterForm />}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginRegister;
