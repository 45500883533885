import {Grid, LinearProgress, MenuItem, TextField, Typography} from "@mui/material";
import {FormikErrors, useFormik} from "formik";
import {t} from "i18next";
import {Company} from "jack-shared";
import {useEffect} from "react";
import {object, string} from "yup";
import {state} from "..";
import {useJackTables} from "../utilities/hooks";
import {OrderRequirementFormType} from "../utilities/types";

export default function OrderRequirementsForm({company, onError, onUpdate}: {company: Company; onError?: (errors: FormikErrors<OrderRequirementFormType>) => void; onUpdate: ({name, selectedTable}: OrderRequirementFormType) => void}) {
  const {getTables, loadingTables, tables} = useJackTables();

  const {errors, handleChange, values} = useFormik<OrderRequirementFormType>({
    initialValues: {
      name: state.currentUser?.displayName || "",
      selectedTable: "",
    },
    onSubmit: (values) => console.log("🚀 ~ file: order-requirements-form.tsx:21 ~ OrderRequirementsForm ~ values:", values),
    validateOnMount: true,
    validationSchema: object().shape({
      name: string().required(t("requiredField", "Gerekli.")),
      selectedTable: string().required(t("requiredField", "Gerekli.")),
    }),
  });

  useEffect(() => {
    if (company?.id) {
      const _unsubscribe = getTables(company.id);
      console.count("getTables");

      return () => {
        if (!!_unsubscribe) {
          _unsubscribe();
          console.log("🚀 ~ file: order-requirements-form.tsx:21 ~ return ~ _unsubscribe:", _unsubscribe);
        }
      };
    }
  }, [company?.id, getTables]);

  useEffect(() => {
    onUpdate(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!!onError) {
      onError(errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">{t("orderDetails", "Sipariş Bilgileri")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField error={!!errors?.selectedTable} fullWidth helperText={errors?.selectedTable} id="selectedTable" label={t("selectTable", "Masa Seçin")} name="selectedTable" onChange={handleChange} select value={values.selectedTable}>
          {tables.map((table) => (
            <MenuItem key={table.id} value={table.name}>
              {table.name}
            </MenuItem>
          ))}
        </TextField>
        {loadingTables && <LinearProgress />}
      </Grid>
      <Grid item xs={12}>
        <TextField error={!!errors?.name} fullWidth helperText={errors?.name} id="name" label={t("nameForOrder", "Sipariş sahibinin ismi")} name="name" onChange={handleChange} value={values.name} />
      </Grid>
    </Grid>
  );
}
