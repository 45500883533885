import {Celebration, CheckCircle, Home} from "@mui/icons-material";
import {Alert, AlertTitle, Avatar, Breadcrumbs, Button, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, ListSubheader, Paper, Typography} from "@mui/material";
import {t} from "i18next";
import {Product} from "jack-shared";
import {useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useStyles from "../utilities/styles";

export default function Result() {
  let navigate = useNavigate();
  let location = useLocation();
  const classes = useStyles();
  const paymentResponse = useRef(location.state.paymentResponse);

  return (
    <>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate(location?.state?.company?.id ? `/menu/${location.state.company.id}` : "/dashboard")}>
            <Home />
          </IconButton>
          {location?.state?.company?.id && location?.state?.company?.shortName && (
            <Button onClick={() => navigate(`/menu/${location.state.company.id}`)}>
              {t("menu", "Menü")}: {location.state.company.shortName}
            </Button>
          )}
          <Typography>{t("transcationResult", "İşlem Sonucu")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} md={3.5}></Grid>
      <Grid item xs={12} md={5}>
        <Paper className={classes.paperContent}>
          <Grid container>
            <Grid item textAlign="center" xs={12}>
              <CheckCircle color="success" sx={{fontSize: 64}} />
            </Grid>
            <Grid item textAlign="center" xs={12}>
              <Typography marginBottom={1} variant="h5">
                {t("paymentSuccess", "Sipariş Kaydedildi")}
              </Typography>
              <Typography variant="body2">{t("gettingPrepared", "Sipariş verdiğiniz masanıza getirmek için hazırlanıyor.")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert icon={<Celebration />}>
                <AlertTitle>{t("youEarned", "Bu siparişiniz ile puan kazandınız.")}</AlertTitle>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="caption">{t("youEarnedDetails", "Her puan 1 TL anlamına geliyor. Sonraki siparişlerinizde bu puanları kullanabilirsiniz. Tek yapmanı gereken üye olmak.")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button color="success" fullWidth onClick={() => navigate("/register")} size="small" variant="contained">
                      {t("signUpAndSave", "Üye Ol ve Puanlarını Kaydet")}
                    </Button>
                  </Grid>
                </Grid>
              </Alert>
            </Grid>
            <Grid textAlign="left" item xs={12}>
              <ListSubheader disableGutters>
                {t("orderno", "Siparişiniz Numarası: ")} {paymentResponse.current?.paymentResult?.conversationId}
              </ListSubheader>
              {paymentResponse.current?.cart?.items.map((item: Product) => (
                <ListItem disableGutters key={item.id}>
                  {item.attachment && (
                    <ListItemAvatar>
                      <Avatar alt={item.name} src={item.attachment} variant="rounded" />
                    </ListItemAvatar>
                  )}
                  <ListItemText primary={item.name} secondary={new Intl.NumberFormat("tr-TR", {style: "currency", currency: item.currentPrice.currency}).format(item.currentPrice.amount)} />
                </ListItem>
              ))}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
