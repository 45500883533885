import { Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";

const UpdatePasswordForm = function ({ onSuccess }: { onSuccess: Function }) {
  const onSubmit = function (values: any, actions: any) {
    onSuccess({ actions, values });
  };

  const { errors, handleChange, handleSubmit, touched, values } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      new_password2: "",
    },
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid item xs={12} md={6}>
          <TextField error={!!touched.old_password && !!errors.old_password} fullWidth helperText={!!touched.old_password && errors?.old_password} id="old_password" label={t("old_password", "Mevcut Şifre")} name="old_password" onChange={handleChange} type="password" value={values.old_password} />
        </Grid>
        <Grid item>
          <TextField error={!!touched.new_password && !!errors.new_password} fullWidth helperText={!!touched.new_password && errors?.new_password} id="new_password" label={t("new_password", "Yeni Şifre")} name="new_password" onChange={handleChange} type="password" value={values.new_password} />
        </Grid>
        <Grid item>
          <TextField error={!!touched.new_password2 && !!errors.new_password2} fullWidth helperText={!!touched.new_password2 && errors?.new_password2} id="new_password2" label={t("new_password2", "Yeni Şifre (tekrar)")} name="new_password2" onChange={handleChange} type="password" value={values.new_password2} />
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" fullWidth type="submit">
            {t("submit_update_password", "Şifremi Yenile")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdatePasswordForm;
