import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Delete, Home } from "@mui/icons-material";
import { Breadcrumbs, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import CreditCardForm from "../forms/credit-card-form";

import theme from "../utilities/theme";

const PaymentMethods = function () {
  let navigate = useNavigate();

  return (
    <>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate("/dashboard")}>
            <Home />
          </IconButton>
          <Typography>{t("payment_methods", "Ödeme Yöntemleri")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item md={6}>
            <Grid container direction="column">
              <Grid item>
                <Card>
                  <CardHeader
                    action={
                      <IconButton disabled size="small">
                        <Delete />
                      </IconButton>
                    }
                    title={<Typography variant="subtitle2">{t("main_payment_method", "Ana Ödeme Aracı")}</Typography>}
                  />
                  <CardContent>
                    <Grid container>
                      <Grid item md={2} xs={4}>
                        <FontAwesomeIcon color={theme.palette.primary.main} icon={faCcVisa} size="3x" />
                      </Grid>
                      <Grid item md={10} xs={8}>
                        <Typography variant="subtitle1">**** 1234</Typography>
                        <Typography variant="body2">
                          {t("expiration_date", "Son Kullanma Tarihi:")} {t("test_card_expirateion_date", "12/24")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardHeader
                    action={
                      <IconButton size="small">
                        <Delete />
                      </IconButton>
                    }
                    title={<Typography variant="subtitle2">{t("secondary_payment_method", "İkincil Ödeme Aracı")}</Typography>}
                  />
                  <CardContent>
                    <Grid container>
                      <Grid item md={2} xs={4}>
                        <FontAwesomeIcon color={theme.palette.primary.main} icon={faCcMastercard} size="3x" />
                      </Grid>
                      <Grid item md={10} xs={8}>
                        <Typography variant="subtitle1">**** 9876</Typography>
                        <Typography variant="body2">
                          {t("expiration_date", "Son Kullanma Tarihi:")} {t("test_card_expirateion_date", "12/21")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button variant="outlined">{t("use_this_card", "Ana ödeme aracı yap")}</Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <CreditCardForm showAgreements={false} submitText={t("save_card", "Kart Bilgilerini Kaydet")} onSuccess={() => console.log("PaymentMethods return CreditCardForm onSuccess")} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentMethods;
