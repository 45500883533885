import { Alert, Grid, Typography } from "@mui/material";
import { useSnapshot } from "valtio";
import { state } from "..";

export default function Errors() {
  const snapshot = useSnapshot(state);

  return (
    <Grid container spacing={2}>
      {snapshot?.errors?.map((error, index) => (
        <Grid item key={`error_${index}`} xs={12}>
          <Alert color="error" onClose={() => (state.errors = state.errors.filter((e: string, i: number) => i !== index))}>
            <Typography>{error}</Typography>
          </Alert>
        </Grid>
      ))}
    </Grid>
  );
}
