import {Grid, Paper, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {Timestamp} from "firebase/firestore";
import {t} from "i18next";
import {Currency, Money, Product, ProductStatus, ProductVarianceType} from "jack-shared";
import {MouseEvent, useState} from "react";
import {useCart} from "react-use-cart";
import {state} from "..";
import useStyles from "../utilities/styles";
import {TipType} from "../utilities/types";

export default function AddTip() {
  const classes = useStyles();
  const [tipAmount, setTipAmount] = useState<number>(0);
  const cart = useCart();

  function onChange(event: MouseEvent<HTMLElement>, value: any) {
    try {
      if (!event?.currentTarget?.dataset?.tipType) throw new Error(t("noTipType", "Bahşiş tipi belirtilmemiş."));
      if (!event?.currentTarget?.dataset?.tipAmount) throw new Error(t("noTipAmount", "Bahşiş tutarı belirtilmemiş."));

      if (!value) cart.removeItem("tip");
      console.log("🚀 ~ file: add-tip.tsx:38 ~ onChange ~ value:", value);

      const _tipAmount = event.currentTarget.dataset.tipAmount;
      const _tipType = event.currentTarget.dataset.tipType;
      const _now = Timestamp.now().toDate();
      let _price: Money = {
        amount: 0,
        createdAt: _now,
        currency: Currency.TRY,
      };

      const _tipProduct: Product = {
        allowMultiplePurchase: false,
        allowedPointsUsagePercentage: 0,
        attachment: null,
        attachments: null,
        attributes: [],
        autoRenew: false,
        barcode: "",
        categoryIds: [],
        companyId: "",
        createdAt: _now,
        criticalStockCount: 0,
        currentPrice: _price,
        currentPriceRange: null,
        description: _tipType === TipType.percent ? t("tipPercent", "Yüzde") : t("tipConstatnt", "Sabit"),
        duration: null,
        expiresAt: null,
        gtip: null,
        hasStockMovements: false,
        id: "tip",
        initialStock: 0,
        inventoryTracking: false,
        isSaleable: false,
        isUsageConfirmationNeeded: false,
        listPrice: _price,
        listPriceRange: null,
        name: t("tip", "Bahşiş"),
        order: 0,
        paymentId: "",
        startsAt: _now,
        status: ProductStatus.available,
        stock: null,
        stockType: null,
        updatedAt: null,
        taxes: [],
        varianceType: ProductVarianceType.simple,
        variations: [],
      };

      switch (_tipType) {
        case TipType.constant:
          _tipProduct.currentPrice.amount = Number(_tipAmount);
          _tipProduct.listPrice.amount = Number(_tipAmount);
          break;

        case TipType.percent:
          _tipProduct.currentPrice.amount = (cart.cartTotal * Number(_tipAmount)) / 100;
          _tipProduct.listPrice.amount = (cart.cartTotal * Number(_tipAmount)) / 100;
          break;
      }

      if (_tipType === TipType.constant && cart.getItem("tip")?.price === _tipProduct.currentPrice.amount) {
        setTipAmount(0);
        cart.removeItem("tip");

        return;
      }

      console.log("🚀 ~ file: add-tip.tsx:79 ~ onChange ~ _tipProduct:", _tipProduct);
      cart.addItem({..._tipProduct, price: _tipProduct.listPrice.amount}, 1);
    } catch (error: any) {
      state.errors = [...state.errors, error?.message || JSON.stringify(error) || t("unknownErrorAddTipOnChange", "Bilinmeyen hata: unknownErrorAddTipOnChange")];
    }
  }

  if (process.env.NODE_ENV === "development") {
    (window as any).tipAmount = tipAmount;
  }

  return (
    <Paper className={classes.paperContent}>
      <Grid container>
        <Grid item xs={12}>
          <Typography>{t("addTip", "Bahşiş Ekleyin")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup color="primary" defaultValue={tipAmount} exclusive fullWidth onChange={onChange}>
            <ToggleButton data-tip-amount="10" data-tip-type={TipType.constant} selected={cart.getItem("tip")?.price === 10} value="constant_10">
              {t("tenPercent", "₺10")}
            </ToggleButton>
            <ToggleButton data-tip-amount="25" data-tip-type={TipType.constant} selected={cart.getItem("tip")?.price === 25} value="constant_25">
              {t("twentyFiveTL", "₺25")}
            </ToggleButton>
            <ToggleButton data-tip-amount="50" data-tip-type={TipType.constant} selected={cart.getItem("tip")?.price === 50} value="constant_50">
              {t("fiftyTL", "₺50")}
            </ToggleButton>
            <ToggleButton data-tip-amount="100" data-tip-type={TipType.constant} selected={cart.getItem("tip")?.price === 100} value="constant_100">
              {t("oneHhundredTL", "₺100")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Paper>
  );
}
