import {Close, Delete, Home} from "@mui/icons-material";
import {Alert, Breadcrumbs, Button, CircularProgress, Divider, Grid, IconButton, Paper, TextField, Typography} from "@mui/material";
import {FormikErrors} from "formik";
import {t} from "i18next";
import {Cart as CartModel} from "jack-shared";
import {Fragment, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useCart} from "react-use-cart";
import AddTip from "../components/add-tip";
import Money from "../components/money";
import CreditCardForm from "../forms/credit-card-form";
import OrderRequirementsForm from "../forms/order-requirements-form";
import {useMetretulOrders} from "../utilities/hooks";
import useStyles from "../utilities/styles";
import {OrderRequirementFormType} from "../utilities/types";

const Cart = function () {
  const classes = useStyles();
  const cart = useCart();
  const {loadingOrder, createOrder} = useMetretulOrders();
  const [errors, setErrors] = useState<any[]>([]);
  let navigate = useNavigate();
  let location = useLocation();
  const [requiredFieldErrors, setRequiredFieldErrors] = useState<FormikErrors<OrderRequirementFormType>>({name: "", selectedTable: ""});

  const continuesWithNewOrder = async function () {
    try {
      const _cart: CartModel = {
        isEmpty: false,
        cartTotal: cart.cartTotal,
        items: cart.items,
        metadata: cart.metadata as any,
        totalItems: cart.totalItems,
        totalUniqueItems: cart.totalUniqueItems,
      };

      console.log("🚀 ~ file: cart.tsx ~ line 56 ~ _cart", _cart);
      const _result = await createOrder(_cart);
      console.log("🚀 ~ file: cart.tsx ~ line 60 ~ continuesWithNewOrder ~ _result", _result);

      const _emptyCart = await new Promise((resolve) => {
        const _cartEmptied = cart.emptyCart();

        resolve(_cartEmptied);
      });
      console.log("🚀 ~ file: cart.tsx:75 ~ const_emptyCart=awaitnewPromise ~ _emptyCart:", _emptyCart);

      navigate("/dashboard");
    } catch (error) {
      console.error(`cart.tsx onSuccess error: ${(error as any)?.message || error || t("unknownError", "Bilinmenyen hata oluştu.")}`);
    }
  };

  const onSuccess = async function (paymentResponse: any) {
    try {
      console.log("🚀 ~ file: cart.tsx:63 ~ onSuccess ~ paymentResponse", paymentResponse);

      if (paymentResponse?.data?.paymentStatus === "success") {
        const _emptyCart = await new Promise((resolve) => {
          const _cartEmptied = cart.emptyCart();

          resolve(_cartEmptied);
        });
        console.log("🚀 ~ file: cart.tsx:75 ~ const_emptyCart=awaitnewPromise ~ _emptyCart:", _emptyCart);

        navigate("/result", {state: {paymentResponse: paymentResponse?.data}});
        return;
      }

      throw new Error(`${paymentResponse?.data?.paymentResult?.errorMessage} (${t("errorCode", "Hata kodu")}: ${paymentResponse?.data?.paymentResult?.errorCode})`);
    } catch (error) {
      setErrors((e) => [...e, error]);
      console.error(`cart.tsx onSuccess error: ${(error as any)?.message || error || t("unknownError", "Bilinmenyen hata oluştu.")}`);
    }
  };

  if (process.env.NODE_ENV === "development") {
    (window as any).cart = cart;
    (window as any).errors = errors;
    (window as any).location2 = location;
  }

  return (
    <>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate(location?.state?.company?.id ? `/menu/${location.state.company.id}` : "/dashboard")}>
            <Home />
          </IconButton>
          {location?.state?.company?.id && location?.state?.company?.shortName && (
            <Button onClick={() => navigate(`/menu/${location.state.company.id}`)}>
              {t("menu", "Menü")}: {location.state.company.shortName}
            </Button>
          )}
          <Typography>{t("cart", "Sepet")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {errors.map((error, i) => (
            <Grid item key={`Error_${i}`} xs={12}>
              <Alert
                action={
                  <IconButton size="small" onClick={() => setErrors((errs) => errs.filter((e, k) => i !== k))}>
                    <Close fontSize="inherit" />
                  </IconButton>
                }
                severity={error?.paymentStatus === "success" ? "success" : "error"}
              >
                {error?.message || t("errorOccured", "İşlem Sonucu")}
              </Alert>
            </Grid>
          ))}
          <Grid item xs={12} md={!!cart.items.length && !!cart.cartTotal ? 8 : 12}>
            <Grid container direction="column">
              <Grid item>
                <Paper className={classes.paperContent}>
                  <Grid container>
                    <Grid item xs={10} md={5}>
                      <Typography variant="subtitle2">{t("product_service", "Ürün / Hizmet")}</Typography>
                    </Grid>
                    <Grid item textAlign="right" xs={2}>
                      <Typography variant="subtitle2">{t("quantity", "Adet")}</Typography>
                    </Grid>
                    <Grid item textAlign="right" sx={{display: {xs: "none", sm: "block"}}} md={2}>
                      <Typography variant="subtitle2">{t("price_per_item", "Birim Fiyat")}</Typography>
                    </Grid>
                    <Grid item textAlign="right" sx={{display: {xs: "none", sm: "block"}}} md={2}>
                      <Typography variant="subtitle2">{t("total_price", "Toplam Fiyat")}</Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {cart.items.length ? (
                      cart.items.map((item, index) => (
                        <Fragment key={`${item.id}_${index}`}>
                          <Grid item xs={10} md={5}>
                            <Typography variant="subtitle2">{item.name}</Typography>
                            <Typography variant="body2">{item.description}</Typography>
                          </Grid>
                          <Grid item textAlign="right" xs={2} md={2}>
                            {item.price && item.allowMultiplePurchase ? <TextField onChange={(event) => cart.updateItemQuantity(item.id, Number(event?.target.value))} size="small" type="number" value={item.quantity} /> : <Typography>1</Typography>}
                          </Grid>
                          <Grid item textAlign="right" xs={5} md={2}>
                            <Typography>
                              <Money price={item.price} />
                            </Typography>
                          </Grid>
                          <Grid item textAlign="right" xs={5} md={2}>
                            <Typography>
                              <Money price={item.itemTotal} />
                            </Typography>
                          </Grid>
                          <Grid item xs={2} md={1}>
                            <IconButton onClick={() => cart.removeItem(item.id)}>
                              <Delete />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Fragment>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        {t("empty_cart", "Sepete eklenmiş ürün ya da hizmet bulunmuyor.")}
                      </Grid>
                    )}
                    {!!cart.items.length && (
                      <>
                        {/* <Grid item xs={5}></Grid>
                        <Grid item textAlign="right" xs={4}>
                          <Typography variant="subtitle2">{t("subtotal", "Ara toplam")}</Typography>
                        </Grid>
                        <Grid item textAlign="right" xs={2}>
                          <Money price={cart.cartTotal - cart.items.reduce((a, b) => a + b.tax, 0)} />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}></Grid>
                        <Grid item textAlign="right" xs={4}>
                          <Typography variant="subtitle2">{t("vat", "KDV")}</Typography>
                        </Grid>
                        <Grid item textAlign="right" xs={2}>
                          <Money price={cart.items.reduce((a, b) => a + b.tax, 0)} />
                        </Grid>
                        <Grid item xs={1}></Grid> */}
                        <Grid item xs={5}></Grid>
                        <Grid item textAlign="right" xs={4}>
                          <Typography variant="subtitle2">{t("grand_totar", "Ödenecek Toplam")}</Typography>
                        </Grid>
                        <Grid item textAlign="right" xs={2}>
                          <Money price={cart.cartTotal} />
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </>
                    )}
                  </Grid>
                </Paper>
              </Grid>
              {!!cart.items.length && !cart.cartTotal && (
                <Grid item textAlign="right">
                  <Button disabled={loadingOrder} onClick={continuesWithNewOrder} size="large" variant="contained">
                    {loadingOrder ? <CircularProgress /> : t("continue", "Devam Et")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {!!cart.items.length && !!cart.cartTotal && (
            <Grid item xs={12} md={4}>
              <Grid container>
                {location?.state?.company && (
                  <Grid item xs={12}>
                    <Paper className={classes.paperContent}>
                      <OrderRequirementsForm company={location.state.company} onError={(errors) => setRequiredFieldErrors(errors)} onUpdate={(orderInfo) => cart.updateCartMetadata({...cart.metadata, ...orderInfo})} />
                    </Paper>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <AddTip />
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paperContent}>
                    <CreditCardForm cart={cart} company={location?.state?.company} onSuccess={onSuccess} parentErrors={requiredFieldErrors} showAgreements={false} submitText={t("completeOrder", "Siparişi Tamamla")} />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Cart;
