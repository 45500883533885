import { Button, CircularProgress, Grid, Paper, TextField } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { t } from "i18next";
import { useAtom } from "jotai";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import * as yup from "yup";
import { state } from "..";
import TestDataSelector from "../components/test-data-selector";
import { isUsingTestDataAtom, testDataAtom, testDataIdsAtom } from "../utilities/atoms";
import { useMetretulAuthentication } from "../utilities/hooks";
import useStyles from "../utilities/styles";
import { LoginFormType } from "../utilities/types";

const LoginForm = function () {
  const classes = useStyles();
  const [testData] = useAtom(testDataAtom);
  const [testDataIds] = useAtom(testDataIdsAtom);
  const [isUsingTestData] = useAtom(isUsingTestDataAtom);
  const { loadingAuthentication, signInUser } = useMetretulAuthentication();
  const { currentUser, role } = useSnapshot(state);

  let navigate = useNavigate();
  let location = useLocation();

  const _path = `${(location as any)?.state?.from?.pathname || "/dashboard"}`;

  const onSubmit = async function (values: LoginFormType, actions: FormikHelpers<LoginFormType>) {
    const { email, password } = values;

    const _userCredential = await signInUser(email, password);
    console.log("🚀 ~ file: login-register.tsx ~ line 27 ~ _userCredential", _userCredential);
  };

  const validationSchema = yup.object({
    email: yup.string().email(t("validation_format_email", "E-posta adresinizi kontrol edin.")).required(t("validation_required_email", "E-posta adresinizi girin.")),
    password: yup.string().min(6, t("validation_min_password", "Şifreniz en az 6 karakterden oluşmalı.")).required(t("validation_required_password", "Şifrenizi girin.")),
  });

  const { errors, handleChange, handleSubmit, isSubmitting, touched, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: isUsingTestData ? testData.users[testDataIds.users].email : "",
      password: isUsingTestData ? process.env?.REACT_APP_TEST_USERS_PASSWORD || "" : "",
    },
    validationSchema,
    onSubmit,
  });

  if (!!currentUser && !!role) return <Navigate to={_path} />;

  return (
    <Paper className={classes.paperContent} component="form" onSubmit={handleSubmit}>
      <Grid container direction="column">
        {process.env.NODE_ENV === "development" && (
          <Grid item>
            <TestDataSelector id="users" />
          </Grid>
        )}
        <Grid item>
          <TextField disabled={isSubmitting} error={!!touched.email && !!errors.email} fullWidth helperText={!!touched.email && errors?.email} id="email" label={t("email", "E-posta")} name="email" onChange={handleChange} value={values.email} />
        </Grid>
        <Grid item>
          <TextField disabled={isSubmitting} error={!!touched.password && !!errors.password} fullWidth helperText={!!touched.password && errors?.password} id="password" label={t("password", "Şifre")} name="password" onChange={handleChange} type="password" value={values.password} />
        </Grid>
        <Grid item>
          <Button color="primary" disabled={isSubmitting} variant="contained" fullWidth type="submit">
            {t("submit_login", "Üye Girişi Yap")}
          </Button>
        </Grid>
        <Grid display="flex" justifyContent="flex-end" item>
          <Button disabled={loadingAuthentication} onClick={() => navigate("/forgot-password")} size="small" variant="text">
            {loadingAuthentication ? <CircularProgress size={23} /> : t("forgot_password", "Şifremi Unuttum")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoginForm;
