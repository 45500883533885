import {Grid, Paper} from "@mui/material";
import {t} from "i18next";

import useStyles from "../utilities/styles";

const Home = function () {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.paperContent}>{t("metretul_nedir", "Jack Nedir?")}</Paper>
      </Grid>
    </>
  );
};

export default Home;
