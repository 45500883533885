import {DocumentSnapshot} from "firebase/firestore";
import {Company, CompanySetting, Invoice, Message, Notification, Points, Product, ProductVariation, Project, ProjectDeliverables, Subscription, Table} from "jack-shared";
import moment from "moment";

export const companySettingsConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _companySetting: CompanySetting = {} as CompanySetting;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      _companySetting = {
        companyId: _data.companyId,
        createdAt: _data.createdAt,
        description: _data.description,
        id: _data.id,
        name: _data.name,
        updatedAt: _data.updatedAt,
        status: _data.status,
        type: _data.type,
        value: _data.value,
      };
    }

    return _companySetting;
  },
};

export const fromFirestoreCompanyConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _company: Company = {
      address: "",
      administratorIds: [],
      approvalStatus: "approved",
      approvedAt: new Date(),
      approvedById: null,
      branchIds: [],
      categoryIds: [],
      city: "",
      contactEmail: "",
      contactPhone: "",
      country: "",
      createdAt: new Date(),
      createdByMemberId: "",
      district: "",
      employeeIds: [],
      googlePlacesId: null,
      googlePlacesData: null,
      id: "",
      invitedBy: null,
      kep: "",
      legalName: "",
      rating: 0,
      shortName: null,
      updatedAt: null,
    };

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      _company = {
        address: _data.address,
        administratorIds: _data.administratorIds,
        approvalStatus: _data.approvalStatus,
        approvedAt: _data.approvedAt,
        approvedById: _data.approvedById,
        branchIds: _data.branchIds,
        categoryIds: _data.categoryIds,
        city: _data.city,
        contactEmail: _data.contactEmail,
        contactPhone: _data.contactPhone,
        country: _data.country,
        createdAt: _data.createdAt,
        createdByMemberId: _data.createdByMemberId,
        district: _data.district,
        employeeIds: _data.employeeIds,
        googlePlacesId: _data.googlePlacesId,
        googlePlacesData: _data.googlePlacesData,
        id: fromFirestoreSnapshot.id,
        invitedBy: _data.invitedBy,
        kep: _data.kep,
        legalName: _data.legalName,
        rating: _data.rating,
        shortName: _data.shortName,
        updatedAt: _data.updatedAt,
      };
    }

    return _company;
  },
};

export const fromFirestoreInvoiceConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _invoice: Invoice = {} as Invoice;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      _invoice = {
        billingAddress: _data.billingAddress,
        createdAt: _data.createdAt,
        createdByMemberId: _data.createdByMemberId,
        company: _data.company,
        companyId: _data.companyId,
        currency: _data.currency,
        description: _data.description,
        dueDate: _data.dueDate,
        expiresAt: _data.expiresAt,
        grandTotal: _data.grandTotal,
        id: _data.id,
        invoiceDiscount: _data.invoiceDiscount,
        invoiceSeries: _data.invoiceSeries,
        items: _data.items,
        projectDeliverables: _data.projectDeliverables,
        projectCity: _data.projectCity,
        projectId: _data.projectId,
        projectName: _data.projectName,
        projectOwnerCompany: _data.projectOwnerCompany,
        revisionOfQuoteId: _data.revisionOfQuoteId,
        paymentStatus: _data.paymentStatus,
        shipmentAddress: _data.shipmentAddress,
        shipmentIncluded: _data.shipmentIncluded,
        status: _data.status,
        type: _data.type,
      };
    }

    return _invoice;
  },
};

export const fromFirestoreProductConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      const _product: Product = {
        allowMultiplePurchase: _data.allowMultiplePurchase,
        allowedPointsUsagePercentage: _data.allowedPointsUsagePercentage,
        attachment: _data.attachment,
        attachments: _data.attachments,
        attributes: _data.attributes,
        autoRenew: _data.autoRenew,
        barcode: _data.barcode,
        categoryIds: _data.categoryIds,
        companyId: _data.companyId,
        createdAt: _data.createdAt,
        criticalStockCount: _data.criticalStockCount,
        currentPrice: _data.currentPrice,
        currentPriceRange: _data.currentPriceRange,
        description: _data.description,
        duration: _data.duration,
        expiresAt: _data.expiresAt,
        gtip: _data.gtip,
        hasStockMovements: _data.hasStockMovements,
        id: fromFirestoreSnapshot.id,
        initialStock: _data.initialStock,
        inventoryTracking: _data.inventoryTracking,
        isSaleable: _data.isSaleable,
        isUsageConfirmationNeeded: _data.isUsageConfirmationNeeded,
        listPrice: _data.listPrice,
        listPriceRange: _data.listPriceRange,
        name: _data.name,
        order: _data.order,
        paymentId: _data.paymentId,
        startsAt: _data.startsAt,
        status: _data.status,
        stock: _data.stock,
        stockType: _data.stockType,
        updatedAt: _data.updatedAt,
        taxes: _data.taxes,
        varianceType: _data.varianceType,
        variations: _data.variations,
      };

      return _product;
    }
  },
};

export const messageConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _message: Message = {} as Message;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      _message = {
        attachments: _data.attachments,
        channelId: _data.channelId,
        createdAt: _data.createdAt,
        id: fromFirestoreSnapshot.id,
        message: _data.message,
        recieverCompany: _data.recieverCompany,
        recieverMemberId: _data.recieverMemberId,
        recieverMemberRef: _data.recieverMemberRef,
        relatedProjectCity: _data.relatedProjectCity,
        relatedProjectId: _data.relatedProjectId,
        relatedProjectName: _data.relatedProjectName,
        relatedProjectRef: _data.relatedProjectRef,
        repliedMessageId: _data.repliedMessageId,
        senderCompanyId: _data.senderCompanyId,
        senderCompanyName: _data.senderCompanyName,
        senderMemberId: _data.senderMemberId,
        senderMemberName: _data.senderMemberName,
        senderMemberRef: _data.senderMemberRef,
        status: _data.status,
        subject: _data.subject,
        type: _data.type,
      };
    }

    return _message;
  },
};

export const notificationConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _notification: Notification = {} as Notification;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      _notification = {
        createdAt: moment.unix(_data.createdAt.seconds).toDate(),
        id: fromFirestoreSnapshot.id,
        memberId: _data.memberId,
        message: _data.message,
        status: _data.status,
        subject: _data.subject,
        url: _data.url,
      };
    }

    return _notification;
  },
};

export const pointsConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _points: Points = {} as Points;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      _points = {
        companyId: _data.companyId,
        createdAt: _data.createdAt.toDate(),
        expiresAt: _data.expiresAt.toDate(),
        memberId: _data.memberId,
        sourceId: _data.sourceId,
        sourceType: _data.sourceType,
        status: _data.status,
        type: _data.type,
        used: _data.used,
        value: _data.value,
      };
    }

    return _points;
  },
};

export const productConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _product = {} as Product;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();

      _product = {
        allowMultiplePurchase: _data.allowMultiplePurchase,
        allowedPointsUsagePercentage: _data.allowedPointsUsagePercentage,
        attachment: _data.attachment,
        attachments: _data.attachments,
        attributes: _data.attributes,
        autoRenew: _data.autoRenew,
        barcode: _data.barcode,
        categoryIds: _data.categoryIds,
        companyId: _data.companyId,
        createdAt: _data.createdAt,
        criticalStockCount: _data.criticalStockCount,
        currentPrice: _data.currentPrice,
        currentPriceRange: _data.currentPriceRange,
        description: _data.description,
        duration: _data.duration,
        expiresAt: _data.expiresAt,
        gtip: _data.gtip,
        hasStockMovements: _data.hasStockMovements,
        id: fromFirestoreSnapshot.id,
        initialStock: _data.initialStock,
        inventoryTracking: _data.inventoryTracking,
        isSaleable: _data.isSaleable,
        isUsageConfirmationNeeded: _data.isUsageConfirmationNeeded,
        listPrice: _data.listPrice,
        listPriceRange: _data.listPriceRange,
        name: _data.name,
        order: _data.order,
        paymentId: _data.paymentId,
        startsAt: _data.startsAt,
        status: _data.status,
        stock: _data.stock,
        stockType: _data.stockType,
        updatedAt: _data.updatedAt,
        taxes: _data.taxes,
        varianceType: _data.varianceType,
        variations: _data.variations,
      };
    }

    return _product;
  },
};

export const productVariationConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _productVariation: ProductVariation = {} as ProductVariation;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();

      _productVariation = {
        includedAttributeValues: _data.includedAttributeValues,
        variationOfProductId: _data.variationOfProductId,
        varianceType: _data.varianceType,
        allowMultiplePurchase: _data.allowMultiplePurchase,
        allowedPointsUsagePercentage: _data.allowedPointsUsagePercentage,
        attachment: _data.attachment,
        attachments: _data.attachments,
        attributes: _data.attributes,
        autoRenew: _data.autoRenew,
        barcode: _data.barcode,
        categoryIds: _data.categoryIds,
        companyId: _data.companyId,
        createdAt: _data.createdAt,
        criticalStockCount: _data.criticalStockCount,
        currentPrice: _data.currentPrice,
        currentPriceRange: _data.currentPriceRange,
        description: _data.description,
        duration: _data.duration,
        expiresAt: _data.expiresAt,
        gtip: _data.gtip,
        hasStockMovements: _data.hasStockMovements,
        id: _data.id,
        initialStock: _data.initialStock,
        inventoryTracking: _data.inventoryTracking,
        isSaleable: _data.isSaleable,
        isUsageConfirmationNeeded: _data.isUsageConfirmationNeeded,
        listPrice: _data.listPrice,
        listPriceRange: _data.listPriceRange,
        name: _data.name,
        order: _data.order,
        paymentId: _data.paymentId,
        startsAt: _data.startsAt,
        status: _data.status,
        stock: _data.stock,
        stockType: _data.stockType,
        updatedAt: _data.updatedAt,
        taxes: _data.taxes,
        variations: _data.variations,
      };
    }

    return _productVariation;
  },
};

export const projectConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _project: Project = {} as Project;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      _project = {
        activities: _data.activities,
        address: _data.address,
        awaitingQuotationFromCompanyIds: _data.awaitingQuotationFromCompanyIds,
        availableForMeasurementAt: _data.availableForMeasurementAt,
        categoryIds: _data.categoryIds,
        challenges: _data.challenges,
        contractorCompanyId: _data.contractorCompanyId,
        createdAt: _data.createdAt,
        createdByMemberId: _data.createdByMemberId,
        endsAt: _data.endsAt,
        expiresAt: _data.expiresAt.toDate(),
        id: fromFirestoreSnapshot.id,
        favoritedCompanies: _data.favoritedCompanies,
        name: _data.name,
        notes: _data.notes,
        messages: _data.messages,
        offerId: _data.offerId,
        ownerCompany: _data.ownerCompany,
        quotationCompanyDocumentReference: _data.quotationCompanyDocumentReference,
        quotationId: _data.quotationId,
        quotationMemberDocumentReference: _data.quotationMemberDocumentReference,
        quotationIds: _data.quotationIds,
        paymentMethod: _data.paymentMethod,
        projectDeliverables: _data.projectDeliverables,
        projectScope: _data.projectScope,
        revisionOfProjectId: _data.revisionOfProjectId,
        revisionOfProjectReference: _data.revisionOfProjectReference,
        reviews: _data.reviews,
        status: _data.status,
        subProjectIds: _data.subProjectIds,
        transactions: _data.transactions,
      };
    }

    return _project;
  },
};

export const projectTemplateConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _projectDeliverables = {} as ProjectDeliverables;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      console.log("🚀 ~ file: converters.ts ~ line 267 ~ _data", _data);

      _projectDeliverables = {
        deliverables: _data.deliverables,
        description: _data.description,
        title: _data.title,
        total: _data.total,
        createdAt: _data.createdAt.toDate(),
        createdBy: _data.createdBy,
        id: _data.id,
        updatedAt: !_data?.updatedAt ? null : _data.updatedAt.toDate(),
        updatedBy: _data.updatedBy,
        status: _data.status,
      };
    }

    return _projectDeliverables;
  },
};

export const subscriptionConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _subscription: Subscription = {} as Subscription;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      _subscription = {
        createdAt: _data.createdAt.toDate(),
        expiresAt: _data.expiresAt.toDate(),
        memberId: _data.memberId,
        order: _data.order,
        orderId: _data.orderId,
        role: _data.role,
        status: _data.status,
      };
    }

    return _subscription;
  },
};

export const tableConverter = {
  toFirestore: (a: any) => a,
  fromFirestore: (fromFirestoreSnapshot: DocumentSnapshot) => {
    let _table: Table = {} as Table;

    if (fromFirestoreSnapshot.exists()) {
      const _data = fromFirestoreSnapshot.data();
      _table = {
        companyId: _data.companyId,
        description: _data.description,
        id: _data.id,
        name: _data.name,
      };
    }

    return _table;
  },
};
