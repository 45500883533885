import {Home} from "@mui/icons-material";
import {Breadcrumbs, Grid, IconButton, Typography} from "@mui/material";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import {useSnapshot} from "valtio";
import {state} from "..";
import Companies from "../components/companies";
import MemberPoints from "../components/member-points";
import QRMenuCompanies from "../components/qrMenuCompanies";
import Subscription from "../components/subscription";
import {DashboardScreenPropsType} from "../utilities/types";

const Dashboard = function ({companies, isLoadingCompanies}: DashboardScreenPropsType) {
  const {currentUser: member, role} = useSnapshot(state);
  const snapshot = useSnapshot(state);

  let navigate = useNavigate();

  return !member || !role ? null : (
    <>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate("/dashboard")}>
            <Home />
          </IconButton>
          <Typography>{t("jack", "Jack")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={6}>
            {!!member && (
              <Grid container direction="column">
                {role.privilegeLevel <= 400 && (
                  <Grid item>
                    <Subscription />
                  </Grid>
                )}
                {role.privilegeLevel <= 400 && (
                  <Grid item>
                    <Companies companies={companies} isLoadingCompanies={isLoadingCompanies} />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <MemberPoints memberId={snapshot.currentUser?.uid || ""} />
              </Grid>
              <Grid item xs={12}>
                <QRMenuCompanies />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
