import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formLoginRegisterContainer: {
      maxWidth: theme?.breakpoints?.values?.sm || 600,
    },
    gridSticky: {
      position: "sticky",
    },
    iconPaymenSuccess: {
      fontSize: 72,
    },
    listLeftMenu: {
      width: 240,
    },
    paperContent: {
      borderRadius: 12,
      padding: theme.spacing(2),
    },
    paperDataGridContainer: {
      height: 500,
      width: "100%",
    },
    paperFullHeight: {
      height: "100%",
    },
    span100: {
      width: "100%",
    },
    typographyCaptionDeliveryCompleted: {
      display: "block",
      lineHeight: 1.4,
      marginTop: 10,
    },
    typographyRadioLabel: {
      lineHeight: 1,
    },
    typographyBody1ProductsTable: {
      marginBottom: 5,
    },
  })
);

export default useStyles;
