import {User} from "firebase/auth";
import {DocumentData, Query} from "firebase/firestore";
import {FormikErrors} from "formik";
import {Company, Invoice, Product, ProductAttribute, ProductCategoryName, ProductVarianceType, ProductVariation, Project, ProjectStatusName, Subscription} from "jack-shared";
import {ReactNode} from "react";

export type atomInitialDataType = {
  errors: (Error | string | null)[];
  isUsingTestData: boolean;
  testData: any;
  testDataIds: atomInitialDataTestDataIdsType;
};

export type atomInitialDataTestDataIdsType = {
  addresses: string;
  companies: string;
  cards: string;
  products: string;
  projects: string;
  users: string;
};

export type CompaniesScreenPropTypes = {
  companies: (Company | DocumentData)[] | null;
  isLoadingCompanies: boolean;
};

export type createUserPropsType = {
  email: string;
  name: string;
  phoneNumber: string;
  password: string;
};

export type DashboardScreenPropsType = {
  availableCompanyCategories: string[];
  cities: string[];
  companies: Company[];
  companiesWithIds: any | null;
  isLoadingCompanies: boolean;
  message: string;
};

export type ErrorStateType = {
  helperText: string;
  state: boolean;
};

export type LoginFormType = {
  email: string;
  password: string;
};

export type MenuDrawerComponentPropsType = {
  availableCompanyCategories: string[];
  companies: (Company | DocumentData)[];
  message: string;
};

export type MessagesPropsType = {
  company: Company;
  invoice: Invoice;
  project: Project;
};

export type OrderRequirementFormType = {
  name: string;
  selectedTable: string;
};

export type ProductFormInitialValuesType = {
  attachment: string;
  attributes: ProductAttribute[];
  categoryId: ProductCategoryName;
  description: string;
  listPrice: string | number;
  name: string;
  variance: ProductVarianceType;
};

export type ProductFormPropsType = {
  companyId: string;
  product: Product | null;
};

export type ProductVariantsFormInitialValuesType = {
  [key: string]: string;
};

export type ProductVariantsFormPropsType = {
  product: Product;
  productVariations: ProductVariation[];
};

export type ProjectFormInitialValuesType = {
  application: string;
  applicationDetails: string;
  backSpaceCoverage: string;
  backSpaceCoverageOptions: string;
  attachments: string[];
  city: string;
  notes: string;
  paymentMethod: string;
  productCategoryId: string;
  productId: string;
  projectName: string;
};

export type ProjectFormComponentPropsType = {
  fileList?: Function;
  onSuccess: Function;
};

export type ProjectsByCitiesPropsType = {
  cities: string[];
  companies: Company[];
  showClosed?: boolean;
};

export type ProjectsByCompaniesPropsType = {
  companies: Company[];
  projectStatusName: ProjectStatusName;
  roleId?: string;
};

export type ProjectsComponentPropsType = {
  companies: (Company | DocumentData)[] | null;
  companiesWithIds: string[];
  memberId: string | null;
  message: string;
  subscription?: Subscription | DocumentData | null;
};

export type ProjectTab = {
  disabled: boolean;
  id: string;
  index: number;
  label: string;
};

export type QuoteFormInitialValuesType = {
  [key: string]: {
    amount: number;
    label: string;
    price: number;
    vatRate: number;
    quantity: number;
  };
};

export type QuoteFormPropsType = {
  callback?: (result: any) => void;
  company: Company;
  invoice: Invoice | null;
  member: User;
  project: Project;
};

export type QuoteWritePropsType = {
  companies: Company[];
};

export type RegisterFormType = {
  agreement: boolean;
  cookie: boolean;
  email: string;
  name: string;
  password: string;
  password2: string;
  phoneNumber: string;
};

export type SelectColorComponentPropsType = {
  error: boolean;
  helperText: ReactNode;
  id?: string;
  label: ReactNode;
  name: string;
  onChange: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<ProjectFormInitialValuesType>>;
  options: {imageUrl: string; text: string; value: string}[];
  value: string;
};

export type SendMessageFormInitialValuesType = {
  message: string;
};

export type SendMessageFormPropsType = {
  project: Project;
};

export type SubscriptionComponentPropsType = {
  subscription: Subscription | null;
};

export enum TipType {
  constant = "constant",
  custom = "custom",
  percent = "percent",
}

export type useMetretulProductsUploadFilesParamsType = {
  file: File;
  path: string;
};

export type useMetretulCompaniesPropsType = {customQuery?: Query | null};

export type useMetretulProjectsPropsType = {
  customQuery: Query | null;
};
