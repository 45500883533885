import {EmailAuthProvider, getAuth, linkWithCredential, updateProfile, User} from "firebase/auth";
import {addDoc, collection, getFirestore, Timestamp} from "firebase/firestore";
import {getFunctions, httpsCallable} from "firebase/functions";
import {t} from "i18next";
import {Cart, Company, IyzicoPaymentCard, Order, Role} from "jack-shared";

import {defaults, model} from "jack-shared/assets/constants.json";
import {firebaseApp, state} from "..";

export const createCompany = async function (data: any, googlePlacesData: any, member: User | null, useAdditionalFields = false) {
  try {
    const _time = Timestamp.now();

    const _company: Company = {
      ...(useAdditionalFields
        ? {
            additionalFields: {
              employeeNumbers: data.experiencedYearsOnField,
              experiencedYearsOnField: data.experiencedYearsOnField,
            },
          }
        : {}),
      address: data.company_address,
      administratorIds: member?.uid ? [member.uid] : [],
      approvalStatus: model.company.status.pending as "pending",
      approvedAt: _time.toDate(),
      approvedById: null,
      branchIds: [],
      categoryIds: [data.company_area],
      city: data.company_address_city,
      contactEmail: data.kep,
      contactPhone: data.company_phone,
      country: data.country || defaults.addresses.country,
      createdAt: _time.toDate(),
      createdByMemberId: member?.uid ?? "",
      district: data.company_address_district,
      employeeIds: [],
      googlePlacesId: googlePlacesData?.place_id || null,
      googlePlacesData: googlePlacesData || null,
      kep: data.kep,
      id: googlePlacesData?.place_id || "",
      invitedBy: null,
      legalName: data.company_name,
      shortName: data.company_name,
      rating: -1,
      updatedAt: _time.toDate(),
    };
    console.log("🚀 ~ file: remote.ts ~ line 67 ~ createCompany ~ _company", _company);

    const _companyDocumentReference = await addDoc(collection(getFirestore(), "Companies"), _company);
    console.log("🚀 ~ file: remote.ts ~ line 38 ~ createCompany ~ _companyDocumentReference", _companyDocumentReference);

    return _companyDocumentReference;
  } catch (error) {
    throw error;
  }
};

export const createOrderRemote = async function (cart: Cart) {
  try {
    if (!cart) throw new Error("Sepet tanımlı olmadığı için sipariş oluşturulmadı.");

    const _order = await httpsCallable<Cart, Order>(getFunctions(firebaseApp, `${process.env.REACT_APP_CLOUD_FUNCTONS_REGION}`), "callables-createOrderCallable")(cart);

    console.log("🚀 ~ file: remote.ts ~ line 64 ~ createOrderRemote ~ cart", cart);
    console.log("🚀 ~ file: remote.ts ~ line 64 ~ createOrderRemote ~ _order", _order);

    return _order;
  } catch (error) {
    console.error(error);
  }
};

export const createMember = async function ({displayName, email, password, phoneNumber}: {displayName: string; email: string; password: string; phoneNumber: string}) {
  try {
    const _user = getAuth().currentUser;
    console.log("🚀 ~ file: remote.ts:77 ~ createMember ~ _user:", _user);

    // const _result: any = await httpsCallable(getFunctions(firebaseApp, `${process.env.REACT_APP_CLOUD_FUNCTONS_REGION}`), "callables-createUser")({displayName, email, password, phoneNumber});
    // console.log("🚀 ~ file: remote.ts ~ line 74 ~ createMember ~ _result", _result);

    if (!!_user) {
      const _credential = EmailAuthProvider.credential(email, password);
      const _userResult = (await linkWithCredential(_user, _credential)).user;

      if (!!displayName) {
        const _userProfileResult = await updateProfile(_user, {displayName});
        console.log("🚀 ~ file: remote.ts:93 ~ createMember ~ _userProfileResult:", _userProfileResult);
      }

      if (!!phoneNumber) {
      }

      const _idTokenResult = await _userResult.getIdTokenResult(true);
      console.log("🚀 ~ file: remote.ts:86 ~ createMember ~ _idTokenResult:", _idTokenResult);

      if (_idTokenResult?.claims?.role) {
        state.role = _idTokenResult?.claims?.role as Role;
      }

      const _currentUser = getAuth().currentUser;
      console.log("🚀 ~ file: remote.ts:102 ~ createMember ~ _currentUser", _currentUser);

      state.currentUser = _currentUser?.toJSON() as User;

      return _currentUser;
    }

    // return _result;
  } catch (error) {
    throw error;
  }
};

export const getQuotes = function (id?: string) {
  return new Promise(function (resolve, reject) {
    try {
      setTimeout(function () {
        if (id) {
          resolve("test");
          return;
        }
        resolve("test2");
      }, 2000);
    } catch (error) {
      reject(error);
    }
  });
};

export const makePaymentRemote = async function (cart: Cart, creditCard: IyzicoPaymentCard, ip: string, user: User) {
  try {
    const _user = JSON.stringify(user);
    console.log("🚀 ~ file: remote.ts ~ line 91 ~ makePayment ~ { cart, creditCard, ip, user: _user }", {cart, creditCard, ip, user: _user});

    const _cart = {
      cartTotal: cart.cartTotal,
      isEmpty: cart.isEmpty,
      items: cart.items,
      metadata: cart.metadata,
      totalItems: cart.totalItems,
      totalUniqueItems: cart.totalUniqueItems,
    };

    const _result: any = await httpsCallable(getFunctions(firebaseApp, `${process.env.REACT_APP_CLOUD_FUNCTONS_REGION}`), "callables-makePayment")({cart: JSON.stringify(_cart), creditCard: JSON.stringify(creditCard), ip, user: _user});
    console.log("remote.js makePayment creditCard _result", creditCard, _result);

    const _paymentResult = _result?.data?.paymentStatus === model.order.status.success && _result?.data?.confirmationStatus === model.order.status.success;
    console.log("🚀 ~ file: remote.ts ~ line 30 ~ makePayment ~ _paymentResult", _paymentResult);

    return _result;
  } catch (error) {
    console.error(`remote.ts makePayment error: ${(error as any)?.message || error || t("unknownError", "Bilinmeyen hata oluştu.")}`);
    return error;
  }
};
