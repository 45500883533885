import {Check, Close, Delete, Edit, Home} from "@mui/icons-material";
import {Avatar, Box, Breadcrumbs, Button, Card, CardActions, CardContent, CircularProgress, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField, Typography} from "@mui/material";
import {red} from "@mui/material/colors";
import {t} from "i18next";
import {Company} from "jack-shared";
import {QRCodeSVG} from "qrcode.react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import TableGeneratorForm from "../forms/table-generator-form";
import {useJackTables} from "../utilities/hooks";

export default function Tables({companies}: {companies: Company[]}) {
  let navigate = useNavigate();
  const {deleteTable, editTableName, tables, getTables, loadingEditingTable, loadingTables} = useJackTables();
  const [editingTable, setEditingTable] = useState<string | null>(null);
  const [newTableName, setNewTableName] = useState<string>("");

  function downloadSVG() {
    const svg = document.getElementById("my-svg");
    const serializer = new XMLSerializer();
    if (svg) {
      const source = serializer.serializeToString(svg);
      const blob = new Blob([source], {type: "image/svg+xml"});
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "qr-menu.svg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  useEffect(() => {
    let _unsubscribe: any;

    if (companies[0]?.id) {
      _unsubscribe = getTables(companies[0].id);
    }

    return () => {
      console.log("🚀 ~ file: tables.tsx:21 ~ return ~ _unsubscribe", _unsubscribe);
      if (_unsubscribe) {
        _unsubscribe();
      }
    };
  }, [companies, getTables]);

  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <IconButton color="primary" onClick={() => navigate("/dashboard")}>
                <Home />
              </IconButton>
              <Typography>{t("tables", "Masalar")}</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container>
          <Grid item xs={12}>
            {!!companies.length && <TableGeneratorForm company={companies[0]} numberOfExistingTables={tables.length} />}
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <List>
                {loadingTables ? (
                  <CircularProgress />
                ) : !tables.length ? (
                  <ListItem>
                    <ListItemText primary={t("noTables", "Henüz masa üretilmemiş.")} />
                  </ListItem>
                ) : (
                  tables.map((table) => (
                    <ListItem
                      key={table.id}
                      secondaryAction={
                        <Grid container>
                          {editingTable === table.id ? (
                            <>
                              <IconButton
                                onClick={() => {
                                  deleteTable(table.id);
                                  setEditingTable(null);
                                  setNewTableName("");
                                }}
                              >
                                <Delete />
                              </IconButton>
                              <IconButton
                                onClick={async () => {
                                  setEditingTable(null);
                                  setNewTableName("");
                                }}
                              >
                                <Close />
                              </IconButton>
                              <IconButton
                                onClick={async () => {
                                  await editTableName(table.id, newTableName);
                                  setEditingTable(null);
                                  setNewTableName("");
                                }}
                              >
                                <Check />
                              </IconButton>
                            </>
                          ) : (
                            <Grid item xs={6}>
                              {!loadingEditingTable && (
                                <IconButton
                                  onClick={() => {
                                    setEditingTable(table.id);
                                    setNewTableName(table.name);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar sx={{bgcolor: red[500]}}>{table.name.split(" ")[table.name.split(" ").length - 1]}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={editingTable === table.id ? <TextField defaultValue={table.name} fullWidth onChange={(e) => setNewTableName(e.target.value)} size="small" variant="standard" /> : table.name} secondary={table.description || null} />
                    </ListItem>
                  ))
                )}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <Box display="flex" justifyContent="center" paddingTop={4}>
            <QRCodeSVG id="my-svg" size={200} value={companies[0]?.id} />
          </Box>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {companies[0]?.legalName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("downloadQRAndPrint", "QR menü kare kodun. Çıktısını alın ve masalara yerleştirin.")}
            </Typography>
          </CardContent>
          <CardActions>
            <Button fullWidth onClick={downloadSVG} variant="contained">
              {t("downloadQRCode", "QR Menü Görselini İndir")}
            </Button>
          </CardActions>
          {companies[0] && (
            <CardActions>
              <Button fullWidth onClick={() => navigate(`/menu/${companies[0]?.id}`)} variant="outlined">
                {t("qrMenu", "QR Menü")}
              </Button>
            </CardActions>
          )}
        </Card>
      </Grid>
    </>
  );
}
