import { Home } from "@mui/icons-material";
import { Breadcrumbs, Grid, IconButton, Paper, Typography } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import UpdatePasswordForm from "../forms/update-password-form";

import useStyles from "../utilities/styles";

const UpdatePassword = function () {
  const classes = useStyles();

  let navigate = useNavigate();

  const onUpdatePassword = function ({ actions, values }: any) {
    console.log("Account onUpdateCompany values", values);
  };

  return (
    <>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate("/dashboard")}>
            <Home />
          </IconButton>
          <Typography>{t("update_password", "Şifre Yenileme")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center">
          <Grid item md={6}>
            <Paper className={classes.paperContent}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{t("update_password", "Şifre Yenileme")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <UpdatePasswordForm onSuccess={onUpdatePassword} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdatePassword;
