import {ArrowBack, Home} from "@mui/icons-material";
import {Breadcrumbs, Button, Grid, IconButton, Paper, Typography} from "@mui/material";
import {t} from "i18next";
import {Link, useNavigate} from "react-router-dom";
import useStyles from "../utilities/styles";

const NotFound = function () {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate("/dashboard")}>
            <Home />
          </IconButton>
          <Typography>{t("notFound", "Sayfa Bulunamadı")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item textAlign="center" xs={12}>
        <Paper className={classes.paperContent}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="subtitle1">{t("notFoundPage", "Aradığınız sayfa bulunamadı. ")}</Typography>
              <Typography variant="subtitle2">{t("notFoundPageBackMessage", "Ana sayfaya gidebilir ya da bir önceki sayfaya geri göndebilirsiniz.")}</Typography>
            </Grid>
            <Grid item>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={2}>
                  <Button fullWidth onClick={() => navigate(-1)} startIcon={<ArrowBack />} variant="outlined">
                    {t("back", "Geri")}
                  </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button component={Link} fullWidth startIcon={<Home />} to="/" variant="outlined">
                    {t("home", "Ana sayfa")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
export default NotFound;
