import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {languages} from "jack-shared/assets/constants.json";

const _lang: any = languages;

export const resources = {
  en: {
    translation: Object.keys(_lang).reduce((a, b) => ({...a, [b]: _lang[b]["en"]}), {}),
  },
  tr: {
    translation: Object.keys(_lang).reduce((a, b) => ({...a, [b]: _lang[b]["tr"]}), {}),
  },
} as const;
console.log("🚀 ~ file: config.ts:15 ~ resources", resources);

i18n.use(initReactI18next).init({
  lng: "tr",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
