import {Home} from "@mui/icons-material";
import {Breadcrumbs, Grid, IconButton, Paper, Typography} from "@mui/material";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";

import CompanyForm from "../forms/company-form";

import useStyles from "../utilities/styles";

const CompanyWrite = function () {
  const classes = useStyles();

  let navigate = useNavigate();

  return (
    <>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate("/dashboard")}>
            <Home />
          </IconButton>
          <Typography>{t("company_info", "Firma Bilgileri")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item md={6}>
            <Paper className={classes.paperContent}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{t("company_info", "Firma Bilgileri")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CompanyForm />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyWrite;
