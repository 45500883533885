import {getAnalytics} from "firebase/analytics";
import {initializeApp} from "firebase/app";
import {connectAuthEmulator, getAuth, onAuthStateChanged, signInAnonymously, User} from "firebase/auth";
import {connectFirestoreEmulator, getDocs, getFirestore} from "firebase/firestore";
import {connectFunctionsEmulator, getFunctions} from "firebase/functions";
import {connectStorageEmulator, getStorage} from "firebase/storage";
import {Role, Subscription} from "jack-shared";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {proxy} from "valtio";
import {devtools} from "valtio/utils";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {querySubscriptions} from "./utilities/queries";

export const state = proxy<{currentUser: User | null; errors: string[]; role: Role | null; subscription: Subscription | null}>({
  currentUser: null,
  errors: [],
  role: null,
  subscription: null,
});
devtools(state, {name: "Jack State", enabled: true});

const firebaseConfig = {
  apiKey: "AIzaSyDZQncEUc4vBdJeSiERxs7hZPXBgHiXlTg",
  // authDomain: "jack-78b03.firebaseapp.com",
  authDomain: "bonnaqr.com",
  databaseURL: "https://jack-78b03.firebaseio.com",
  projectId: "jack-78b03",
  storageBucket: "jack-78b03.appspot.com",
  messagingSenderId: "729456441261",
  appId: "1:729456441261:web:ce1c0f36d217507aaa5324",
};

export const firebaseApp = initializeApp(firebaseConfig);

if (process.env.NODE_ENV === "production") {
  getAnalytics(firebaseApp);
}

if (process.env.NODE_ENV === "development") {
  const auth = getAuth();
  const database = getFirestore();
  const functions = getFunctions(firebaseApp, process.env.REACT_APP_CLOUD_FUNCTONS_REGION);
  const storage = getStorage();

  if (!!connectAuthEmulator) {
    connectAuthEmulator(auth, "http://localhost:9099");
  }

  if (!!connectFirestoreEmulator) {
    connectFirestoreEmulator(database, "localhost", 8080);
  }

  if (!!connectFunctionsEmulator) {
    connectFunctionsEmulator(functions, "localhost", 5001);
  }

  if (!!connectStorageEmulator) {
    connectStorageEmulator(storage, "localhost", 9199);
  }
}

let _user: User | null = null;

async function getAnonymousUser() {
  const _anonymousUserCredential = await signInAnonymously(getAuth());
  console.log("🚀 ~ file: index.tsx:68 ~ const_initialUserCredential=awaitsignInAnonymously ~ _anonymousUserCredential:", _anonymousUserCredential);

  return _anonymousUserCredential;
}

onAuthStateChanged(getAuth(), async function (currentUser) {
  let _subscription: Subscription | null = null;
  const _role = ((await currentUser?.getIdTokenResult())?.claims?.role as Role) || null;
  _user = currentUser;

  console.log("🚀 ~ file: index.tsx ~ line 68 ~ currentUser", currentUser);
  console.log("🚀 ~ file: index.tsx ~ line 68 ~ _role", _role);

  if (!_user) {
    const _anonymousUserCredential = await getAnonymousUser();
    console.log("🚀 ~ file: index.tsx:82 ~ _anonymousUserCredential:", _anonymousUserCredential);
  }

  if (!!_user) {
    const _querySubscriptions = querySubscriptions(_user);
    const _subscriptionQuerySnapshot = await getDocs(_querySubscriptions);
    _subscriptionQuerySnapshot.forEach((subscriptionQueryDocumentSnapshot) => {
      if (subscriptionQueryDocumentSnapshot.exists()) {
        _subscription = subscriptionQueryDocumentSnapshot.data();
      }
    });
  }

  state.currentUser = _user;
  state.role = _role;
  state.subscription = _subscription;

  console.log("🚀 ~ file: index.tsx ~ line 82 ~ state", state);

  if (process.env.NODE_ENV === "development") {
    (window as any).currentUser = _user;
    (window as any).getAuth = getAuth;
    (window as any).role = _role;
  }

  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
