import {CameraAlt, Edit, Home} from "@mui/icons-material";
import {Breadcrumbs, Button, Grid, IconButton, Paper, Typography} from "@mui/material";
import {useState} from "react";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";

import MemberForm from "../forms/member-form";

import useStyles from "../utilities/styles";

const Member = function () {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);

  let navigate = useNavigate();

  const onUpdateMember = function ({actions, values}: any) {
    setEdit(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <IconButton color="primary" onClick={() => navigate("/dashboard")}>
            <Home />
          </IconButton>
          <Typography>{t("account", "Hesabım")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item md={4}>
                <Paper className={classes.paperContent}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant="subtitle1">{t("profile_photo", "Profil Fotoğrafı")}</Typography>
                    </Grid>
                    <Grid item>
                      <img alt="" src="https://picsum.photos/200/200" width="100%" />
                    </Grid>
                    <Grid item>
                      <label htmlFor="icon-button-file">
                        <input hidden accept="image/*" id="icon-button-file" type="file" />
                        <Button component="span" fullWidth startIcon={<CameraAlt />} variant="contained">
                          {t("upload_photo", "Fotoğraf Yükleyin")}
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item md={8}>
                <Paper className={classes.paperContent}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">{t("profile_info", "Profil Bilgileri")}</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      {!edit && (
                        <Button endIcon={<Edit />} onClick={() => setEdit((e) => !e)} variant="outlined">
                          {t("edit_profile", "Profilimi Düzenle")}
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <MemberForm edit={edit} onSuccess={onUpdateMember} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Member;
