import {Button, Card, CardActions, CardContent, Tooltip, Typography} from "@mui/material";
import {DocumentData} from "firebase/firestore";
import {t} from "i18next";
import {Company} from "jack-shared";
import {useNavigate} from "react-router-dom";
import {useSnapshot} from "valtio";
import {state} from "..";
import {CompaniesScreenPropTypes} from "../utilities/types";

const Companies = function ({companies = [], isLoadingCompanies = false}: CompaniesScreenPropTypes) {
  let navigate = useNavigate();
  const {subscription} = useSnapshot(state);

  return (
    <Card>
      <CardContent>
        <Typography variant="caption">{t("my_companies", "Kayıtlı Firmalarım")}</Typography>
        {isLoadingCompanies ? (
          <Typography>{t("loading", "Yükleniyor...")}</Typography>
        ) : !companies?.length ? (
          <Typography variant="body1">{t("noRegisteredCompany", "Kayıtlı firma bulunmuyor.")}</Typography>
        ) : (
          companies.map((company: Company | DocumentData, index: number) => (
            <Typography key={`company_${index}`} variant="body1">
              {company.legalName}
            </Typography>
          ))
        )}
      </CardContent>
      {!companies?.length && (
        <CardActions>
          <Tooltip disableHoverListener={!!subscription} placement="top" title={`${t("subscriptionNeededToRegisterCompany", "Aboneliğiniz bulunmadığı için firma kaydedemezsiniz.")}`}>
            <span>
              <Button disabled={!subscription} onClick={() => navigate("/company")} variant="contained">
                {t("createCompany", "Yeni Firma Kaydedin")}
              </Button>
            </span>
          </Tooltip>
        </CardActions>
      )}
    </Card>
  );
};

export default Companies;
