import {Home} from "@mui/icons-material";
import {Breadcrumbs, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, Paper, Switch, TextField, Typography} from "@mui/material";
import {t} from "i18next";
import {Company, CompanySettingType} from "jack-shared";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useJackCompanySettings} from "../utilities/hooks";
import useStyles from "../utilities/styles";

export default function CompanySettings({companies}: {companies: Company[]}) {
  let navigate = useNavigate();
  const classes = useStyles();
  const {companyId} = useParams();
  const {companySettings, getCompanySettings, isLoadingCompanySettings, updateCompanySetting} = useJackCompanySettings();

  useEffect(() => {
    if (companyId) {
      getCompanySettings(companyId);
    }
  }, [companyId, getCompanySettings]);

  return (
    <>
      <Grid item xs={12}>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <IconButton color="primary" onClick={() => navigate("/")}>
                <Home />
              </IconButton>
              <Typography>{t("settings", "Ayarlar")}</Typography>
              {companies[0] && <Typography>{companies[0]?.shortName}</Typography>}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={3}></Grid>
      <Grid item xs={12} md={6}>
        <Paper className={classes.paperContent}>
          {isLoadingCompanySettings ? (
            <CircularProgress />
          ) : (
            <Grid component={FormGroup} container>
              {companySettings.map((companySetting) => {
                switch (companySetting.type) {
                  case CompanySettingType.swith:
                    return <Grid component={FormControlLabel} control={<Switch defaultChecked={companySetting.status} onChange={(e) => updateCompanySetting(companySetting.id, {status: e.target.checked})} />} item key={companySetting.id} label={companySetting.name} xs={12} />;

                  case CompanySettingType.value:
                    return (
                      <Grid item key={companySetting.id} xs={12}>
                        <TextField defaultValue={companySetting.value} fullWidth label={companySetting.name} onBlur={(e) => updateCompanySetting(companySetting.id, {value: e.target.value})} />
                      </Grid>
                    );

                  default:
                    return <></>;
                }
              })}
            </Grid>
          )}
        </Paper>
      </Grid>
    </>
  );
}
