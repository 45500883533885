import {Navigate, useLocation} from "react-router-dom";
import {useSnapshot} from "valtio";
import {state} from "..";

function RequireAuth({screen}: {screen: JSX.Element}) {
  const {currentUser, role} = useSnapshot(state);
  let location = useLocation();

  /**
   * IMPORTANT!
   * replace attribute is required to enable back button of web browser when user redirected to login screen.
   */
  if (!currentUser || !role) return <Navigate to="/login" replace state={{from: location}} />;

  return screen;
}
export default RequireAuth;
