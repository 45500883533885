import {User} from "firebase/auth";
import {collection, DocumentReference, getFirestore, orderBy, query, where} from "firebase/firestore";
import {NotificationStatusName, ProjectStatusCode, ProjectStatusName, SubscriptionStatusName} from "jack-shared";
import {subscriptionConverter} from "./converters";

export function queryNotifications(memberId: string, unreadOnly: boolean = false, limit = 10, offset = 0) {
  return query(collection(getFirestore(), "Notifications"), where("memberId", "==", memberId), ...(unreadOnly ? [where("status.name", "==", NotificationStatusName.unread)] : []), orderBy("createdAt", "desc"));
}

export const queryProjectById = function (projectId: string) {
  if (!projectId) {
    return null;
  }

  return query(collection(getFirestore(), "Projects"), where("id", "==", projectId));
};

export const queryProjectsByCities = function (cities: string[] = [], showClosed = false) {
  if (!cities.length) {
    return null;
  }

  return query(collection(getFirestore(), "Projects"), where("address.city", "in", cities), where("status.name", "==", showClosed ? ProjectStatusName.pendingForQuoteApproval : ProjectStatusName.pendingForQuotes));
};

export function queryProjectsDraft(memberId: string) {
  return query(collection(getFirestore(), "Projects"), where("status.code", "==", ProjectStatusCode.draft), where("createdByMemberId", "==", memberId));
}

export function queryProjectsForAdmins() {
  return query(collection(getFirestore(), "Projects"), where("status.code", ">=", ProjectStatusCode.challengeRequested), where("status.code", "<=", ProjectStatusCode.pendingForDeliveryConfirmation));
}

export function queryProjectsPendingTransaction() {
  return query(collection(getFirestore(), "Projects"), where("status.code", ">=", ProjectStatusCode.deliveryCompleted));
}

export function queryProjectsSuspended() {
  return query(collection(getFirestore(), "Projects"), where("status.code", "==", ProjectStatusCode.suspended));
}

export const queryApprovedProjectsByCompanies = function (companies: DocumentReference[] = [], projectStatusName: ProjectStatusName = ProjectStatusName.preApproved) {
  if (!companies.length) {
    return null;
  }

  return query(collection(getFirestore(), "Projects"), where("contractorCompanyId", "==", companies[0].id), where("status.name", "==", projectStatusName));
};

export const queryPendingBankTransferProjectsByCompanies = function (companies: DocumentReference[] = []) {
  if (!companies.length) {
    return null;
  }

  return query(collection(getFirestore(), "Projects"), where("ownerCompany.id", "==", companies[0].id), where("status.code", "<=", ProjectStatusCode.pendingForBankTransferCompleted), where("status.code", ">=", ProjectStatusCode.pendingForBankTransfer));
};

export const queryPendingBankTransferProjectsForAdmins = function () {
  return query(collection(getFirestore(), "Projects"), where("status.code", "<=", ProjectStatusCode.pendingForBankTransferCompleted), where("status.code", ">=", ProjectStatusCode.pendingForBankTransfer));
};

export const queryProjectsCreatedByMember = function (memberId: string | null) {
  if (!memberId) {
    throw new Error("Üye kimlik bilgisi gerekli.");
  }

  return query(collection(getFirestore(), "Projects"), where("createdByMemberId", "==", memberId));
};

export function querySubscriptions(currentUser: User) {
  const _subscriptionsRef = collection(getFirestore(), "Subscriptions");

  return query(_subscriptionsRef, where("memberId", "==", currentUser.uid), where("status.name", "==", SubscriptionStatusName.active)).withConverter(subscriptionConverter);
}
